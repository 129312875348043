import styles from "./UploadFile.module.css";

import React from "react";
import { useState, useEffect } from "react";

import { FormControl } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";
// import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Button } from "@material-ui/core";
import { Chip } from "@material-ui/core";

/**
 * @typedef UploadFileProps props for UploadFile.js component
 * @property {string} uploadFileType indicate which type of file is accepted {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file}
 * @property {string } buttonContent text to display on upload button
 * @property {Function} handleUploadFile handle the processing of file's content after uploading the file
 * @property {Array.<Object>} FilesList array of objects to contain the existing uploaded files
 * @property {Function} setUploadFilesList handle file change when uploading, including adding and removing
 * @property {boolean} isDisabled indicate whether upload button and helperText are disabled or not
 * @property {React.ReactElement} helperText dynamic helper text
 */

/**
 *
 * @param {UploadFileProps} props
 * @returns
 */
const UploadFile = (props) => {
  const {
    uploadFileType,
    buttonContent,
    handleUploadFile,
    FilesList,
    setUploadFilesList,
    isDisabled,
    helperText,
  } = props;

  // function to upload file
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const uploadFile = e.target.files[0];
      handleUploadFile(uploadFile);
    }
  };

  return (
    <div className={styles.upload}>
      <FormControl variant="standard" className={styles.uploadButton}>
        <label>
          <Button
            variant="outlined"
            component="span"
            disabled={isDisabled}
            // startIcon={<FileUploadIcon />}
          >
            {buttonContent}
            <input
              type="file"
              accept={uploadFileType}
              hidden
              disabled={isDisabled}
              onClick={(e) => (e.target.value = "")}
              onChange={handleFileChange}
            />
          </Button>
        </label>
        <FormHelperText disabled={isDisabled}>{helperText}</FormHelperText>
      </FormControl>
      <div className={styles.uploadChips}>
        {FilesList.map((file, index) => (
          <div className={styles.uploadPerChip} key={index}>
            <Chip
              label={file.fileName}
              onDelete={() => {
                setUploadFilesList((prev) =>
                  prev.filter((oneFile) => oneFile.fileName !== file.fileName)
                );
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadFile;
