/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Aug 28, 2020
 *
 * Description: A list component which contains maximum 100 data points
 * for a use to label.
 *
 * On the bottom of the list, there would be another component that contains
 * 2 buttons and one text input. While the two buttons enable a user to
 * navigation data points, fetching previous 100 data points or next 100 data
 * points if there were any.
 *
 * The text input would indicate the user which page
 * of data points he is labeling and the user is also able
 * to jump to a paticular page by change its value
 *
 */

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { IconButton } from "@material-ui/core";
import { timeToString } from "../../containers/home/homeUtils";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckIcon from "@material-ui/icons/Check";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { connect } from "react-redux";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { IrisSelect } from "irisrad-ui";
import { setGlobalMessage } from "../../redux/reducers/globalReducer/globalActions";
import { SORT_DATA_POINT_BY } from "../../redux/reducers/dataPointsReducer/dataPointActions";

const TAG = "dataPointList.js";
const DataPointList = (props) => {
  const {
    dataPointArray,
    loadData,
    onSelectedData,
    numberOfDataPoints,
    userData,
    previousUrl,
    nextUrl,
    selectedData,
  } = props;

  console.log(`dataPointArrayFromList`, dataPointArray);

  const { setGlobalMessage } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [targetPage, setTargetPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const listRef = useRef();

  /**
   * @summary callback method of onKeyDown listener of the list
   *
   * @description when the list is focused, for example, element of
   * it was clicked, then it would be able to listen to keyDown event
   * via its onKeyDown event listener.active
   *
   * Now the list is able to listen to arrow up and down event for a user
   * to visit previous and next respectively, data point on the list.
   *
   * @see onKeyDown
   * @see toggleActiveIndex
   *
   * @param {Event} event keypress event
   */
  const handleKeyUp = (event) => {
    const currentTarget = event.currentTarget;
    const eventTarget = event.target;
    if (currentTarget === eventTarget) {
      switch (event.keyCode) {
        case 40:
          toggleActiveIndex(activeIndex + 1);
          break;
        case 38:
          toggleActiveIndex(activeIndex - 1);
          break;
        case 37:
          loadPreviousPage();
          break;
        case 39:
          loadNextPage();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    document.body.addEventListener("keyup", handleKeyUp);
    return () => {
      document.body.removeEventListener("keyup", handleKeyUp);
    };
  });

  /**
   * @summary method marks an element of the list as active.
   *
   * @description An element of the list should be marked as active when it
   * was either clicked by a user with mouse, or visted by a user with shortcut
   * key, namely arrow up or arrow down.
   *
   * before marking the next data point as active, it also notifies the parent to send update
   * about the currenlty selected data points (the bounding boxes it has in current state)
   *
   * @see loadNextPage
   * @see loadPreviousPage
   * @see onSelectedData
   * @see handlePageInputKeyEvent
   * @param {Number} newIndex index of the up comming data point that should be marked as 'active'
   */
  const toggleActiveIndex = (newIndex) => {
    // only if newIndex is [0, dataPointArray.length)
    if (newIndex >= 0 && newIndex < dataPointArray.length) {
      // get the selected element from the document

      // scroll the list if the element upcoming element is not
      // in the view port
      scrollToElement(newIndex);
      setActiveIndex(newIndex);

      onSelectedData(dataPointArray[newIndex], userData.id);
    }
  };

  /**
   * @summary rerender if dataPointArray is updated
   *
   * @description when the data point array got updated
   * this component should be rerender so that the most updated
   * change would be reflected.
   */
  useEffect(() => {
    if (dataPointArray && selectedData) {
      let index = 0;

      // reselect last selected data point.
      // use case: when a user navigated away and came back
      // though the list would be rerender with dataPointArray, which
      // is saved in HomePageContext, but the previously selected
      // element on the list would be reset to 0. So a user would
      // lost track of the last selected data point.
      for (let i = 0; i < dataPointArray.length; i++) {
        // with HomePageContext, the most updated selected data point would be saved there
        // find it's index on the data point array based on its id.
        if (dataPointArray[i].id === selectedData.id) {
          index = i;
          break;
        }
      }

      // toggleActiveIndex(index);
      scrollToElement(index);
      setActiveIndex(index);
    }
  }, [dataPointArray, selectedData]);

  useEffect(() => {
    if (previousUrl == null) {
      setCurrentPage(1);
      setTargetPage(1);
    }
    if (nextUrl === null) {
      setCurrentPage(Math.ceil(numberOfDataPoints / 100));
      setTargetPage(Math.ceil(numberOfDataPoints / 100));
    } else {
      const arr = nextUrl.split("&");
      const a = arr.find((element) => element.includes("page"));
      const b = a.split("=");
      setCurrentPage(Number(b[1]) - 1);
      setTargetPage(Number(b[1]) - 1);
    }
    setTotalPage(
      Math.ceil((numberOfDataPoints ? numberOfDataPoints : 100) / 100)
    );
  }, [previousUrl, nextUrl, numberOfDataPoints]);

  /**
   * @summary callback method for arrow right button
   */
  const loadNextPage = () => {
    toggleActiveIndex(activeIndex);
    if (nextUrl === null) {
      setGlobalMessage({
        title: "Message",
        message: "You are on the last page",
      });
      return;
    }

    loadData(nextUrl);
  };

  /**
   * @summary callback method for arrow left button
   */
  const loadPreviousPage = () => {
    toggleActiveIndex(activeIndex);
    if (previousUrl === null) {
      setGlobalMessage({
        title: "Message",
        message: "You are on the first page",
      });
      return;
    }

    loadData(previousUrl);
  };

  /**
   * @summary renter an icon, indicating the data point's status
   *
   * @description if the icon is a single check mark, it means the current data point is not viewed by the user
   * if the icon has a double check mark, it means the data point is viewed by the user
   * if the icon is a triangle warning sign, it means the data point is flagged
   *
   * @param {Object} element data point object
   * @param {Boolean} isActive whether given data point is active (selected)
   */
  const renderStatusIcon = (element, isActive) => {
    if (element) {
      if (element.flag && element.flag !== "N") {
        return (
          <IconButton disabled>
            <ReportProblemIcon
              style={{ color: isActive ? "#fff" : "#000" }}
              fontSize="large"
            />
          </IconButton>
        );
      }
      if (element.edited && element.visited) {
        return (
          <IconButton disabled>
            <DoneAllIcon
              style={{ color: isActive ? "#fff" : "#000" }}
              fontSize="large"
            />
          </IconButton>
        );
      }
      if (element.edited || element.visited) {
        return (
          <IconButton disabled>
            <CheckIcon
              style={{ color: isActive ? "#fff" : "#000" }}
              fontSize="small"
            />
          </IconButton>
        );
      }
    }
  };

  const handlePageInputKeyEvent = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const pageNumber = Number(event.target.value);
      toggleActiveIndex(activeIndex);
      if (pageNumber === currentPage) {
        setGlobalMessage({
          title: "Message",
          message: `Already in page ${currentPage}`,
        });
      } else {
        loadData(previousUrl ? previousUrl : nextUrl, pageNumber);
      }
    }
  };

  const options = [];
  Object.keys(SORT_DATA_POINT_BY).map((key) => {
    options.push({ id: key, label: SORT_DATA_POINT_BY[key] });
  });

  return (
    <div className="list-wrapper">
      <div className="sort-select">
        <IrisSelect
          label="Sort by"
          options={options}
          valueField="label"
          labelField="label"
          onChange={props.updateDataPointSortOption}
        />
      </div>
      <div id="list" ref={listRef} className="list-component">
        <div>
          {dataPointArray.map((k, i) => (
            <div
              id={`data-point-${i}`}
              key={i}
              className={`list-item ${i === activeIndex ? `active` : ""}`}
              onClick={() => {
                toggleActiveIndex(i);
              }}
            >
              <ListItem>
                <ListItemText
                  primary={
                    <div style={{ fontSize: "1rem" }}>
                      <div>{timeToString(k.create_time, true)}</div>
                      {/* <div style={{ fontSize: "75%" }}>{k.address}</div> */}
                    </div>
                  }
                  secondary={
                    <span style={{ fontSize: "0.75rem" }}>{`${
                      i + 1
                    } / 100`}</span>
                  }
                  secondaryTypographyProps={{
                    style: {
                      color: i === activeIndex ? "#fff" : "#000",
                    },
                  }}
                />
                {renderStatusIcon(k, i === activeIndex)}
              </ListItem>
            </div>
          ))}
        </div>
      </div>
      {dataPointArray.length > 0 && (
        <div className="list-footer">
          <IconButton
            color="inherit"
            disabled={previousUrl === null}
            size="small"
            onClick={loadPreviousPage}
          >
            <ArrowBackIcon fontSize="large" />
          </IconButton>
          <span>
            <input
              type="number"
              className="page-input"
              value={targetPage}
              min={1}
              max={totalPage}
              onChange={(event) => {
                const pageNumber = Number(event.target.value);
                if (pageNumber > 0 && pageNumber <= totalPage) {
                  setTargetPage(pageNumber);
                } else {
                  setTargetPage(currentPage);
                }
              }}
              onKeyDown={handlePageInputKeyEvent}
            />
            / {totalPage}
          </span>

          <IconButton
            color="inherit"
            disabled={nextUrl === null}
            size="small"
            onClick={loadNextPage}
          >
            <ArrowForwardIcon fontSize="large" />
          </IconButton>
        </div>
      )}
    </div>
  );

  /**
   * @summary for better UX, show the selected element on the view port if it was not visible.
   *
   * @description
   * If a user scroll up or down too far so that the selected element is not visible
   * on the view port. If the user then use a shortcut key to navigate the list, then
   * the upcoming selected element is not visible on the view port.
   *
   * In this case, the list should be scrolled to a point where the active element would be
   * visible on the view port.
   *
   * @param {Number} newIndex index of the upcoming selected element
   */
  function scrollToElement(newIndex) {
    const selectedElement = document.getElementById(`data-point-${newIndex}`);

    // selected element's top and bottom relative to the to height of the list
    const listElementTop = selectedElement.getBoundingClientRect().top;
    const listElementBottom = selectedElement.getBoundingClientRect().bottom;

    // list's top and bottom position relative to the view port.
    const listTop = listRef.current.getBoundingClientRect().top;
    const listBottom = listRef.current.getBoundingClientRect().bottom;

    // the height of pixel, relative to its top position, that the list has been scrolled.
    const listScrolledHeight = listRef.current.scrollTop;

    // if the selected element below the lsit, the list should scroll down
    // until it would be shown on the view port
    if (listElementBottom > listBottom) {
      listRef.current.scrollTo(
        0,
        listElementTop - listTop + listScrolledHeight
      );
    }
    // if the selected element above the lsit, the list should scroll up
    // until it would be shown on the view port
    if (listElementTop < listTop) {
      listRef.current.scrollTo(
        0,
        listScrolledHeight + listElementTop - listTop
      );
    }
  }
};

DataPointList.propTypes = {
  dataPointArray: PropTypes.array.isRequired, // data point array to be displayed
  loadData: PropTypes.func, // callback function that requires to load other data points from server
  onSelectedData: PropTypes.func, // callback function that handle events while a data point is selected
  numberOfDataPoints: PropTypes.number, // total number of data points
};
const mapStateToProps = (state) => {
  const { loginState, dataPointsState } = state;
  return { ...loginState, ...dataPointsState };
};
const mapDispatchToProps = {
  setGlobalMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(DataPointList);

/**
 * Change Log:
 *
 * Change Date: Aug 28, 2020
 *
 * Description: added documentation
 */
