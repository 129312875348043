import { isValidObject } from "../../awsBackend/awsBackendUtils";

export const SORTING_TYPES = {
  PCI: "pci",
  TIME: "create_time",
  DEFECT_TYPE: "type",
  CITY: "city_name",
};

/**
 * @summary convert OCT time (in string value) from server to local time
 *
 * @param {String} timeStampData string value that represents date value
 * @param {Boolean} formatAmPm determined wheter or not to format date into 12 hour format
 * @returns {Strig} string value of date
 */

export const timeToString = (timeStampData, formatAmPm) => {
  // construct time object, "Z" at the end must be upper case
  const current_datetime = new Date(timeStampData + "Z");

  const year = current_datetime.getFullYear();
  const month = current_datetime.getMonth() + 1;
  const date = current_datetime.getDate();
  let hour = current_datetime.getHours();
  let isAm = hour < 12;
  if (formatAmPm && hour > 12) {
    hour = hour % 12;
  }
  const minute = current_datetime.getMinutes();
  const second = current_datetime.getSeconds();
  return `${year}-${month >= 10 ? month : `0${month}`}-${
    date >= 10 ? date : `0${date}`
  } 
    ${hour}:${minute >= 10 ? minute : `0${minute}`}:${
    second >= 10 ? second : `0${second}`
  } 
    ${formatAmPm ? `${isAm ? " AM" : " PM"}` : ""}`;
  // return current_datetime.toISOString();
};

/**
 * @summary given a date object, get date string as "yyyy-MM-dd"
 *
 * @description if d is not given or is undefined, d = current_date
 *
 * @param {Date} d
 * @return {String} in format of "yyyy-MM-dd"
 */

export const getDate = (d) => {
  const date = d === null || d === undefined ? new Date() : d;
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${month < 10 ? `0${month}` : `${month}`}-${
    day < 10 ? `0${day}` : `${day}`
  }`;
};

/**
 * @summary convert pci value to corresponding string value
 *
 * @description 0-20 = very poor, 20-40 = poor, 40-60 = fair, > 60 = good
 *
 * @param {Number} pciValue
 */
export const getPCIString = (pciValue) => {
  if (pciValue === null || pciValue === undefined || pciValue > 60) {
    return "Good";
  } else if (pciValue <= 20) {
    return "Very Poor";
  } else if (pciValue <= 40) {
    return "Poor";
  } else if (pciValue <= 60) {
    return "Fair";
  }
};

/**
 * @description
 * for example
 * values = ["apple", "banana"]
 * objects = [
 *  {id: 0, fruitName: "apple"},
 *  {id: 1, fruitName: "peach"},
 *  {id: 4, fruitName: "banana"}
 * ]
 * property name = fruitName
 *
 * then the return number array should be
 * [0, 4] where 0 is the id of object {id: 0, fruitName: "apple"}
 *          and 4 is the id of object {id: 4, fruitName: "banana"}
 *
 * @param {Stringn} values array of strings
 * @param {Array} objects array of objects
 * @param {String} propertyName a specific key value of each object in objects
 * @returns {Array} array of numbers that rerpesents each value of given values
 */
export const getIdsFromObjects = (values, objects, propertyName) => {
  const damageIds = [];
  if (
    values.length === 0 ||
    !isValidObject(values) ||
    !isValidObject(objects)
  ) {
    const defaultNoneObject = objects.find(
      (obj) => obj[propertyName].toLowerCase() === "none"
    );

    return [defaultNoneObject ? defaultNoneObject.id : -1];
  }
  for (const value of values) {
    for (const element of objects) {
      if (
        element.hasOwnProperty(propertyName) &&
        element[propertyName] === value &&
        element.id > 0
      ) {
        damageIds.push(element.id);
      }
    }
  }
  return damageIds;
};

/*================================================== for short cut keys ================================================== */

// short cut key codes from 0 - 9
// 48 49 49 51 52 53 54 55 56 57 18  16
// 0  1   2  3  4  5  6  7  8  9 alt shift
export const SHORT_CUT_KEYS = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];

export const renderLabel = (index, name) => {
  let prefix = `${index}`;

  if (Math.floor(index / 10) === 1) {
    prefix = `S${index % 10}`;
  } else if (Math.floor(index / 10) === 2) {
    prefix = `A${index % 10}`;
  }
  return `${prefix} ${name}`;
};
