const postUrl = new URL("https://post.api.irisradgroup.com");
// const postUrl = new URL("https://irisgo.api.irisradgroup.com");

const loginUrl = `${postUrl}api/token/`;
const lookupUrl = `${postUrl}api/lookup/v1/`;
const getDistanceReport = `${postUrl}api/report/distance/`;
const getViewReport = `${postUrl}api/report/view/`;
const postDataPointUrl = `${postUrl}api/geo_info/`;
const updateDataPointUrl = `${postUrl}api/datapoint`;
const updateDeviceUrl = `${postUrl}api/device`;
const getPciUrl = `${postUrl}api/tasks/pci/`;

const postDataPointBoundingBoxesUrl = `${postUrl}api/add_label/`;
const updateDataPointBoundingBoxUrl = `${postUrl}api/label`;

const deleteLabelUrl = `${postUrl}api/label`;

const fmeReportUrl =
  "https://9wm4qo7u9j.execute-api.ca-central-1.amazonaws.com/alpha/sync";
const autoGenerateWorkorderUrl =
  "https://9wm4qo7u9j.execute-api.ca-central-1.amazonaws.com/alpha/upload_by_label";

const sendReportEmailUrl = `${postUrl}api/tasks/post_office_email/`;

const userManualUrl =
  "https://iris-user-manuals.s3.ca-central-1.amazonaws.com/labelling-portal/Iris_Labeling_Portal_User_+Manual_Reviewed_March12_2021.docx";

const verifyUrl = `${postUrl}api/token/verify/`;

// const IRIS_DATA_URL = "http://35.182.242.193:8081"; // test
const IRIS_DATA_URL = "https://data.irisradgroup.com"; // prod
const getDataPointByCityUrl = `${IRIS_DATA_URL}/api/datapoint/`;
const IRIS_WORK_ORDER_END_POINT = `${IRIS_DATA_URL}/api/workorder`;
const DEFECTS_URL = `${IRIS_DATA_URL}/api/defects/`;
const LIST_DEFECT_TYPE_URL = `${IRIS_DATA_URL}/api/defect_types/`;
const CREATE_WORK_ORDERS_BATCH_URL = `${IRIS_DATA_URL}/api/workorder/create`;
export {
  loginUrl,
  lookupUrl,
  getDataPointByCityUrl,
  postDataPointUrl,
  updateDataPointUrl,
  getDistanceReport,
  getViewReport,
  postDataPointBoundingBoxesUrl,
  deleteLabelUrl,
  updateDataPointBoundingBoxUrl,
  updateDeviceUrl,
  getPciUrl,
  userManualUrl,
  sendReportEmailUrl,
  fmeReportUrl,
  autoGenerateWorkorderUrl,
  verifyUrl,
  IRIS_WORK_ORDER_END_POINT,
  DEFECTS_URL,
  LIST_DEFECT_TYPE_URL,
  CREATE_WORK_ORDERS_BATCH_URL,
};

/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Mar 30, 2021
 *
 * Description: added download link for the user manual
 */
