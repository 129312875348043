import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
//Material UI components
import { withStyles } from "@material-ui/core/styles";
import Spinner from "../../UI/spinner/Spinner";
import { IRISAnalysisTable } from "../../components/table/IRISAnalysisTable";

import { getDate } from "./homeUtils";
import { getReports, exportReports } from "../../awsBackend/awsBackendUtils";
import { useStyles } from "./homeStyle";
import { SearchBar } from "../../components/searchBar/AnalysisSearchBar";
import IrisHeader from "../../components/irisHeader/irisHeader";

const TAG = "AnalysisHome.js";
class Home extends React.Component {
  constructor(props) {
    super(props);
    this._imageRef = React.createRef();
    this.state = {
      dataPoints: [],
      selectedData: null,
      isVideoClicked: false,
      isActive: "",
      hasDefect: "0",
      selectedDataToggledDefectType: [],
      roadIssues: [],
      accelerometer: [],
      type: "",
      pci: "",
      searchParams: { option: 0, year: "", month: "" },
      reports: [],
      city_name: "",
      session_id: "",
      loading: false,
      typeSort: true,
      citySort: true,
      dateTimeSort: false,
      pciSort: true,
      roadDefectTypes: [],
      noDefectType: { detected: false },
      pciTypes: [
        { value: "Good", checked: true, intValue: 80 },
        { value: "Fair", checked: false, intValue: 60 },
        { value: "Poor", checked: false, intValue: 40 },
        { value: "Very Poor", checked: false, intValue: 20 },
      ],
      editedObj: [
        { value: "Not edited", boolValue: false },
        { value: "Edited", boolValue: true },
      ],
      searchEditedBoolean: false,
      numberOfDayBefore: 0,
      clearCheckboxes: false,
      datakeys: [
        null,
        { id: "id", data: "city", value: "mileage" },
        { id: "id", data: "labeler", value: "view_count" },
        { id: "id", data: "labeler", value: "view_count" },
      ],
      resetTable: true,
      selectedDate: getDate(),
      selectedIndexFromMap: -1,
      previousPageUrl: null,
      nextPageUrl: null,
      flipPhoto: false,
      flags: [],
      // data to be posted to backend
      postDamageType: [],
      postRoadIssues: [],
      postPCIIntValue: 0,
      postFlagString: "",
    };
  }

  toggleRestTable = () => {
    this.setState({ resetTable: false });
  };

  getRoadDefectString(selectedIdArray, roadDefectTypesArray) {
    let detectedTypeArray = [];
    for (const id of selectedIdArray) {
      roadDefectTypesArray.forEach((type) => {
        if (type.id === id) {
          detectedTypeArray.push(type.name);
        }
      });
    }
    return detectedTypeArray;
  }

  editSearch = (searchParams) => {
    this.setState({ searchParams: searchParams });
  };

  handleSearch = async () => {
    const { option } = this.state.searchParams;
    if (option !== 0) {
      this.setState({ loading: true });
      getReports(this.state.searchParams)
        .then((result) => {
          this.setState({
            reports: result.result,
            loading: false,
            resetTable: true,
          });
        })
        .catch((error) => {
          console.log(`error in searching data ponts in ${TAG}`);
          console.error(error);
        });
    }
  };

  handleExport = async () => {
    const { option } = this.state.searchParams;
    if (option !== 0) {
      exportReports(this.state.searchParams)
        .then((result) => {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(result);
          a.href = url;
          a.download = result.name;
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
          // window.open(link);
        })
        .catch((error) => {
          console.log(`error in searching data ponts in ${TAG}`);
          console.error(error);
        });
    }
  };

  showDataOnTable = (index) => {
    this.setState({
      selectedIndexFromMap: index,
    });
  };

  handleKeyDown = (event) => {
    const { selectedData, nextPageUrl, previousPageUrl } = this.state;
    switch (event.keyCode) {
      case 16: // shift key pressed
        if (selectedData !== null) {
          this.flipPhoto();
        }
        break;
      case 188: // "," key pressed
        if (previousPageUrl !== null) {
          this._get({ url: previousPageUrl }, true);
        }
        break;
      case 190: // "." key pressed
        if (nextPageUrl !== null) {
          this._get({ url: nextPageUrl }, true);
        }
        break;
      default:
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div id="wrap">
          {/* <Header title="iPort Analysis Console" /> */}
          <IrisHeader
            title="iPort Analysis Console"
            links={[
              { title: "Labelling", pathname: "/", isActive: true },
              { title: "Analysis", pathname: "/analysis", isActive: false },
            ]}
          >
            <SearchBar
              editSearch={this.editSearch}
              handleSearch={this.handleSearch}
              searchParams={this.state.searchParams}
            />
          </IrisHeader>

          <div className={classes.main}>
            {/* Main Container Grid */}
            <Grid container direction="row" justify="space-evenly">
              {/* main Layout with Table Grid */}
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <Box>
                  {/* Data Table */}
                  <Grid container className={classes.searchArea}>
                    {this.state.loading ? (
                      <Spinner />
                    ) : (
                      <IRISAnalysisTable
                        checkBoxType={this.state.selectedDataToggledDefectType}
                        data={this.state.reports}
                        deleteItem={this.deleteItem}
                        resetTable={this.state.resetTable}
                        toggleRestTable={this.toggleRestTable}
                        datakeys={this.state.datakeys}
                        handleExport={this.handleExport}
                        option={this.state.searchParams.option}
                      />
                    )}
                  </Grid>
                  <div
                    tabIndex={1}
                    onKeyDown={(event) => this.handleKeyDown(event)}
                  ></div>
                  {this.state.selectedData === null ? null : (
                    <Grid container style={{ padding: "2rem 0 0 1rem" }}>
                      {/* left defects panel */}
                      <Grid
                        item
                        xs={6}
                        xm={6}
                        style={{ padding: "1rem" }}
                      ></Grid>
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(useStyles)(Home);
