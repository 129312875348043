/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Aug 23, 2021
 *
 * Description: a welcome page for user with adminstration access who could
 * choose to visit the label portal or the analysis portal
 */

import React from "react";
import { withRouter, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import logo from "../../images/iris-logo.png";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { IrisButton, IrisGrid } from "irisrad-ui";
import pkg from "../../../package.json";

const useStyles = makeStyles((theme) => ({
  bgBlock: {
    height: "100vh",
    backgroundColor: "#1565c0",
  },
  textField: {
    fontSize: "1.25rem",
  },
  loginArea: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  menuButton: {
    width: "250px",
    alignSelf: "center",
    padding: "3px 20px",
    margin: "20px",
    hover: {
      backgroundColor: "black",
    },
  },
  resize: {
    fontSize: 50,
  },
  menuFont: {
    textDecoration: "none",
    color: "white",
  },
}));

const Welcome = () => {
  const classes = useStyles();

  return (
    <div className="login-wrapper">
      <div className="login-content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} className="img" alt="IrisLogo" />
          <h3 className="login-title">Municipal Road Dashboard</h3>
          <h4 className="iris-red-title">Version {pkg.version}</h4>
        </div>
        <form style={{ padding: "0 1rem" }}>
          <IrisGrid
            container
            // justifyContent="center"
            style={{ paddingTop: "2rem" }}
          >
            <IrisGrid item xs={12}>
              <Link className={classes.menuFont} to="/">
                <IrisButton
                  fullWidth
                  size="large"
                  className="button-bottom-margin"
                  color="primary"
                  style={{ marginBottom: "1rem" }}
                >
                  Labeler Console
                </IrisButton>
              </Link>
            </IrisGrid>
            <IrisGrid item xs={12}>
              <Link className={classes.menuFont} to="/analysis">
                <IrisButton
                  fullWidth
                  size="large"
                  className="button-bottom-margin"
                  color="primary"
                >
                  Analysis Console
                </IrisButton>
              </Link>
            </IrisGrid>
          </IrisGrid>
          <Grid container justify="center">
            <ul className="nav-list">
              <li>
                <a
                  href="mailto:emilr@irisradgroup.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Help
                </a>
              </li>
              &nbsp;
              <li>
                <a
                  href="https://www.irisradgroup.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About Us
                </a>
              </li>
            </ul>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default withRouter(Welcome);

/**
 * Change Log:
 *
 * Change Date: Aug 23, 2021
 *
 * Description: created and added documentation.
 */
