import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Home from "./containers/home/Home";
import AnalysisHome from "./containers/home/AnalysisHome";
// import Login from './components/Login';
import Login from "./components/login/Login";
import Welcome from "./components/login/Welcome";
import PrivateRoute from "./routes/PrivateRoute";
import IRISThemeProvider from "./UI/theme/IRISMuiTheme";
import IrisShortutKeyList, {
  toggleShortcutListDisplayStyle,
  SHORTCUT_KEY_LIST_ID,
} from "./components/irisShortCutKeyList/irisShortcutKeyList";
import IRISDIalog from "./awsBackend/alertDialog/IRISDialog";
import { connect } from "react-redux";
import { setGlobalMessage } from "./redux/reducers/globalReducer/globalActions";
import Auth from "@aws-amplify/auth";
import { getUserFromSession } from "./aws/utils";
import { loginReset } from "./redux/reducers/loginReducer/loginActions";
import { verifyToken } from "./awsBackend/awsBackendUtils";
import { clearLocalStorage } from "./components/irisHeader/irisHeader";

const App = (props) => {
  const { message, userData, loginReset, setGlobalMessage } = props;
  useEffect(() => {
    const handleKeyUp = (event) => {
      if (
        event.target === event.currentTarget &&
        event.shiftKey &&
        event.keyCode === 191 // "/" key
      ) {
        toggleShortcutListDisplayStyle();
      }
    };

    document.body.addEventListener("keyup", handleKeyUp);

    return () => {
      document.body.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const checkCurrentUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser) {
          const user = getUserFromSession(currentUser?.signInUserSession);
          console.log("currentUser", user);
        }
      } catch (error) {
        // if not authenticated user, an error would be thrown
        console.log(`error in getting current user`, error);
      }
    };
    checkCurrentUser();
  }, []);

  // veriy access token periodically, if anything is wrong log user out
  useEffect(() => {
    let id;

    const verifyTokenPeriodically = (token, interval = 10 * 1000) => {
      id = setInterval(() => {
        verifyToken(token).catch((error) => {
          // mark session as expired and log uer out on any error
          clearInterval(id);
          id = null;
          clearLocalStorage();
          loginReset();
          setGlobalMessage({
            title: "Session is expired",
            message: "Please log in agin",
          });
        });
      }, interval);
    };

    const access = userData?.access;
    if (access) {
      verifyTokenPeriodically(access);
    }

    return () => {
      if (id) {
        clearInterval(id);
        id = null;
      }
    };
  }, [userData, loginReset, setGlobalMessage]);
  const onDismissDialog = () => {
    setTimeout(() => {
      props.setGlobalMessage(null);
    }, 200);
  };

  return (
    <IRISThemeProvider>
      {message && (
        <IRISDIalog
          open={true}
          dialogTitle={message.title}
          dialogMessage={message.message}
          onClose={onDismissDialog}
          onConfirm={message.onConfirm}
        />
      )}
      <Router>
        <IrisShortutKeyList />
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          {/* <PrivateRoute exact path="/shortcuts" component={Home} /> */}
          <Route exact path="/login" component={Login} />
          {/* <PrivateRoute exact path="/analysis" component={AnalysisHome} /> */}
          <PrivateRoute exact path="/welcome" component={Welcome} />
          <Route
            exact
            path="/page-not-found"
            render={() => <h1>Page Not Found</h1>}
          />
          <Redirect to="/page-not-found" />
        </Switch>
      </Router>
    </IRISThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    message: state.globalState.message,
    userData: state.loginState.userData,
  };
};

export default connect(mapStateToProps, { setGlobalMessage, loginReset })(App);

/**
 * Change Log:
 *
 * Change Date: Jan 28, 2021
 *
 * Description:
 * 1. Added IrisShortutKeyList as a golbally accessible element;
 * 2. Added " shift + / " shortcut key for toggling the display style of the IrisShortutKeyList
 *
 * @see IrisShortutKeyList
 * @see toggleShortcutListDisplayStyle
 */

/**
 * Change Log:
 *
 * Change Date: Mar 17, 2021
 *
 * Description: make the custom dialog globally accessible for displaying informative message
 */
