/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Nov 15, 2021
 *
 * Description: A component that contains grouped bounding boxes for user to interact
 */
import { Button, Switch, FormControlLabel } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import BoundingBoxTypeList from "../irisList/IrisList";
import PropTypes from "prop-types";
import { CANVAS_COMPONENT_DOM_ID } from "../components/boundingBoxCanvas/BoundingBoxCanvas";
import { IrisButton, IrisMultiSelect } from "irisrad-ui";
import IrisSlider from "../UI/slider/IrisSlider";
import { connect } from "react-redux";
import {
  setNumOfLanes,
  setWidthPerLane,
} from "../redux/reducers/dataPointsReducer/dataPointActions";
import UploadFile from "../components/uploadFile/UploadFile";
import * as shp from "shpjs";
const TAG = "BoundingBoxComponent.js";

export const ENTER_KEY_EVENT = new KeyboardEvent("keydown", {
  code: "e",
  key: "e",
  charCode: 69,
  keyCode: 69,
  view: window,
  bubbles: true, // please test this value for your case
});

/**
 *
 * give an array of bounding box object in form of
 *
 * {
 *  id: Number,
 *  start: [x0, y0],
 *  end: [x1, y1],
 *  scaledMatrix: [x0, y0, x1, y1],
 *  typeId: Number,
 *  type: String,
 *  rating: Number
 * }
 *
 * reduce all objects into a singel object in the form of
 * {
 *    "type0": {
 *              coordinates: [
 *                  {coordinates: scaledMatrix0, id: id0, rating: rating0 }
 *                  {coordinates: scaledMatrix1, id: id1, rating: rating1 }
 *      ]
 *    }
 *   "type1": {
 *              coordinates: [
 *                  {coordinates: scaledMatrix0, id: id0, rating: rating0 }
 *                  {coordinates: scaledMatrix1, id: id1, rating: rating1 }
 *      ]
 *    }
 * }
 *
 * @param {Array} boundingBoxes array of bounding box objects
 */
const getReducedBoundingBoxes = (boundingBoxes) => {
  // filter out locally delected bounding boxes whose
  // start and end properties arre set as null, as
  // they should not be shown on the UI
  const filteredBoundingBoxes = boundingBoxes.filter(
    (box) => box.id > 0 || typeof box.id === "string"
  );
  const reducedBoundingBoxes = filteredBoundingBoxes.reduce((acc, element) => {
    const {
      topLeft,
      topRight,
      bottomRight,
      bottomLeft,
      start,
      end,
      surface_area,
    } = element;
    const type = element.type;
    if (acc[type]) {
      let typeValue = acc[type];
      let coordinates = typeValue.coordinates;
      // coordinates.push(element.scaledMatrix);
      coordinates.push({
        coordinate: {
          topLeft,
          topRight,
          bottomRight,
          bottomLeft,
          start,
          end,
        },
        rating: element.rating,
        id: element.id,
        surface_area: surface_area || "N / A",
      });
      typeValue = { ...typeValue, coordinates: coordinates };
      acc[type] = typeValue;
      return { ...acc };
    } else {
      acc[type] = {
        type: element.type,
        open: false,
        coordinates: [
          {
            coordinate: {
              topLeft,
              topRight,
              bottomRight,
              bottomLeft,
              start,
              end,
            },
            rating: element.rating,
            id: element.id,
            surface_area: surface_area || "N / A",
          },
        ],
      };
      return { ...acc };
    }
  }, {});

  return reducedBoundingBoxes;
};

/**
 * @summary toggles the validity (whether to be remove or not) of the passed data point
 *
 * @description this method toggles the values passed data point's boundingBoxes and
 * removedBoundingBoxes properties.
 *
 * If isRemove is true (false), all values of boundingBoxes (removedBoundingBoxes)
 * would be appended to the values of proerty removedBoundingBoxes (boundingBoxes),
 * and the array of boundingBoxes(removedBoundingBoxes) would be cleared
 *
 * @param {boolean} isRemove
 *
 * @return updated data point
 */
export const toggleBoundingBoxes = (dataPoint, isRemove) => {
  const updatedSelectedData = JSON.parse(JSON.stringify(dataPoint));

  if (isRemove) {
    const { boundingBoxes, removedBoundingBoxes } = updatedSelectedData;
    updatedSelectedData.removedBoundingBoxes =
      removedBoundingBoxes.concat(boundingBoxes);
    updatedSelectedData.boundingBoxes = [];
  } else {
    const { boundingBoxes, removedBoundingBoxes } = updatedSelectedData;
    updatedSelectedData.boundingBoxes =
      boundingBoxes.concat(removedBoundingBoxes);
    updatedSelectedData.removedBoundingBoxes = [];
  }

  return updatedSelectedData;
};

function BoundingBoxComponent(props) {
  const {
    selectedData,
    updateSelectedData,
    labelGroup,
    labelPreference = [],
    handleShapeFile,
    changeCreateWorkOrderState
  } = props;

  const [grouppdBoundingBoxes, setgrouppdBoundingBoxes] = useState(null);
  const [isRemoveAllBoundingBoxes, setIsRemoveAllBoundingBoxes] =
    useState(true);

  const [labelOptions, setLabelOptions] = useState({
    allRootLabels: [],
    userPreference: [],
  });

  const restoreLastRemovedBoundingBox = (dataPoint) => {
    const copiedDataPoint = JSON.parse(JSON.stringify(dataPoint));
    const { boundingBoxes, removedBoundingBoxes } = copiedDataPoint;
    const target = removedBoundingBoxes.pop();

    if (target) {
      boundingBoxes.push(target);
    }
    copiedDataPoint.boundingBoxes = boundingBoxes;
    copiedDataPoint.removedBoundingBoxes = removedBoundingBoxes;
    updateSelectedData(copiedDataPoint);
  };

  const uploadShapeFileProps = {
    uploadFileType: ".zip",
    buttonContent: "Use ShapeFile",
    handleUploadFile: (uploadFile) => {

      const reader = new FileReader();

      reader.onload = function () {
        const arrayBuffer = this.result;

        shp(arrayBuffer).then(function (geojson) {
          handleShapeFile(geojson)
        }).catch((err) => { alert("Could not load ShapeFile") });
      }
      reader.onerror = x => { alert("Could not load ShapeFile") }
      reader.readAsArrayBuffer(uploadFile);


    },
    FilesList: [],
    setUploadFilesList: () => { },
    isDisabled: false,
  };

  /**
   * @summary method that handle key combos globally.
   *
   * @description key event combo includes
   * 1. control + z  => restore last removed bounding box;
   * 2. enter        => open the rating component on the cavnas compoent for eiditting the given bounding box
   *
   * For the "enter" key event, it actually emits a key evnet of "e" to the canvas component. The latter, which
   * it is higlighted, is set up to listen that event and reply by bringing up the bounding box rating component.
   *
   * @param {KeyboardEvent} event key event that user triggers
   * @see BoundingBoxCanvas
   */
  const handleKeyUp = (event) => {
    // ignore short cut keys combo from input fields
    const tagName = event.target.tagName.toLowerCase();
    if (tagName === "input" || tagName === "body" || tagName === "document") {
      return;
    }

    console.log(`event.target.tagName`, event.target.tagName);

    event.stopPropagation();
    if (selectedData) {
      // shift + d, remvoe / restore all bounding box
      if (event.shiftKey && event.keyCode === 68) {
        let updatedSelectedData = toggleBoundingBoxes(
          selectedData,
          !isRemoveAllBoundingBoxes
        );
        updateSelectedData(updatedSelectedData);
      }

      // shift + z, restore last deleted bounding box
      if (event.ctrlKey && event.key === "z") {
        restoreLastRemovedBoundingBox(selectedData);
      }

      // a bounding box is selected and user hit enter
      // bring up the rating component for user to update
      // the selected bounding box
      if (
        selectedData.selectedBoundingBox &&
        event.key.toLowerCase() === "enter"
      ) {
        // find the canvas component
        const canvasComponent = document.getElementById(
          CANVAS_COMPONENT_DOM_ID
        );
        if (canvasComponent) {
          // as enter key event has been set up properly in
          // that component. So simply "hit" enter key programmatically
          canvasComponent.focus();
          canvasComponent.dispatchEvent(ENTER_KEY_EVENT);
        }
      }
    }
  };

  useEffect(() => {
    if (selectedData && selectedData.boundingBoxes) {
      const reducedBoundingBoxes = getReducedBoundingBoxes(
        selectedData.boundingBoxes
      );

      setgrouppdBoundingBoxes(reducedBoundingBoxes);
      setIsRemoveAllBoundingBoxes(
        selectedData && selectedData.boundingBoxes.length === 0
      );
    } else {
      setgrouppdBoundingBoxes(null);
    }
  }, [selectedData]);

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [
    selectedData,
    updateSelectedData,
    toggleBoundingBoxes,
    isRemoveAllBoundingBoxes,
    restoreLastRemovedBoundingBox,
  ]);

  useEffect(() => {
    let labelRoot = [];
    const userPreference = [...labelPreference];
    if (Array.isArray(labelGroup) && labelGroup.length > 0) {
      labelRoot = labelGroup.map((group) => ({
        name: group.name,
      }));
    }
    setLabelOptions({
      allRootLabels: labelRoot,
      userPreference: userPreference,
    });
  }, [labelGroup, labelPreference]);

  // const handleChange = (event) => {
  //   console.log(`handleChange`);
  //   const values = event.target.value;
  //   // console.log(`values`, values);
  //   // alert(values);
  //   props.onSelectedLabelPreferece(values);
  // };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ width: "100%" }}>
        {labelOptions.allRootLabels.length > 0 && (
          <div>
            <IrisMultiSelect
              label="Select label group"
              options={labelOptions.allRootLabels}
              values={labelOptions.userPreference}
              valueField="name"
              labelField="name"
              style={{ minWidth: 0 }}
              onChange={(values) => props.onSelectedLabelPreferece(values)}
            />
            <IrisSlider
              disabled={labelOptions.userPreference.indexOf("PCI") < 0}
              label="Number of Lanes"
              value={props.numOfLanes}
              max={5}
              min={1}
              onChange={(value) => {
                const v = Number(value);
                props.setNumOfLanes(v);
              }}
            />
            <IrisSlider
              disabled={labelOptions.userPreference.indexOf("PCI") < 0}
              label="Lane Width"
              unit="m"
              value={props.widthPerLane}
              max={5}
              min={3}
              step={0.1}
              onChange={(value) => {
                props.setWidthPerLane(Number(value));
              }}
            />
            <div style={{ maxHeight: "55px" }}>
              <UploadFile {...uploadShapeFileProps} />
            </div>
            <IrisButton color="secondary" size="medium" onClick={() => { handleShapeFile(null) }}>
              Clear ShapeFile
            </IrisButton>
            {/* !TODO Enable again once feature becomes more refined */}
            {/* <FormControlLabel
              control={<Switch
                onChange={(event) => { changeCreateWorkOrderState(event.target.checked) }}
              />}
              label="Create work order with label"
              labelPlacement="start"
            /> */}


          </div>
        )}
      </div>

      {grouppdBoundingBoxes !== null &&
        grouppdBoundingBoxes !== undefined &&
        Object.keys(grouppdBoundingBoxes).length !== 0 ? (
        <BoundingBoxTypeList dataObject={grouppdBoundingBoxes} />
      ) : (
        <div
          style={{
            flex: 1,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <h2>No bounding boxes</h2>
          {/* 
            show undo remove button when there is no valid bounding boxes 
            (ie: those id is number AND > 0, which were fetched from the server)
            but the selected data point's boudning box array is not empty
            (ie: those id is number AND < 0, namely, those were "removed" by user by pressing the "Remove all"
            on the bounding box list)
            As those "INVALID" data points are still local (have no been updated to the server yet), they are
            eligable to be "restored" (ie: their remove stated, IOW, id < 0, could be undone)
            */}
          {selectedData && selectedData.removedBoundingBoxes.length > 0 && (
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {
                const updatedSelectedData = toggleBoundingBoxes(
                  selectedData,
                  false
                );
                updateSelectedData(updatedSelectedData);
              }}
            >
              Restore all
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

BoundingBoxComponent.propTypes = {
  selectedData: PropTypes.object,
  updateSelectedData: PropTypes.func.isRequired,
  handleShapeFile: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    numOfLanes: state.dataPointsState.numOfLanes,
    widthPerLane: state.dataPointsState.widthPerLane,
  };
};
const mapDispatchToProps = {
  setNumOfLanes,
  setWidthPerLane,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoundingBoxComponent);
/**
 * Change Log:
 *
 * Change Date: Jan 15, 2021
 *
 * Description: add feature use shortcut to remove (restore) all bounding boxes
 * NOTE: those fetched from the server would be able to be restored, for those drawn
 * by user but not yet send to server yet, they are not eligible to be restored after
 * removal.
 */

/**
 * Change Log:
 *
 * Change Date: Jan 21, 2021
 *
 * Description:
 * updated @see handleKeyUp
 * 1. added ctrl + z to restore a bounding box, from the most latest to the least latest
 *
 * 2. excluded both shortcuts "shift + d" and "ctrl + z" from taking effective out of
 *    any Input element.
 *
 * 3. add button to allow user to restore all removed bounding boxes.
 */

/**
 * Change Log:
 *
 * Change Date: Feb 13, 2021
 *
 * Description:
 * added feature: after a user selected a bounding box from the list component and hit
 * "enter", the rating bounding box component would be shown and allow the user to edit
 * the selected bounding box
 *
 * @see handleKeyUp
 */

/**
 * Change Log:
 *
 * Change Date: Jun 10, 2021
 *
 * Description: added multi select component
 *
 * User is able to use this selector to toggle the types of labels, ie "MMS", "PCI", "Right Of Way", "Signs", "Traffic Signs"
 * and "Waste". This has 2 results.
 *
 * 1. the label items which are shown on the bounding box canvas, after a bounding box is drawn or is selected to be edited,
 *    only the labels which belongs to the selected groups would be render out for user selection.
 *
 *    For example, if the user unchecks the "MMS" selection, then on the canvas component, all labels that belongs to the
 *    "MMS" category would not be rendered out.
 *
 * 2. the bounding boxes, which are attached to the data point, that belongs to the label group, wound not be drawn on the
 *    boudning box canvas, as a result, the user could focus on the type of labels and bounding boxes that he concerns.
 *
 *    For instance, if a data point has 3 label, 2 of them belongs to "MMS" category and 1 belongs to "Signs" category.
 *    By default, all values on the multi select component are checked, so all of these 3 bounding boxes would be shown on the
 *    bounding box canvas.
 *
 *    If the user unchecks the "MMS" selection, then 2 of the bounidng boxes would be visually removed from the bounding box canvas,
 *    which mean the use would not be able to edit them. If he checks the same selection, those bounding boxes would be drawn
 *    back to the bounding box canvas.
 */

/**
 * Change Log:
 *
 * Change Date: Aug 07, 2021
 *
 * Description: moved numOfLanes and widthPerLane from this component
 * to the redux store so that when Home.js submits bounding boxes info,
 * it would have asccess to both properties which are required in the payload
 * of the calculate pci API
 */
