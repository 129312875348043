/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Sep 28, 2021
 *
 * Description: This file defines some actions that interact with the state
 * of the login status of a user.
 */

import { getUserGroups, login } from "../../../awsBackend/awsBackendUtils";
import {
  USER_NAME,
  PASSWORD,
  TOKEN,
  REFRESH_TOKEN,
  DEVICES,
} from "../../../components/login/localStorageKeys";
import { resetDataPointsState } from "../dataPointsReducer/dataPointActions";
import { setToastMessage } from "../globalReducer/globalActions";
import { getDevices } from "../../../awsBackend/awsBackendUtils";
import { TreeBuilder } from "../../../utils/MenuTree";
import { listDefectTypes } from "../../../awsBackend/irisDataUtils";
import { getAWSToken } from "../../../utils/cognitoUtils";
import { setupDatadog } from "../../../datadog/config.js";

// import * as actionTypes from "../../actions/actionTypes";

export const actionTypes = {
  loginStarted: "LOGIN_STARTED",
  loginSucceeded: "LOGIN_SUCCEEDED",
  loginFailed: "LOGIN_FAILED",
  loginReset: "LOGIN_RESET",
  setDamageTypes: "SET DAMAGE TYPES",
  setDevices: "SET DEVICES",
  setLabelTypes: "SET LABEL TYPES",
};

const saveUserLocally = (userName, password, data) => {
  localStorage.setItem(USER_NAME, userName);
  localStorage.setItem(PASSWORD, password);
  localStorage.setItem(TOKEN, data.access);
  localStorage.setItem(REFRESH_TOKEN, data.refresh);
};
export const startLogin = (userName, password, accessToken = "") => {
  return async (dispatch) => {
    let token = accessToken;
    let refreshToken = "";
    dispatch(loginStarted());
    try {
      // TODO remove this hardcoded credentials when backend team sync cognito user with the iris backend
      const userName = process.env.REACT_APP_LABELLER_USER_NAME;
      const password = process.env.REACT_APP_LABELLER_PASSWORD;
      if (token === "") {
        const data = await login(userName, password);
        token = data.access;
        refreshToken = data.refresh;
      }
      const { foundUser, damageTypes, deviceInfo, labelTypes } =
        await getUserGroups(userName, token);
      const adminGroup = foundUser.groups.find((g) => g.name === "admin");
      const labelerGroup = foundUser.groups.find((g) => g.name === "labelers");
      const employeeGroup = foundUser.groups.find((g) => g.name === "employee");

      let alteredLabels = labelTypes.map((label) => {
        const { rating_range, range_title, parent, city_id } = label;
        label.ratingRange = rating_range;
        label.ratingTitle = range_title;
        label.parentId = parent;

        label.cityId = city_id;

        delete label.rating_range;
        delete label.range_title;
        delete label.parent;
        delete label.city_id;

        return label;
      });

      // this defect type is set up for search bar drop down list
      const awsToken = await getAWSToken();
      const defectTypeIrisData = await listDefectTypes(awsToken);
      const defectNames = defectTypeIrisData.map((ele) => ele.name);

      const DUPLICATES = {
        "Bridge Deck Spall": 455,
        "Pothole Paved Surface": 402,
        "Pothole Shoulder": 404,
        Debris: 407,
      };
      alteredLabels = alteredLabels.filter((ele) => {
        const foundId = DUPLICATES[ele.name];

        if (defectNames.indexOf(ele.name) > -1) {
          if (!foundId) {
            return true;
          }
          if (foundId === ele.id) {
            return true;
          }
        }
      });

      alteredLabels.forEach((ele) => {
        // parent child relation about defect type is no longer valid,
        // set to null so that the build tree proccess will be okay
        ele.parentId = null;
      });

      const labelTree = new TreeBuilder(
        alteredLabels,
        "parentId",
        "id",
        "subMenu",
        "name"
      );
      const rootLabels = labelTree.rootNotes;
      foundUser.adminGroup = adminGroup;
      foundUser.labelerGroup = labelerGroup;
      foundUser.employeeGroup = employeeGroup;
      foundUser.access = token;
      foundUser.refresh = refreshToken;
      dispatch(setToastMessage(`Welcome ${userName}`));
      dispatch(loginSucceeded(foundUser));
      dispatch(setDevices(deviceInfo));
      dispatch(setDamageTypes(damageTypes));
      dispatch(setLabelTypes(rootLabels));
      dispatch(resetDataPointsState());
      saveUserLocally(userName, password, foundUser);

      // download and save device list
      let devices = await getDevices();
      localStorage.setItem(DEVICES, JSON.stringify(devices));
      setupDatadog(userName);
    } catch (error) {
      console.log(`error`, error);

      dispatch(
        loginFailed({
          title: "Login error",
          message: "Please check your user name and password",
        })
      );
    }
  };
};
export const loginStarted = () => ({ type: actionTypes.loginStarted });
export const loginSucceeded = (userData) => ({
  type: actionTypes.loginSucceeded,
  payload: userData,
});
export const loginFailed = (errorObject) => ({
  type: actionTypes.loginFailed,
  payload: errorObject,
});
export const loginReset = () => ({ type: actionTypes.loginReset });

export const setDamageTypes = (damageTypes) => ({
  type: actionTypes.setDamageTypes,
  payload: damageTypes,
});

export const setDevices = (deviceArray) => ({
  type: actionTypes.setDevices,
  payload: deviceArray,
});

export const setLabelTypes = (labelTypes) => ({
  type: actionTypes.setLabelTypes,
  payload: labelTypes,
});

/**
 * Change Log:
 *
 * Change Date: Sep 28, 2020
 *
 * Description: file created.
 */

/**
 * Change Log:
 *
 * Change Date: Aug 07, 2021
 *
 * Description:
 * 1. retrieve root label types and save to redux store, instead
 * of saving all of them and retrieve the root labels when it is
 * needed.
 */
