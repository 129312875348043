import { IRIS_RED } from "../../UI/uiConstants";
// import { createMuiTheme } from '@material-ui/core/styles';
// export const datePickerTheme = createMuiTheme({
//     datePicker: {
//         color: "red",
//         textColor: "red",
//         calendarTextColor: "red",
//         selectColor: "red",
//         selectTextColor: "red",
//         calendarYearBackgroundColor: "red",
//         headerColor: "red"
//     }
// });
export const useStyles = (theme) => ({
  main: {
    // maxWidth: "1980px",
    margin: "auto",
  },
  mainLeft: {
    margin: theme.spacing(1),
  },

  mainRight: {
    margin: theme.spacing(1),
  },
  // [global] Customized scroll bar
  "@global": {
    "*::-webkit-scrollbar": {
      width: "12px",
    },
    "*::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },

  table: {
    minWidth: 300,
    height: 300,
    backgroundColor: "#e8eaf6",
  },

  tableHeader: {
    // backgroundColor: '#3f51b5',
    background: `linear-gradient(45deg, ${IRIS_RED} 30%, #FF8E53 90%)`,
  },

  tableHeaderFont: {
    color: "white",
    cursor: "pointer",
  },

  searchArea: {
    margin: theme.spacing(1),
  },

  videoFrame: {
    width: "100%",
    height: "50vh",
    // marginTop: 40,
    marginBottom: "1rem",
  },

  imageContainer: {
    width: "100%",
    height: "50vh",
    marginBottom: "1rem",
  },

  myMap: {
    width: "100%",
    height: 300,
  },
  rotateImage: {
    transform: "rotateX(180deg)",
    "-webkit-transform": "rotateX(180deg)" /* Safari and Chrome */,
    "-moz-transform": "rotateX(180deg)" /* Firefox */,
  },
});
