import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
}));

/**
 *
 * @param {Object} props
 * @param {Object<string, string> []} props.values
 * @param {string} props.labelField
 * @param {string} props.valueField
 * @param {string} props.label
 * @param {(value: Object<string, string>) => {}} props.onSelect
 * @param {string} props.value
 * @param {boolean} props.fullWidth
 * @param {boolean} props.disabled
 * @returns
 */
export default function MuiSelect(props) {
  const {
    values,
    labelField,
    valueField,
    label = "",
    onSelect: handleSelect,
    value: defaultValue = "",
    fullWidth = false,
    disabled = false,
  } = props;
  const [value, setValue] = React.useState(defaultValue);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event, chil) => {
    const selectedValue = event.target.value;
    if (typeof selectedValue === "string") {
      let foundObj = values.find(
        (value) => value[valueField] === selectedValue
      );

      if (typeof handleSelect === "function") {
        if (!foundObj) {
          foundObj = { [labelField]: "", [valueField]: "" };
        }
        handleSelect(foundObj);
      }

      setValue(selectedValue);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (typeof defaultValue === "string") {
      setValue(defaultValue);
    } else if (typeof defaultValue === "object" && defaultValue[valueField]) {
      setValue(defaultValue[valueField]);
    }
  }, [defaultValue, valueField]);

  return (
    <div>
      <FormControl fullWidth={fullWidth}>
        <InputLabel>{label}</InputLabel>
        <Select
          disabled={disabled}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={value}
          onChange={handleChange}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {/* show none selection only when value === "", ie: on mounted */}
          {value === "" && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {values.map((ele, index) => {
            const value = ele[valueField];
            const label = ele[labelField];
            return (
              <MenuItem key={index} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
