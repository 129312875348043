import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { IRIS_RED, BUTTON_HEIGHT, IRIS_BLUE } from "../uiConstants";

const useStyles = makeStyles({
  active: {
    background: `linear-gradient(45deg, ${IRIS_RED} 30%, #FF8E53 90%)`,
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: BUTTON_HEIGHT,
    padding: "0 30px",
    fontSize: "1.8rem",

    "&:hover": {
      background: `linear-gradient(45deg, ${IRIS_BLUE} 30%, ${IRIS_RED} 90%)`,
    },
  },
  disabled: {
    backgroundColor: "rgba(0,0,0,0.12)",
    border: 0,
    borderRadius: 3,
    height: BUTTON_HEIGHT,
    padding: "0 30px",
  },
});

export const IRISButton = (props) => {
  const { disabled } = props;
  const classes = useStyles();
  return (
    <Button
      fullWidth={props.fullWidth}
      className={disabled ? classes.disabled : classes.active}
      {...props}
    >
      {props.children}
    </Button>
  );
};
