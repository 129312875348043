import styles from "./MiniMap.module.css";
import { loadModules } from "esri-loader";
import React, { useEffect, useRef, useState } from "react";
import { IrisButton } from "irisrad-ui";
import { updateData } from "../../awsBackend/awsBackendUtils";
import useWorkOrderFeatureLayer from "../../hooks/featuerLayers/useWorkorderFeatureLayer";
import MuiSelect from "../../UI/select/MuiSelect";
import { getAWSToken } from "../../utils/cognitoUtils";
import DateRangePicker from "../irisDateRangePicker/DateRangePicker";
import { Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const DIGIT_PRECISION = 10_000_000;

const TEXT_SYMBOL = (angle = 0) => {
  if (!angle) angle = 0;
  return {
    type: "text", // autocasts as new TextSymbol()
    color: "#7A003C",
    text: "\ue69e", // esri-icon-map-pin
    font: {
      // autocasts as new Font()
      size: 36,
      family: "CalciteWebCoreIcons",
    },
    angle: angle,
    horizontalAlignment: "center",
    verticalAlignment: "top",
  };
};

const LAYER_ID = "layerId";

/**
 * mini map component to show data point on map, including map icon to open modal of map
 * @param {object} props
 * @param {Function} props.onUpdateCurrentDataPoint to update the data point on redux store
 * @param {object} props.currentDataPoint current data point object
 */
const MiniMap = (props) => {
  const {
    onUpdateCurrentDataPoint,
    currentDataPoint,
    open: openModal,
    onClose,
    geojson,
    handleUpdateDefectsLocation,
  } = props;

  const {
    latitude: initLat,
    longitude: initLon,
    id: dataPointID,
    device_sn: {
      city: { name: region, timezone },
    },
    heading,
  } = currentDataPoint;

  const {
    layer,
    workorderTypes,
    selectedCategory,
    setSelectedCategory: updateCategory,
    setAwsToken: updateToken,
    updateDateRange,
    queryStartDate,
    queryEndDate,
  } = useWorkOrderFeatureLayer(true, LAYER_ID, region, timezone);

  // set state of marked point location
  const [locationState, setLocationState] = useState([initLon, initLat]);

  // set state of open modal of map
  const [open, setOpen] = useState(openModal);
  const [map, setMap] = useState();

  const mapDivRef = useRef(null);
  const addressRef = useRef(null);
  const filterDivRef = useRef(null);
  const dateRangePickerRef = useRef(null);
  const dateRangeRef = useRef();

  // to close modal
  const handleClose = (e) => {
    e.stopPropagation();
    // material icon className is an object rather than string
    if (typeof e.target.className === "string") {
      if (e.target.className.includes("backdrop")) {
        onClose();
      }
    }
  };

  // to update current data point location, both to backend and to redux store
  const handleSave = (e) => {
    e.stopPropagation();
    onClose();

    const [newLon, newLat] = locationState;
    currentDataPoint.longitude = newLon;
    currentDataPoint.latitude = newLat;

    // update data point location to backend
    updateData(dataPointID, {
      longitude: newLon,
      latitude: newLat,
    });

    // update data point location to redux store
    onUpdateCurrentDataPoint(currentDataPoint);
    // update data point's defects' geo
    const id = currentDataPoint.id;
    if (id > 0 && typeof handleUpdateDefectsLocation == "function") {
      handleUpdateDefectsLocation(id, newLat, newLon);
    }
  };

  // // load map when long and lat changes
  useEffect(() => {
    setLocationState([initLon, initLat]);
    const getCongiguredGraphic = (GraphicClass, point) => {
      return new GraphicClass({
        geometry: point,
        symbol: TEXT_SYMBOL(heading),
        attributes: {
          latitude: point.latitude,
          longitude: point.longitude,
        },
        popupTemplate: {
          title: "location",
          content: [
            {
              type: "fields",
              fieldInfos: [
                {
                  fieldName: "latitude",
                  format: {
                    places: 7,
                    digitSeparator: true,
                  },
                },
                {
                  fieldName: "longitude",
                  format: {
                    places: 7,
                    digitSeparator: true,
                  },
                },
              ],
            },
          ],
        },
      });
    };
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/Graphic",
        "esri/geometry/Point",
        "esri/widgets/Expand",
        "esri/widgets/BasemapGallery",
        "esri/widgets/Legend",
        "esri/layers/GeoJSONLayer",
      ],
      { css: true }
    )
      .then(
        async ([
          Map,
          MapView,
          Graphic,
          Point,
          Expand,
          BasemapGallery,
          Legend,
          GeoJSONLayer,
        ]) => {
          const point = new Point([initLon, initLat]);
          // Create a symbol for drawing the point
          const pointGraphic = getCongiguredGraphic(Graphic, point);

          if (mapDivRef.current) {
            // map style
            const map = new Map({
              basemap: "gray-vector", //Basemap layer service
            });

            // initial view port of the map, including both position and zoom
            const view = new MapView({
              map: map,
              center: [Number(initLon), Number(initLat)], // longitude, latitude
              zoom: 16,
              container: mapDivRef.current,
              popup: {
                defaultPopupTemplateEnabled: true,
              },
            });

            const basemapGallery = new BasemapGallery({
              view: view,
              container: document.createElement("div"),
            });

            const bgExpand = new Expand({
              view: view,
              content: basemapGallery,
            });
            view.ui.add(bgExpand, "top-right");
            view.ui.add(dateRangePickerRef.current, "top-right");
            view.ui.add(addressRef.current, "top-right");
            view.ui.add(filterDivRef.current, "top-right");

            let draggingGraphic, tempGraphic;

            // triggered when dragging the point on map
            view.on("drag", async (e) => {
              // if this is the starting of the drag, do a hitTest
              if (e.action === "start") {
                view.hitTest(e).then((resp) => {
                  if (
                    resp.results[0]?.graphic &&
                    resp.results[0]?.graphic.geometry?.type === "point"
                  ) {
                    e.stopPropagation();
                    // if the hitTest returns a point graphic, set draggingGraphic
                    draggingGraphic = resp.results[0].graphic;
                  }
                });
              } else if (e.action === "update") {
                // on drag update events, only continue if a draggingGraphic is set
                if (draggingGraphic) {
                  view.popup.close();
                  e.stopPropagation();
                  // if there is a tempGraphic, remove it
                  if (tempGraphic) {
                    view.graphics.remove(tempGraphic);
                  } else {
                    // if there is no tempGraphic, this is the first update event, so remove original graphic
                    view.graphics.remove(draggingGraphic);
                  }
                  // create new temp graphic and add it
                  tempGraphic = draggingGraphic.clone();
                  tempGraphic.geometry = view.toMap(e);
                  view.graphics.add(tempGraphic);
                }
              } else if (e.action === "end") {
                // on drag end, continue only if there is a draggingGraphic
                if (draggingGraphic) {
                  e.stopPropagation();
                  // remove temp
                  if (tempGraphic) view.graphics.remove(tempGraphic);
                  // create new graphic based on original dragging graphic

                  let newGraphic = draggingGraphic.clone();

                  newGraphic.geometry = tempGraphic.geometry.clone();

                  const {
                    geometry: { latitude, longitude },
                  } = newGraphic;

                  const smallLat =
                    Math.round(latitude * DIGIT_PRECISION) / DIGIT_PRECISION;
                  const smallLon =
                    Math.round(longitude * DIGIT_PRECISION) / DIGIT_PRECISION;
                  setLocationState([smallLon, smallLat]);
                  /**
                   * configured graphic with updated popup template
                   */
                  const configuredGraphic = getCongiguredGraphic(
                    Graphic,
                    tempGraphic.geometry
                  );

                  // add replacement graphic
                  view.graphics.add(configuredGraphic);

                  if (addressRef.current) {
                    addressRef.current.innerHTML = `${smallLat}, ${smallLon}`;
                  }

                  draggingGraphic = null;
                  tempGraphic = null;
                }
              }
            });

            if (geojson) {
              const blob = new Blob([JSON.stringify(geojson)], {
                type: "application/json",
              });

              // URL reference to the blob
              const url = URL.createObjectURL(blob);
              // create new geojson layer using the blob url
              const layer = new GeoJSONLayer({
                url,
              });
              map.add(layer);
            }

            view.graphics.add(pointGraphic);
            setMap(map);
            //"esri/widgets/Legend"
            const legend = new Legend({
              view: view,
            });

            // Add widget to the bottom left corner of the view
            view.ui.add(legend, "bottom-left");
          }
        }
      )
      .catch((error) => {
        alert("Error in loading arcgis modules");
      });
  }, [initLon, initLat, open]);

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  useEffect(() => {
    if (map?.layers && layer) {
      /**
       * simply use `map.layers.add();` works
       * but the new added layer will stay on top of others
       * simply find a way to reverse the order
       */
      const layers = map.layers.filter((layer) => layer.id !== LAYER_ID);
      layers.unshift(layer);
      map.layers = layers;
    }
  }, [map, layer]);

  /**
   * when lat lon changed, ie: selected data change
   * use Auth to updated the access token if necessary
   */
  useEffect(() => {
    getAWSToken().then((token) => {
      updateToken(token);
    });
  }, [initLon, initLat]);

  return (
    <div>
      {open && (
        <div className={styles.backdrop} onClick={handleClose}>
          <div className={styles.modal}>
            <div className={styles.map} ref={mapDivRef}></div>

            <div ref={filterDivRef} className={styles.layerFilterDiv}>
              <MuiSelect
                values={workorderTypes}
                labelField="name"
                valueField="id"
                label="Filter by work order category"
                value={selectedCategory}
                onSelect={(categoryObj) => updateCategory(categoryObj.name)}
                fullWidth
              ></MuiSelect>
            </div>
            <div ref={dateRangePickerRef} className={styles.dateRangePicker}>
              <DateRangePicker
                updateDateRange={(startDate, endDate) =>
                  (dateRangeRef.current = [startDate, endDate])
                }
                startDate={queryStartDate}
                endDate={queryEndDate}
              />
              <Button
                variant="contained"
                endIcon={<SearchIcon />}
                color="primary"
                onClick={() => {
                  const {
                    current: [startDate, endDate],
                  } = dateRangeRef;
                  updateDateRange(startDate, endDate);
                }}
              >
                Search
              </Button>
            </div>
            <div ref={addressRef} className={styles.modalInfo} id="addressSpan">
              <div>
                {initLat}, {initLon}
              </div>
            </div>
            <div className={styles.buttonGroup}>
              <IrisButton
                size="large"
                iconProps={{
                  iconTitle: "save",
                  size: "large",
                }}
                color="primary"
                disabled={
                  initLat.toFixed(7) === locationState[1].toFixed(7) &&
                  initLon.toFixed(7) === locationState[0].toFixed(7)
                }
                onClick={handleSave}
              >
                Save
              </IrisButton>
              <IrisButton
                size="large"
                iconProps={{
                  iconTitle: "cancel",
                  size: "large",
                }}
                color="secondary"
                disabled={
                  Number(initLat.toFixed(7)) ===
                    Number(locationState[1].toFixed(7)) &&
                  Number(initLon.toFixed(7)) ===
                    Number(locationState[0].toFixed(7))
                }
                onClick={onClose}
              >
                Cancel
              </IrisButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MiniMap;
