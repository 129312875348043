import DateUtils from "../utils/DateUtils";
import { DEFECTS_URL, IRIS_WORK_ORDER_END_POINT, LIST_DEFECT_TYPE_URL } from "./backendURLs";

/**
 *
 * @param {string} token aws token returned from the iris-data server
 * @param {string} region
 * @param {string} startDate utc, use client timezone, timestamp
 * @param {string} endDate utc, use client timezone, timestamp
 * @returns
 */
export const getWorkOrders = async (token, region, startDate, endDate) => {
  const d = new Date();
  const param = {
    region: {
      operant: "eq",
      value0: region,
    },
    open_datetime: {
      operant: "between",
      value0: startDate,
      value1: endDate,
    },
  };
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(param),
  };
  return new Promise(async (resolve, reject) => {
    let error;

    const response = await fetch(IRIS_WORK_ORDER_END_POINT, options).catch(
      (e) => {
        error = e;
      }
    );

    if (error) {
      // caught error due to fetch API
      reject(error);
    } else if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson.data);
    } else {
      const { status } = response;
      const message = await response.text();
      const errorMessage = `Error status: message`;
      if (status === 401 || status === 403) {
        error = new Error("ACCESS DENIED");
      } else if (status === 404) {
        error = new Error("PAGE NOT FOUND");
      } else if (status >= 500) {
        error = new Error("SERVER ERROR");
      } else {
        error = new Error("UNKNOWN ERROR");
      }
      reject(error);
    }
  });
};


/**
 *
 * @returns
 */
export const listDefectTypes = async (awsToken) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${awsToken}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(LIST_DEFECT_TYPE_URL, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const message = await response.text();
      const { status } = response;
      if (status === 401 || status === 403) {
        error = new Error(`code: ${status}, access denied, ${message}`);
      } else if (status === 404) {
        error = new Error(`code: ${status}, page not found, ${message}`);
      } else if (status >= 500) {
        error = new Error(`code: ${status}, internal server error, ${message}`);
      } else {
        error = new Error(`general_error_message: ${message}`);
      }
      reject(error);
    }
  });
};




/**
 *
 * @returns
 */
export const createDefect = async (data, awsToken) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${awsToken}`,
    },
    body: JSON.stringify(data)
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(DEFECTS_URL, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const message = await response.text();
      const { status } = response;
      if (status === 401 || status === 403) {
        error = new Error(`code: ${status}, access denied, ${message}`);
      } else if (status === 404) {
        error = new Error(`code: ${status}, page not found, ${message}`);
      } else if (status >= 500) {
        error = new Error(`code: ${status}, internal server error, ${message}`);
      } else {
        error = new Error(`general_error_message: ${message}`);
      }
      reject(error);
    }
  });
};


/**
 *
 * @returns
 */
export const getDefectsWithFilter = async (filterParams, awsToken) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${awsToken}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(DEFECTS_URL + '?' + new URLSearchParams(filterParams), options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const message = await response.text();
      const { status } = response;
      if (status === 401 || status === 403) {
        error = new Error(`code: ${status}, access denied, ${message}`);
      } else if (status === 404) {
        error = new Error(`code: ${status}, page not found, ${message}`);
      } else if (status >= 500) {
        error = new Error(`code: ${status}, internal server error, ${message}`);
      } else {
        error = new Error(`general_error_message: ${message}`);
      }
      reject(error);
    }
  });
};


/**
 *
 * @returns
 */
export const updateDefect = async (defectID, data, awsToken) => {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${awsToken}`,
    },
    body: JSON.stringify(data)
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(DEFECTS_URL + `${defectID}/`, options);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      const message = await response.text();
      const { status } = response;
      if (status === 401 || status === 403) {
        error = new Error(`code: ${status}, access denied, ${message}`);
      } else if (status === 404) {
        error = new Error(`code: ${status}, page not found, ${message}`);
      } else if (status >= 500) {
        error = new Error(`code: ${status}, internal server error, ${message}`);
      } else {
        error = new Error(`general_error_message: ${message}`);
      }
      reject(error);
    }
  });
};

/**
 *
 * @returns
 */
export const deleteDefect = async (defectID, awsToken) => {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${awsToken}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    const response = await fetch(DEFECTS_URL + `${defectID}/`, options);
    let error;
    if (response.ok) {
      resolve("Deleted");
    } else {
      const message = await response.text();
      const { status } = response;
      if (status === 401 || status === 403) {
        error = new Error(`code: ${status}, access denied, ${message}`);
      } else if (status === 404) {
        error = new Error(`code: ${status}, page not found, ${message}`);
      } else if (status >= 500) {
        error = new Error(`code: ${status}, internal server error, ${message}`);
      } else {
        error = new Error(`general_error_message: ${message}`);
      }
      reject(error);
    }
  });
};
