/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Nov 11, 2021
 *
 * Description:
 * An element as a prop up menu from where user could select
 * a bounding box title, either from toggling menu list itmes or directly
 * serach title by typing on the search text field.
 *
 * If the selected title has not default rating, another component would be
 * shown up for user to select a custom rating.
 *
 * After both title and rating are defined for a bounding box, its creation
 * should be done, and the popup menu(this component) should be dismissed.
 */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { IconButton, makeStyles, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import RatingComponent from "../irisDropDownSingleSelect/RatingComponent";
import { connect } from "react-redux";
import { setToastMessage } from "../../redux/reducers/globalReducer/globalActions";
import { IrisButton } from "irisrad-ui";
import { sortMenuByName } from "../../redux/reducers/dataPointsReducer/dataPointActions";
import { recursiveMenu } from "../../utils/utilFunctions";

const TAG = "BoundingBoxMenu.js";
const SERACH_TEXT_FIELD_ID = "searchTextField";

// /**
//  * @summary flatten the multi level menu into one-dimension menu
//  *
//  * @description while flattening the menu, keeps traks the parents links for the
//  * root parent from most remote parent to its parent
//  *
//  * For instance, if a menu's (label's) parents field looks like this
//  * parents: ["MMS", "Signage Issue", "Damaged Sign"], which means that
//  * while "MMS" is the grand grand parent, the "Damaged Sign" is its parent
//  *
//  * @param {Array} menuArray an array of menu objects
//  * @param {Array} rootArray menu tree
//  */
// export const recursiveMenu = (menuArray, rootArray, parents) => {
//   let a = rootArray;

//   for (const ma of menuArray) {
//     let ps = parents.concat(ma.name);
//     ma.parents = ps;
//     if (ma.subMenu.length !== 0) {
//       recursiveMenu(ma.subMenu, a, ps);
//     } else {
//       a.push(ma);
//     }
//   }
//   return a;
// };

const useStyles = makeStyles(() => ({
  backButton: {
    fontSize: "1.4rem",
  },
}));

/**
 * get the rating range for the showRatingComponent() method to generate the rating component
 *
 * @see showRatingComponent
 *
 * @returns an array of rating objects, where each one has id and title properties.
 */

const getratingRange = (rangeValue, title) => {
  let range = rangeValue;
  if (rangeValue === 0) {
    range = 1;
  }
  const rangeObjectArray = [{ id: 0, name: "Please select" }];
  for (let i = 1; i <= range; i++) {
    rangeObjectArray.push({ id: i, name: `${title} ` + i });
  }
  return rangeObjectArray;
};

function BoundingBoxMenu(props) {
  const { menu, anchorElement, boundingBox } = props;
  const { setToastMessage } = props; // method from redux store
  const [anchorEl, setMenuAnchorElement] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedRating, setSelectedRating] = useState(null);
  // const [currentMenu, setCurrentMenu] = useState(menu);
  const [menus, setMenus] = useState({
    allMenus: [],
    filteredMenu: [],
    selectedMenu: null,
  });

  const menuRef = useRef();

  // // const rootMenu = recursiveMenu(menu, []);
  // const [rootMenu, setRootMenu] = useState([]);

  const classes = useStyles();

  /**
   * @description show the rating component for a menu (label) for user to pick a rating for it
   *
   * @see getratingRange
   *
   * @param {Object} menu
   * @param {Number} selectedRatingIndex
   */
  const showRatingComponent = useCallback(
    (menu, selectedRatingIndex) => {
      /**
       * {
       *  title
       *  selectedRage
       *  range,
       * }
       */

      let index = selectedRatingIndex;
      if (selectedRatingIndex === null || selectedRatingIndex === undefined) {
        index = 0;
      }
      if (menu.ratingRange === 0) {
        index = 1;
      }
      const range = getratingRange(menu.ratingRange, menu.ratingTitle);
      setSelectedRating({
        title: menu.name,
        ratingTitle: menu.ratingTitle,
        rating: range[index],
        range,
      });
    },
    [setSelectedRating]
  );

  useEffect(() => {
    const temp = recursiveMenu(menu, [], []);
    const rootMenu = temp.filter((menu) => menu.enabled.toLowerCase() === "y");
    const sortedMenu = sortMenuByName(rootMenu);
    const copyRootMenu = JSON.parse(JSON.stringify(sortedMenu));
    setMenus({ allMenus: sortedMenu, filteredMenu: copyRootMenu, rootMenu });
  }, [menu]);

  useEffect(() => {
    setSelectedRating(null);
    setMenuAnchorElement(anchorElement);
    setSearchText("");
    if (Boolean(anchorElement)) {
      setTimeout(() => {
        focusSearchTextField();
      }, 100);
    }
  }, [anchorElement]);

  /**
   * when user try to modify an existed bounding box
   * 1. find the menu object base on bounding box type value
   * 2. set the found menu as currentMenu, reaseon (@see selectionKeyEvent)
   * 3. show the rating component directly (as a result, not showing the root menu) so that
   *    the use could update the rating for the given bounding box
   */
  useEffect(() => {
    if (boundingBox) {
      const { type, rating } = boundingBox;
      if (type && rating !== undefined) {
        const foundMenu = menus.allMenus.find((m) => m.name === type);
        if (!foundMenu) {
          // no menu found for bounding box, return
          return;
        }
        if (foundMenu && foundMenu.ratingRange > 0) {
          setSearchText(type);
          // setCurrentMenu([foundMenu]);
          showRatingComponent(foundMenu, rating);
          setMenus((current) => ({ ...current, selectedMenu: foundMenu }));
          // unfocused the text field, by default, when the menu pops up, it is focused
          // for this case, it should be unfocused so that the user could toggle ratings
          // directly by pressing number key. Otherwise, the user has to use the mouse to
          // select from rating component or press "Tab" key so that the text field would
          // be unfocused.
          setTimeout(() => {
            document.getElementById(SERACH_TEXT_FIELD_ID).blur();
          }, 100);
        } else if (foundMenu.ratingRange === 0) {
          // if the rating range of the found menu is 0
          // let notify the user and he have to select a new label
          // for the current bounding box
          setToastMessage("No quantitative range for label\n" + type);
        }
      }
    }
  }, [boundingBox, showRatingComponent, setToastMessage]);

  /**
   * @summary a callback function pass to the search text field's onChange property
   *
   * @description while using search menu (label) by name, all menus (labels) without sub menu (label)
   * with matched name would be filtered out and shown to the user
   *
   * @param {Event} event key event
   */
  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    if (value.trim() === "") {
      setSelectedRating(null); // hide rating for a single defects / rri since no defect should be selected
      setMenus((current) => ({
        ...current,
        filteredMenu: JSON.parse(JSON.stringify(current.allMenus)),
      }));
      // setCurrentMenu(menu); // show root menu to user for navigating
    } else {
      // filter out the menus, which has no sub menu, namely, the bottom menu items
      // and show it to the user
      const matchMenuByName = menus.allMenus.filter((menu) =>
        menu.name.toLocaleLowerCase().includes(value)
      );

      const matchMenuById = menus.allMenus.filter((menu) =>
        (menu.id + "").startsWith(value)
      );

      let results =
        matchMenuByName.length > matchMenuById.length
          ? matchMenuByName
          : matchMenuById;
      setMenus((current) => ({ ...current, filteredMenu: results }));
    }
    setSearchText(value);
  };

  /**
   * @description callback function designed for the Cancel button, clear text Icon button
   * or it could be called when menu component is rendered
   */
  const showRootMenu = () => {
    // setCurrentMenu(menu); // set current root menu as current menu
    setSelectedRating(null); // hide rating component in root menu
    setSearchText(""); // clear search text

    document.getElementById(SERACH_TEXT_FIELD_ID).focus(); // auto focus search text field when root menu is shown
    setMenus((current) => ({
      ...current,
      filteredMenu: JSON.parse(JSON.stringify(current.allMenus)),
      selectedMenu: null,
    }));
  };

  /**
   * @summary a callback function for the onClick property in the MenuItem element
   *
   * @description when a menu item is clicked,
   * if it contains sub menu, show its submenu
   *
   * else it has no sub menu
   *     if it's rating is 0, then create the bouding box with that rating and it's item name
   *     else if it's rating is greater than 0, show the rating component for the user to pick a rating for the label
   *
   * @param {Object} menu
   */
  const handleMenuItemClick = (menu) => {
    if (menu.ratingRange === 0) {
      if (menu.ratingTitle === "sqft" || menu.ratingTitle === "ft") {
        // surface area in square meters or length in meters
        // show rating component
        setSearchText(menu.name);

        // setCurrentMenu([menu]);
        showRatingComponent(menu);
        setMenus((current) => ({
          ...current,
          selectedMenu: JSON.parse(JSON.stringify(menu)),
        }));
      } else {
        handleMenuClose(null, menu.name, menu.id, menu.parents[0]);
      }
    } else if (menu.ratingRange > 0) {
      // show rating component
      setSearchText(menu.name);

      // setCurrentMenu([menu]);
      showRatingComponent(menu);
      setMenus((current) => ({
        ...current,
        selectedMenu: JSON.parse(JSON.stringify(menu)),
      }));
    }
    // }
  };

  const handleMenuClick = (event) => {
    setMenuAnchorElement(event.currentTarget);
  };

  /**
   * focus the search text fiel on the menu component when it is displayed to the user
   */

  const focusSearchTextField = () => {
    const textField = document.getElementById(SERACH_TEXT_FIELD_ID);
    if (textField) {
      textField.focus();
      textField.select();
    }
  };

  /**
   * @summary a cacllback function to close the menu, and save the current bounding box to redux store
   *
   * @description A callback functon for the okay button in the rating component
   * and for the "enter" key event after a user selected a rating from the rating component
   * and also for the default behavior when a menu with ratingRange = 0, in which case the
   * rating component should not be shown because there is nothing for a user to select.
   *
   * @param {Event} event
   * @param {String} type bounding box type's name
   * @param {Number} id bounding box type's id
   */
  const handleMenuClose = async (event, type, id, parent) => {
    if (type.toLowerCase() === "tabkeydown") {
      // by pass the tab key which will close the menu and broke other stuff
      return;
    }
    if (type === "backdropClick" || type === "escapeKeyDown") {
      // boundingBox.pop();
      setMenuAnchorElement(null);
      onGiveUpRating();
    } else {
      // const { start, end } = getBoundingBoxUpperLeftAndLowerRightCoordinates(
      //   boundingBox
      // );

      // boundingBox.start = start;
      // boundingBox.end = end;
      boundingBox.type = type;
      boundingBox.typeId = id;
      boundingBox.isPolygon = parent.toLowerCase() === "pci";
      // if variable selectedRating existed, which means a user has to
      // select rating from the rating component, then read the rating id
      // from the current selectedRating.
      if (selectedRating && selectedRating.rating && selectedRating.rating.id) {
        boundingBox.rating = selectedRating.rating.id;
      } else {
        // selectedRating is not set due to selected bottom has ratingRange = 0 (nothing
        // for a user to select from the rating coomponent), set rating rage as 0 (default rating)
        boundingBox.rating = 0;
        if (selectedRating) {
          if (
            selectedRating.ratingTitle === "ft" ||
            selectedRating.ratingTitle === "sqft"
          ) {
            boundingBox.rating = selectedRating.rating;
          }
        }
      }
      setMenuAnchorElement(null);

      window.setTimeout(function () {
        props.onUpdateBoundingBoxes(boundingBox, true);
      }, 20);
    }
    // setSearchText("");
    // setCurrentMenu(menu); // show root menu
    // setSelectedRating(null); // hide rating menu
  };

  /**
   * @summary a callback function for listening to key event, whcih is  passed to the rating component
   *
   * @description when the rating component is mounted, this callback function would be registered as a
   * "keydown" event listener
   *
   * When user press the number key of the keyboard, the assocated rating would be auto selected and displayed
   * to the user, without using the mouse.
   *
   * @param {Event} event key event
   */
  const selectionKeyEvent = (event) => {
    const value = Number(event.key);
    console.log(`selectionKeyEvent`, value);
    // "Enter" key is pressed
    if (event.key.toLowerCase() === "enter") {
      if (selectedRating.rating.id <= 0) {
        // not rating has chosen
        return;
      }
      const menu = menus.selectedMenu;
      if (menu) {
        handleMenuClose(null, menu.name, menu.id, menu.parents[0]);
      }
    }

    // non numeric key is pressed
    if (isNaN(value)) {
      return;
    } else {
      // choose a rating component based on which number key is pressed
      let rating = value;
      const menu = menus.selectedMenu;
      if (
        menu &&
        menu.ratingRange &&
        rating >= 0 &&
        rating <= menu.ratingRange
      ) {
        showRatingComponent(menu, rating);
      }
    }
  };

  /**
   * @summary a callback function pass to the TextField component
   *
   * @param {Event} e key down event
   */
  const handleSearchKeyDown = (e) => {
    const keyName = e.key.toLocaleLowerCase();
    if (keyName !== "arrowdown" && keyName !== "arrowup") {
      // if user pressing any keys other then these two
      e.stopPropagation(); // other menu item would not get keydown event propagated from this menue item
    }

    // user press the escape key on the text field
    if (keyName === "escape") {
      // dismiss the menu component
      setMenuAnchorElement(null);
      // remove current boudning box since user press the escape key
      onGiveUpRating();
    }
  };

  /**
   * @summary callback function when user press "escape" key or click outside of this component
   *
   * @description when user interacts such actions, it means he wants the component to be dismiss
   *
   * if this component is displayed for chossing a type and rating for a new bounding box, this bounding
   * should removed from the bounding boxes list
   *
   * if this component is displayed for an existed bounding box which has typeId and type defined, it means that
   * bounding box was rated before, user wants give up this rating other than the previous one (otherwise, he could
   * choose to delete the bounding box). So this bounding box, with its typeId and type value unchanged, should not
   * be removed.
   */
  const onGiveUpRating = () => {
    const shouldRemove =
      boundingBox.typeId === undefined && boundingBox.type === "";
    props.onUpdateBoundingBoxes(boundingBox, !shouldRemove);
  };

  return (
    <div style={{ zIndex: 151 }}>
      <Button
        ref={menuRef}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        style={{ zIndex: -1, position: "absolute", top: 100, left: 100 }}
      >
        . {/*cannot be empty here, just a placeholder */}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        style={{ zIndex: 160, maxHeight: "50vh" }}
        onClick={(event) => {
          console.log("event.target", event.target);
          console.log("event.currentTarget", event.currentTarget);
        }}
      >
        <MenuItem onClick={focusSearchTextField}>
          <TextField
            id={SERACH_TEXT_FIELD_ID}
            autoComplete="off"
            autoFocus
            onKeyDown={handleSearchKeyDown} // prevent key down event on menu item
            onKeyPress={(event) => {
              event.stopPropagation();
              if (
                event.key.toLowerCase() === "enter" &&
                menus.filteredMenu.length === 1 &&
                menus.filteredMenu[0].subMenu.length === 0
              ) {
                if (selectedRating === null) {
                  document.getElementById(SERACH_TEXT_FIELD_ID).blur();
                  setSearchText(menus.filteredMenu[0].name);
                  showRatingComponent(menus.filteredMenu[0]);
                } else {
                  handleMenuClose(
                    null,
                    menus.filteredMenu[0].name,
                    menus.filteredMenu[0].id,
                    menus.filteredMenu[0].parents[0]
                  );
                }
              }

              // hard code stuff remove it start
              else if (
                event.key.toLowerCase() === "enter" &&
                event.target.value !== "" &&
                !isNaN(event.target.value)
              ) {
                props.onUpdatePCI(event.target.value);
              }
              // hard code stuff remove it start
            }}
            placeholder="Search by keyword"
            value={searchText}
            onChange={handleSearch}
          />
          <IconButton
            size="small"
            onClick={showRootMenu}
            disabled={searchText === ""}
          >
            <CancelIcon style={{ color: searchText === "" ? "grey" : "red" }} />
          </IconButton>
        </MenuItem>

        {selectedRating === null &&
          menus.filteredMenu.map((menu, index) => (
            <MenuItem
              key={index}
              onClick={(event) => {
                handleMenuItemClick(menu);
              }}
              component="div"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Infobox_info_icon.svg/1024px-Infobox_info_icon.svg.png"
                width={30}
                height={30}
                style={{ marginRight: "0.675rem" }}
                alt={`${menu.subMenu.name}-icon`}
              />
              {`${menu.id} - ${menu.parents[0]}- ${menu.name}`}
            </MenuItem>
          ))}

        {selectedRating && (
          <div>
            <MenuItem>
              <RatingComponent
                defaultValue={
                  selectedRating.ratingTitle === "ft"
                    ? boundingBox.length
                    : selectedRating.ratingTitle === "sqft"
                    ? boundingBox.surface_area || 0
                    : selectedRating.rating
                }
                values={selectedRating.range}
                label="Rating"
                units={selectedRating.ratingTitle}
                type={
                  selectedRating.ratingTitle === "sqft" ||
                  selectedRating.ratingTitle === "ft"
                    ? "text"
                    : "select"
                }
                onSet={(value) => {
                  setSelectedRating({
                    ...selectedRating,
                    rating: value,
                  });
                }}
                onChange={(id) => {
                  const foundRating = selectedRating.range.find(
                    (rating) => rating.id === id
                  );
                  if (foundRating) {
                    setSelectedRating({
                      ...selectedRating,
                      rating: foundRating,
                    });
                  }
                }}
                keyEvent={selectionKeyEvent}
              >
                {console.log(
                  `defaultValue`,
                  selectedRating,
                  selectedRating.ratingTitle === "sqft",
                  boundingBox,
                  selectedRating.ratingTitle === "ft"
                    ? boundingBox.length
                    : selectedRating.ratingTitle === "sqft"
                    ? boundingBox.surface_area
                    : selectedRating.rating
                )}
              </RatingComponent>
            </MenuItem>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <IrisButton color="secondary" size="small" onClick={showRootMenu}>
                Cancel
              </IrisButton>

              <IrisButton
                size="small"
                onClick={() => {
                  if (menus.selectedMenu) {
                    handleMenuClose(
                      null,
                      menus.selectedMenu.name,
                      menus.selectedMenu.id,
                      menus.selectedMenu.parents[0]
                    );
                  }
                }}
                disabled={selectedRating.rating.id <= 0}
              >
                Okay
              </IrisButton>
            </div>
          </div>
        )}
      </Menu>
    </div>
  );
}

// could be an object with action creator
export default connect(null, {
  setToastMessage,
})(BoundingBoxMenu);

/**
 * Change Log:
 *
 * Change Date: Nov 11, 2021
 *
 * Description:
 * Component is created and inital documentation is added.
 */

/**
 * Change Log:
 *
 * Change Date: Jun 10, 2021
 *
 * Description:
 *
 * this update is due to the requirement
 * 1. when user type in numbers, the label with corresponding id would be shown
 * 2. when user type in chars other than numbers, the label with corresponding name (title) would be shown.
 *
 * step 1 make each UI element of the label renter inner text in the form like
 * label_id - top_most_parent_name - label_name.
 *
 *
 * details :
 * The menu is used to be a tree (multi level) structure, after this update, the
 * menu is an array that contains all root labels (labels that has not sub menu).
 *
 * In addtion, each label contains an extra field, called parents, which is an array (parent link)
 * which is range from the top level parent (grand grand grand ....., grand parent) to the closest parent
 * (parent).
 *
 * For instance, if a label's parents field looks like this ["MMS", "Signage Issue", "Damaged Sign"], which means
 * its parent is "Damaged Sign", the parent of "Damaged Sign" is "Sinage Issue", and the parent of "Sinage Issue" is
 * "MMS".
 *
 *
 * with this layout, the has a better idea of the means of each Ui element.
 *
 * step 2: update the key event of the search text field so that the desired label would be filtered and
 * shown to user properly.
 *
 * @see recursiveMenu from utilFunction.js
 */
