import React, { useEffect } from "react";

import logo from "../../assets/logos/irisCityLogo.png";
import { USER_NAME } from "../login/localStorageKeys";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { loginReset } from "../../redux/reducers/loginReducer/loginActions";
import { toggleShortcutListDisplayStyle } from "../irisShortCutKeyList/irisShortcutKeyList";
import { userManualUrl } from "../../awsBackend/backendURLs";
import { IrisButton, IrisHeader } from "irisrad-ui";
import SearchBar from "../searchBar/SearchBar";
import Auth from "@aws-amplify/auth";

const handleKeyUp = (event) => {
  if (event.target.tagName.toUpperCase() === "INPUT") return;
  if (event.shiftKey && event.keyCode === 83) {
    const btn = document.querySelector(`[data-type="data-type"]`);
    if (btn) {
      btn.click();
    }
    // alert("get it");
    // toggle();
  }
};

export const clearLocalStorage = () => {
  const userName = localStorage.getItem(USER_NAME);
  localStorage.clear();
  localStorage.setItem(USER_NAME, userName);
};
function IrisHeaderTwo(props) {
  const { onLoginReset, currentUser, location, history } = props;
  const { dataPoints } = props;
  const hiddenLinks = [
    { label: "Shortcuts", onClick: toggleShortcutListDisplayStyle },
    {
      label: "Logout",
      onClick: async () => {
        try {
          // TODO use this signout once every labeller has dedicated aws credentials
          // https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-out
          // By doing this, you sign out users from all devices.
          // It also invalidates all refresh tokens issued to a user.
          // The user's current access and Id tokens remain valid until their expiry.
          // Access and Id tokens expire one hour after they are issued.
          // await Auth.signOut({ global: true });
          await Auth.signOut();
          console.log(`logged out from aws cognito`);
        } catch (error) {
          console.log("error signing out: ", error);
        } finally {
          clearLocalStorage();
          onLoginReset();
          history.push("/login");
        }
      },
    },
    { label: "User Manual", onClick: () => window.open(userManualUrl) },
  ];

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  });

  useEffect(() => {
    const toggleBtn = document.querySelector(`[data-type="data-type"]`);
    if (
      toggleBtn.classList.contains("active") &&
      dataPoints &&
      dataPoints.length > 0
    ) {
      toggleBtn.click();
    }
  }, [dataPoints]);
  return (
    <IrisHeader
      userName={localStorage.getItem(USER_NAME)}
      hiddenLinks={hiddenLinks}
      logoComponent={
        <a href="https://www.irisradgroup.com/" target="_blank">
          <img src={logo} alt="iris-logo" className="logo-img" width={140} />
        </a>
      }
      actionButtons={[
        // <IrisButton
        //   size="small"
        //   iconProps={{
        //     iconTitle: "assessment",
        //     size: "small",
        //   }}
        //   color="secondary"
        //   disabled={props.selectedData === null}
        //   onClick={() => props.toggleAccelerometerGraphic(true)}
        // >
        //   Acc Graphs
        // </IrisButton>,
        <IrisButton
          size="small"
          iconProps={{
            iconTitle: "assessment",
            size: "small",
          }}
          color="secondary"
          //  disabled={props.selectedData === null}
          onClick={() => props.toggleQueryDataModal(true)}
        >
          Query Dataset
        </IrisButton>,
      ]}
      headerTitle="Iris Labeling Portal"
      fadeInContent={{
        toggleBtnProps: {
          btnTitle: "Search",
          size: "small",
          iconProps: {
            iconTitle: "search",
            size: "small",
          },
        },
        element: <SearchBar {...props} />,
      }}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.loginState.userData,
    dataPoints: state.dataPointsState.dataPoints,
  };
};

const mapDispatchToProps = {
  onLoginReset: loginReset,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IrisHeaderTwo));
