/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * @author Lucien Chu
 * Create Date: Jun 30, 2020
 *
 * @description This is the component that is shown after a user logged in.
 * It contains 4 components:
 *
 * 1. a search bar on the upper part;
 * 2. a data point list on the lower left part;
 * 3. a canvas component in the middle of the lower part, which contains and iamge and user can draw bounding boxes on the image;
 * 4. a component that contains a bounding list and checkboxes for flagging a data point on the lower right part;
 */

import React, { createRef } from "react";
import Grid from "@material-ui/core/Grid";
//Material UI components
import { withStyles } from "@material-ui/core/styles";
import Spinner from "../../UI/spinner/Spinner";

import { clearLocalStorage } from "../../components/irisHeader/irisHeader";

import { useStyles } from "./homeStyle";
import SearchBar from "../../components/searchBar/SearchBar";
import {
  getAllData,
  ERROR_MESSAGES,
  updateData,
  createDataPointBoundingBoxes,
  deleteBoundingBox,
  updateBoundingBox,
  calculatePCI,
} from "../../awsBackend/awsBackendUtils";
import DataPointList from "../../components/dataPointList/dataPointList";

import IrisHeader from "../../components/irisHeader/irisHeader";
import IrisShortutKeyList from "../../components/irisShortCutKeyList/irisShortcutKeyList";
import { connect } from "react-redux";
import {
  resetNoDataPointsFlag,
  startGettingDataPoints,
  updateDataPoints,
  updateSelectedData,
  setSortDataPointsBy,
  getSortedData,
  setMenu,
} from "../../redux/reducers/dataPointsReducer/dataPointActions";
import BoundingBoxComponent from "../../boundingboxComponent/BoundingBoxComponent";
import IrisToast from "../../components/irisToast/IrisToast";
import {
  setGlobalMessage,
  setLabelPrefence,
  setToastMessage,
} from "../../redux/reducers/globalReducer/globalActions";
import BoundingBoxCanvas from "../../components/boundingBoxCanvas/BoundingBoxCanvas";

import smartCityImage from "../../assets/images/backgroundImages/iris_smart_city_company.png";
import { Route } from "react-router-dom";
import IrisHeaderTwo from "../../components/irisHeader/IrisHeaderTwo";
import SummaryDetails from "../../components/summaryDetails/SummaryDetails";
import AccelerometerGraphic from "../../components/accelerometerGraph/AccelerometerGraphic";
import QueryDataModal from "../../components/queryDataModal/QueryDataModal";
import MiniMap from "../../components/miniMap/MiniMap";
import MapIcon from "@material-ui/icons/Map";
import { IconButton } from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import { getAWSToken } from "../../utils/cognitoUtils";
import {
  createDefect,
  deleteDefect,
  getDefectsWithFilter,
  listDefectTypes,
  updateDefect,
} from "../../awsBackend/irisDataUtils";
const TAG = "Home.js";

const getUrlWithPageNumber = (url, pageNumber) => {
  let urlString = url;
  if (pageNumber) {
    const urlComponents = url.split("&");
    const pageIndex = urlComponents.findIndex((component) =>
      component.includes("page=")
    );
    urlComponents[pageIndex] = `page=${pageNumber}`;
    urlString = urlComponents.join("&");
  }
  return urlString;
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.uploadingMap = createRef({});
    this.uploadingMap.current = {};
    this.newDefectTypes = [];
    this.createWorkOrderState = false;
  }

  state = {
    dialogObject: null,
    showSummaryDetails: null,
    showAccelerometerGraph: false,
    showQueryDataPointModal: false,
    openMapModal: false,
    geojson: null,
  };

  async componentDidMount() {
    const token = await getAWSToken();
    this.newDefectTypes = await listDefectTypes(token);
  }

  componentDidUpdate(prevProp, prevState, snapshot) {
    if (this.props.noDataPointsFlag) {
      this.props.onRestNoDataPointsFlag();
      this.props.onSetGlobalMessage({
        title: "Message",
        message: "No data points",
      });
    }
    const { labelPreference: prevLabelPreference } = prevProp;
    const { labelPreference: currentLabelPreference } = this.props;
    const s0 = prevLabelPreference.sort();
    const s1 = currentLabelPreference.sort();
    const isPreferenceChanged = JSON.stringify(s0) !== JSON.stringify(s1);
    // const dataPoints = this.props.dataPoints;
    // const selectedDataPoint = this.props.selectedData;
    // const allMenu = this.props.allMen;
    // const menu = this.props.menu;
    // console.log(`this.props.dataPoints`, dataPoints, selectedDataPoint, allMenu);
    if (isPreferenceChanged) {
      // props

      const { dataPoints, selectedData, allMenu, labelPreference } = this.props;
      // funcs
      const { onUpdateSelectedDataPoint, onUpdateDataPoints, onSetMenu } =
        this.props;
      const updatedDataPoints = JSON.parse(JSON.stringify(dataPoints));
      updatedDataPoints.map((data) => {
        data.removedBoundingBoxes = [];
        data.boundingBoxes = data.boundingBoxesOriginal.filter(
          (box) => labelPreference.indexOf(box.rootParent) >= 0
        );
      });
      const newSelectedDataPoint = updatedDataPoints.find(
        (data) => data.id === selectedData.id
      );
      if (newSelectedDataPoint) {
        onUpdateSelectedDataPoint(newSelectedDataPoint);
      }
      onUpdateDataPoints(updatedDataPoints);
      const newMenu = allMenu.filter(
        (rootMenu) => labelPreference.indexOf(rootMenu.name) >= 0
      );
      onSetMenu(newMenu);
    }
  }

  loadMoreData = (url, pageNumber) => {
    const urlString = getUrlWithPageNumber(url, pageNumber);

    const { onGetData } = this.props;
    onGetData({
      url: urlString,
    });
  };

  handleSubmit = async (data, auditorId = this.props.auditorId) => {
    const targetDataPoint = JSON.parse(JSON.stringify(this.props.selectedData));
    const token = await getAWSToken();

    // update the new selected data point on the redux store
    // then do the rest async procedures

    // if data is the same as the current selected data point (ie: user click the same data point);
    // instead of using the one fetch from the data points, using the current data point since it has
    // the most updated inforamtion (ie: bounding boxes)
    // otherwise, set the one fetch from the data points array should be fine as it would got updated
    // after the current selected data point's information is send to the server
    this.props.onUpdateSelectedDataPoint(
      data.id === targetDataPoint.id ? this.props.selectedData : data
    );

    const { current: uploadMap } = this.uploadingMap;

    // fixed repeatedly update data point's bounding boxes.
    if (uploadMap[targetDataPoint.id]) {
      return;
    } else {
      uploadMap[targetDataPoint.id] = data;
    }

    // this.uploadBoundingBoxes(copied, data, auditorId);
    const {
      id: dataPointId,
      boundingBoxes,
      boundingBoxesOriginal,
      removedBoundingBoxes,
      pci,
    } = targetDataPoint;

    /**a set of bounding boxes, whehter they are newly created or modified */
    let allBoundingBoxes = [...boundingBoxes];

    /**user created bounding boxes */
    const newBoundingBoxes = [];

    /**user modified bounding boxes */
    const updatedBoundingBoxes = [];

    /**user deleted bounding boxes */
    let deletedBoundingBoxes = [];

    for (const box of allBoundingBoxes) {
      const id = box.id;
      const idType = typeof id;
      // new created bounding box would have id = "data_point_id - number_of_new_bounding_box"
      if (idType === "string") {
        newBoundingBoxes.push({ ...box });
      } else if (idType === "number" && id > 0) {
        // compare current box with the one on the orignal array
        // if there is any difference, means it is been updated by
        // the user, either resize or reselect label
        const foundBoundingBox = boundingBoxesOriginal.find(
          (bbox) => bbox.id === box.id
        );
        if (
          foundBoundingBox &&
          JSON.stringify(foundBoundingBox) !== JSON.stringify(box)
        ) {
          updatedBoundingBoxes.push({ ...box });
        }
      }
    }

    deletedBoundingBoxes = removedBoundingBoxes.filter(
      (box) => typeof box.id === "number"
    );

    /**toast message */
    let message = "";

    /*================================================== create new bounding boxes START ================================================== */
    if (newBoundingBoxes.length > 0) {
      // console.log("create box preparing started");

      /**a set of promises that create bounding box api would return */

      const newBoxRequests = [];
      const newDefectRequests = [];

      newBoundingBoxes.forEach((box) => {
        const defect = this.newDefectTypes.filter(
          (defect) => defect.name === box.type
        );
        if (defect && defect.length > 0) {
          const { topLeft, topRight, bottomRight, bottomLeft } = box;
          const imageURL = targetDataPoint.image.split("?")[0];
          const newDefect = {
            customer_id: targetDataPoint.device_sn.city.id,
            create_time: targetDataPoint.create_time,
            defect_type: defect[0].id,
            datapoint_id: targetDataPoint.id,
            device_id: targetDataPoint.device_sn.id,
            image_raw: imageURL,
            heading: targetDataPoint.heading,
            lat: targetDataPoint.latitude,
            lng: targetDataPoint.longitude,
            bbox: {
              bounding_box: [
                Math.floor(topLeft[0]),
                Math.floor(topLeft[1]),
                Math.floor(topRight[0]),
                Math.floor(topRight[1]),
                Math.floor(bottomRight[0]),
                Math.floor(bottomRight[1]),
                Math.floor(bottomLeft[0]),
                Math.floor(bottomLeft[1]),
              ],
            },
            metadata: {
              rating: box.rating,
            },
          };
          if (this.createWorkOrderState) newDefect["create_work_order"] = true;
          newDefectRequests.push(createDefect(newDefect, token));
        }

        newBoxRequests.push(createDataPointBoundingBoxes(box, dataPointId));
      });

      await Promise.all(newDefectRequests).catch((err) => {
        console.error(err);
      });

      // console.log("create box preparing ended");

      // console.log("create box started");
      await Promise.allSettled(newBoxRequests).then((results) => {
        // console.log(`"create box results`, results);
        // a new id would be return from the promise, match the new id with
        // the corresponding bounding boxes
        results.forEach((result) => {
          if (result.status === "fulfilled") {
            /** id generated on the server */
            const returnedId = result.value.label_id;

            /**temp id that is gerenated locally when a new bounding box is created */
            const oldId = result.value.oldId;

            /** index of the new bounding box */
            const foundIndex = boundingBoxes.findIndex(
              (bbox) => bbox.id === oldId
            );
            if (foundIndex > -1) {
              /** replace old id with new id */
              allBoundingBoxes[foundIndex].id = returnedId;
            }
            message += `Created bounding box, id: ${returnedId}\n`;
          } else if (result.status === "rejected") {
            console.log(
              `Error in creating new bounding box`,
              result.reason.message
            );
          }
        });
      });
      // console.log(`create box ended`);
    }
    /*==================================================  create new bounding boxes END  ================================================== */

    const existingDefects = [];
    if (
      updatedBoundingBoxes.length > 0 ||
      (Array.isArray(deletedBoundingBoxes) && deletedBoundingBoxes.length > 0)
    ) {
      const filteredDefects = await getDefectsWithFilter(
        { datapoint_id: targetDataPoint.id },
        token
      );
      if (filteredDefects.results.length > 0)
        existingDefects.push(...filteredDefects.results);
    }

    /*================================================== update existing bounding boxes START ================================================== */
    if (updatedBoundingBoxes.length > 0) {
      /** a set of promises, each of them is returned from an updateBoundingBox method */
      const updateRequests = [];
      const updateDefectRequests = [];

      updatedBoundingBoxes.forEach((box) => {
        const foundDefects = existingDefects.filter(
          (defect) => defect.defect_type === box.type
        );
        if (foundDefects && foundDefects.length > 0) {
          const orignalBoundingBox = boundingBoxesOriginal.find(
            (bbox) => bbox.id === box.id
          );

          const { topLeft, topRight, bottomRight, bottomLeft } =
            orignalBoundingBox;
          const original = [
            Math.floor(topLeft[0]),
            Math.floor(topLeft[1]),
            Math.floor(topRight[0]),
            Math.floor(topRight[1]),
            Math.floor(bottomRight[0]),
            Math.floor(bottomRight[1]),
            Math.floor(bottomLeft[0]),
            Math.floor(bottomLeft[1]),
          ];

          //  Work around for not being able to find defect directly
          foundDefects.forEach((defect) => {
            if (original.toString() == defect.bbox.bounding_box.toString()) {
              const bbox = defect.bbox.bounding_box;
              const { topLeft, topRight, bottomRight, bottomLeft } = box;
              updateDefectRequests.push(
                updateDefect(
                  defect.id,
                  {
                    bbox: {
                      bounding_box: [
                        Math.floor(topLeft[0]),
                        Math.floor(topLeft[1]),
                        Math.floor(topRight[0]),
                        Math.floor(topRight[1]),
                        Math.floor(bottomRight[0]),
                        Math.floor(bottomRight[1]),
                        Math.floor(bottomLeft[0]),
                        Math.floor(bottomLeft[1]),
                      ],
                    },
                  },
                  token
                )
              );
            }
          });
        }
        updateRequests.push(updateBoundingBox(box));
      });

      await Promise.all(updateDefectRequests).catch((err) => {
        console.error(err);
      });

      await Promise.allSettled(updateRequests).then((results) => {
        results.forEach((result) => {
          if (result.status === "fulfilled") {
            const { success, id: boxId } = result.value;
            if (success === true) {
              message += `Updated bounding, id: ${boxId}\n`;
            }
          } else if (result.status === "rejected") {
            const { message, id } = result.reason;
            this.props.onSetGlobalMessage({
              title: "Error",
              message: `Error in updated bounding box: ${id}\n${message}`,
            });
          }
        });
      });
      // console.log(`update box ended`);
    }
    /*==================================================  update existing bounding boxes END  ================================================== */

    /*================================================== delete bounding boxes START ================================================== */
    if (
      Array.isArray(deletedBoundingBoxes) &&
      deletedBoundingBoxes.length > 0
    ) {
      // console.log(`delete box preparing started`);
      /** a set of promises, each of them is returned from the deleteBoundingBox method call */
      const requests = [];
      const deleteDefects = [];
      deletedBoundingBoxes.forEach((box) => {
        const foundDefects = existingDefects.filter(
          (defect) => defect.defect_type === box.type
        );
        if (foundDefects && foundDefects.length > 0) {
          const orignalBoundingBox = boundingBoxesOriginal.find(
            (bbox) => bbox.id === box.id
          );

          const { topLeft, topRight, bottomRight, bottomLeft } =
            orignalBoundingBox;
          const original = [
            Math.floor(topLeft[0]),
            Math.floor(topLeft[1]),
            Math.floor(topRight[0]),
            Math.floor(topRight[1]),
            Math.floor(bottomRight[0]),
            Math.floor(bottomRight[1]),
            Math.floor(bottomLeft[0]),
            Math.floor(bottomLeft[1]),
          ];

          //  Work around for not being able to find defect directly
          foundDefects.forEach((defect) => {
            if (original.toString() == defect.bbox.bounding_box.toString()) {
              deleteDefects.push(deleteDefect(defect.id, token));
            }
          });
        }

        requests.push(deleteBoundingBox(Math.abs(box.id)));
      });
      // console.log(`delete box preparing ended`);
      // console.log(`delete box started`);

      await Promise.all(deleteDefects).catch((err) => {
        console.error(err);
      });

      await Promise.allSettled(requests).then((results) => {
        results.forEach((result) => {
          const { status } = result;
          if (status === "fulfilled") {
            const { success, id } = result.value;
            if (success) {
              allBoundingBoxes = allBoundingBoxes.filter(
                (bbox) => Math.abs(bbox.id) !== id
              );
              message += `Deleted bounding box, id: ${id}\n`;
            }
          } else if (status === "rejected") {
            const {
              reason: { message },
            } = result;
            // console.log("Error in deleting bounding box: " + message);
            this.props.onSetGlobalMessage({
              title: "Error",
              message: `Error in removing bounding box: ${message}`,
            });
          }
        });
      });

      // console.log(`delete box ended`);
    }
    /*==================================================  delete bounding boxes END  ================================================== */

    // const foundDataPoint = this.props.dataPoints.find((data) => data.id === id);

    targetDataPoint.boundingBoxes = allBoundingBoxes;
    targetDataPoint.boundingBoxesOriginal = [...allBoundingBoxes];
    targetDataPoint.removedBoundingBoxes = [];

    let pciValue = 100;
    let result = await calculatePCI(
      targetDataPoint,
      this.props.rootMenu,
      this.props.numOfLanes,
      this.props.widthPerLane
    );
    if (result?.result) {
      pciValue = result.result;
    }

    if (pciValue !== pci) {
      // calculated pci value is different from the current pci
      // show pci message to user
      targetDataPoint.pci = pciValue;
      message += "pci: " + pciValue;
    }
    const { damage_types, flag } = targetDataPoint;
    updateData(dataPointId, {
      change_time: new Date().toJSON(),
      pci: pciValue,
      edited: "Y", // from edited: "N" to edited "Y"
      flag: flag,
      auditor: auditorId,
      damage_type: damage_types,
    });
    // show all toast message after all APIs are called
    if (message !== "") {
      this.props.onSetToastMessage(message);
    }

    // if next selected data is the current selected data
    if (data.id === dataPointId) {
      // get the most updated data which has the new bounding boxes ids retruend from the server
      this.props.onUpdateSelectedDataPoint(targetDataPoint);
    }

    // update the entire set of data points on the redux store, due to a data point has been updated
    this.props.onUpdateDataPoints(
      this.props.dataPoints.map((d) => {
        if (d.id === targetDataPoint.id) {
          delete this.uploadingMap.current[targetDataPoint.id];
          return {
            ...targetDataPoint,
            visited: true,
            selectedBoundingBox: null,
          };
        }
        return d;
      })
    );
  };

  handleUpdateDefectsLocation = async (datapointId, latitude, longitude) => {
    const token = await getAWSToken();
    const existDefects = await getDefectsWithFilter(
      { datapoint_id: datapointId },
      token
    ).then((response) => {
      console.log("handleUpdateDefectsLocation", response);

      if (Array.isArray(response?.results)) {
        console.log("handleUpdateDefectsLocation", response);
        response.results.forEach((defect) => {
          updateDefect(defect.id, { lat: latitude, lng: longitude }, token);
        });
      }
    });
  };

  toggleSummaryDetails = (deviceDetails) => {
    this.setState({ showSummaryDetails: deviceDetails });
  };

  toggleAccelerometerGraphic = (isShow) => {
    this.setState({ showAccelerometerGraph: isShow });
  };

  updateDataPointSortOption = (option) => {
    const { sortDataBy: currentSort, dataPoints } = this.props; // properties
    if (option === currentSort) {
      return;
    }

    //
    const {
      onSetSortDataPointsBy,
      onUpdateSelectedDataPoint,
      onUpdateDataPoints,
    } = this.props;
    onSetSortDataPointsBy(option);
    if (dataPoints.length === 0) {
      return;
    }
    const sortedDataPoints = getSortedData(dataPoints, option);
    const selectedData = sortedDataPoints[0]
      ? JSON.parse(JSON.stringify(sortedDataPoints[0]))
      : null;
    onUpdateSelectedDataPoint(selectedData);
    onUpdateDataPoints(sortedDataPoints);
  };

  getDataPointsWithLabels = (params) => {
    this.props.onGetData(params);
    this.setState({ showSummaryDetails: null });
  };

  toggleQueryDataModal = (isOpen) => {
    this.setState({ showQueryDataPointModal: isOpen });
  };

  queryDataSet = (ids) => {
    this.props.onGetData({ ids: ids });
  };

  render() {
    return (
      <div>
        <IrisToast message={`${this.props.toastMessage}`} />
        <SummaryDetails
          getDataPointsWithLabels={this.getDataPointsWithLabels}
          deviceDetails={this.state.showSummaryDetails}
          onClose={() => this.toggleSummaryDetails(false)}
          {...this.props}
        />
        <AccelerometerGraphic
          open={this.state.showAccelerometerGraph}
          dataPoint={this.props.selectedData}
          onClose={() => this.toggleAccelerometerGraphic(false)}
        />

        <QueryDataModal
          open={this.state.showQueryDataPointModal}
          onClose={() => this.toggleQueryDataModal(false)}
          onConfirm={this.queryDataSet}
        />
        <Route path={"/shortcuts"} component={IrisShortutKeyList} />
        {/* <LabellerSummary /> */}
        {/* <LabellerSummarySymplify /> */}
        {this.props.isLoading && <Spinner />}
        {/* <IrisHeader
           title="Data Labelling Portal"
           links={[
             { title: "Labelling", pathname: "/" },
             // { title: "Analysis", pathname: "/analysis" },
           ]}
         >
           <SearchBar {...this.props} />
         </IrisHeader> */}
        <IrisHeaderTwo
          {...this.props}
          toggleSummaryDetails={this.toggleSummaryDetails}
          toggleAccelerometerGraphic={this.toggleAccelerometerGraphic}
          toggleQueryDataModal={this.toggleQueryDataModal}
        />
        <div style={{ height: "calc(100vh - 70px)", position: "relative" }}>
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={2} style={{ height: "100%", overflow: "hidden" }}>
              <DataPointList
                onKeyPress={this.handleKeyPress}
                dataPointArray={this.props.dataPoints}
                loadData={this.loadMoreData}
                onSelectedData={this.handleSubmit}
                numberOfDataPoints={this.props.totalDataPoints}
                updateDataPointSortOption={this.updateDataPointSortOption}
              />
            </Grid>
            <Grid
              container
              item
              xs={8}
              alignItems="center"
              justify="center"
              style={{ backgroundColor: "black" }}
            >
              <Grid item style={{ width: "100%" }}>
                {this.props.selectedData ? (
                  <div>
                    <IconButton
                      title="map"
                      color="primary"
                      onClick={() => this.setState({ openMapModal: true })}
                    >
                      <MapIcon
                        fontSize="large"
                        style={{
                          color: blue[400],
                        }}
                      />
                    </IconButton>
                    <BoundingBoxCanvas
                      data={this.props.selectedData}
                      handleSubmit={this.handleSubmit}
                    />

                    <MiniMap
                      open={this.state.openMapModal}
                      onClose={() => this.setState({ openMapModal: false })}
                      onUpdateCurrentDataPoint={
                        this.props.onUpdateSelectedDataPoint
                      }
                      handleUpdateDefectsLocation={
                        this.handleUpdateDefectsLocation
                      }
                      currentDataPoint={this.props.selectedData}
                      geojson={this.state.geojson}
                    />
                  </div>
                ) : (
                  <img
                    src={smartCityImage}
                    style={{ width: "100%", height: "auto" }}
                    alt="demo"
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <BoundingBoxComponent
                labelPreference={this.props.labelPreference}
                labelGroup={this.props.allMenu}
                onSelectedLabelPreferece={this.props.onSelectedLabelPreferece}
                selectedData={this.props.selectedData}
                updateSelectedData={this.props.onUpdateSelectedDataPoint}
                handleShapeFile={(data) => (this.state.geojson = data)}
                changeCreateWorkOrderState={(value) =>
                  (this.createWorkOrderState = value)
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.dataPointsState,
    labelPreference: state.globalState.labelPreference,
    toastMessage: state.globalState.toastMessage,
    auditorId: state.loginState.userData.id,
  };
};
// could be an object with action creator
const mapDispatchToProps = {
  onGetData: startGettingDataPoints,
  onUpdateSelectedDataPoint: updateSelectedData,
  onUpdateDataPoints: updateDataPoints,
  onSetToastMessage: setToastMessage,
  onRestNoDataPointsFlag: resetNoDataPointsFlag,
  onSetGlobalMessage: setGlobalMessage,
  onSetSortDataPointsBy: setSortDataPointsBy,
  onSelectedLabelPreferece: setLabelPrefence,
  onSetMenu: setMenu,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Home));

/**
 * Change Log:
 *
 * Change Date: Jun 30, 2020
 *
 * @description
 * added documentation
 */

/**
 * Change Log:
 *
 * Change Date: Sep 06, 2020
 *
 * Description: change layout
 * 1. remove google map component.
 *
 * 2. hide search bar behind header, click search incon on
 *    header to show search bar
 *
 * 3. Replaced data point table with a list, plased on the left hand side.
 *
 * 4. Move image component in the middle of the page, with much larger image preview.
 *
 * 5. Change the image zoom feature from on side (zoomed details was shown on the right hand side
 *    of the image component), into in line feature (zoomed details is now shown above the image)
 *
 * 6. Merged MMS, RRI, and PIC comopents into a single tap panels,with 3 tabs. The new component
 *    resides on the right hand side.
 *
 * 7. Now the Home component handle updating data point function. @see handleSubmit
 *
 * 8. Added component to show shortcut key map details @see <IrisShortutKeyList />
 */

/**
 * Change Log:
 *
 * Change Date: Oct 13, 2020
 *
 * Description: replace useContex with react-redux
 */

/**
 * Change Log:
 *
 * Change Date: Nov 28, 2021
 *
 * Description: replace features:
 *
 * replace the image component (in the center of the lower part of the layout), which
 * only show a data point, with a canvas component which allows the user to draw bounding boxes on
 * the image and selected a rating for it.
 *
 * replace the MMS, RRI and PCI component which was on the right hand side of the lower part, from which
 * user could toggle MMS, RRI, and PCI for a data point.
 *
 * As the user now is abled to draw bounding boxe on it directly, this component is now replaced by a
 * list component which would show the bounding boxes' information of the current data points.
 *
 * The "red flag" and "yellow flag" checkbox, which was inside the MMS and RRI componet respectively,
 * are now place inside a single element. IOT, the user is still need to toggle flag(s) for the current
 * data point.
 */

/**
 * Change Log:
 *
 * Change Date: Jan 21, 2021
 *
 * Description:
 * 1. udpate the way to get removed bounding box.
 *    Before, new bounding boxes, removed bounding boxes and updated
 *    bounding boxes are grouped from the array of bounding boxes of
 *    seleted data point's boundingBox proerty.
 *
 *    Now as the removed bounding boxes would be move from the boundingBoxes
 *    array to the removedBoundingBoxes by the time the user decide to remove
 *    them. Such movement avoid negating the id of a bounding box (the one that has
 *    a record on the server) which indicates that it should be removed, and making
 *    a user drawn bounding box (not send to the server yet) possible to be
 *    restored later on.
 *
 *    Before this update, the user could not restore any user defined (but not yet send to server)
 *    bounding boxes after removal. Also, he could not restore bounding boxes one by one, though
 *    restoring all of them (only those previous fetched from the server).
 *
 * 2. factor out the logic for updating (CUD) bounding boxes, @see uploadBoundingBoxes
 */

/**
 * Change Log:
 *
 * Change Date: Aug 07, 2021
 *
 * Description:
 * 1. updated submit data point procedure, after bounding boxes are sent
 * to server, then call the calculatePCI api so that the updated pci value
 * could be fetched and display to the user
 *
 * 2. user press ctrl + s could trigger the submit data point mechanism, other
 * then have to click on of the data point on the list component
 */
