/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Oct 28, 2020
 *
 * Description: This component is designed to demonstrate the details about
 * a data point's bounding boxes, including the id, the selected rating and
 * also a thumbnail.
 *
 * This component contains 3 small components:
 * 1. a list of bounding boxes, each list item contains a list of bounding boxes that shared the same title,
 *    for example, if the current data point has 4 bounding boxes, 2 with title "titleA" and another 2 with
 *    title "titleB", the this list should have two list items whose title should be "titleA" and "titleB"
 *    respectively. By click list item nameed "titleA", it expands and show another two list itmes (the actual
 *    bounding box).
 *
 * 2. a component with two checkboxes that is set up for user to select flag(s) for the current data point, based
 *    on the severity of the found issues (bounding boxes and their ratings).
 *
 * 3. A dedicated button for user to removed all the bounding boxes at once.
 */

import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import { IRIS_RED } from "../UI/uiConstants";
import { connect } from "react-redux";
import { updateSelectedData } from "../redux/reducers/dataPointsReducer/dataPointActions";
import { setToastMessage } from "../redux/reducers/globalReducer/globalActions";
import PropTypes from "prop-types";
import {
  ENTER_KEY_EVENT,
  toggleBoundingBoxes,
} from "../boundingboxComponent/BoundingBoxComponent";
import { CANVAS_COMPONENT_DOM_ID } from "../components/boundingBoxCanvas/BoundingBoxCanvas";
import InputLabel from "@material-ui/core/InputLabel";
import {
  getPCI,
  getRatingTitleByLabel,
} from "../awsBackend/awsBackendUtils.js";
import { getRectangularBoundingBoxCoordinates } from "../utils/boundingBoxUtils";

const IMAGE_ID = "image_id";
const THUNBNAIL_CANVAS = "thumbnail_canvas";
const CANVAS_WIDTH = 80;
const CANVAS_HEIGHT = 80;

const RED_FLAG_KEY = "RED_FLAG";
const YELLOW_FLAG_KEY = "YELLOW_FLAG";
const FLAGS = {
  [RED_FLAG_KEY]: {
    name: "Red Flag",
    color: IRIS_RED,
  },
  [YELLOW_FLAG_KEY]: { name: "Yellow Flag", color: "#ffa300" },
};
// const SHOW_CALC_BUTTON = false;

/**
 * @summary given the width and the height of a bounding box, draw its thumbnails in the
 * center of a canvas with the same ratio
 *
 * @see CANVAS_WIDTH
 * @see CANVAS_HEIGHT
 *
 * @param {Number} width bounding boxes' width
 * @param {Number} height bounding boxes' height
 *
 * @returns and object conatins the start position (x, y), the width, and the
 * height of the thumbnials to be drawn on a canvas
 */
const getThumbnailDetails = (width, height) => {
  const widthToHeightRatio = width / height;
  const heightToWidthRatio = height / width;
  // the width of the thumbnail to be drawn
  const drawnWidth =
    CANVAS_WIDTH *
    (widthToHeightRatio < heightToWidthRatio ? widthToHeightRatio : 1);

  // the height of the thumbnail to be drawn
  const drawnHeight =
    CANVAS_HEIGHT *
    (heightToWidthRatio < widthToHeightRatio ? heightToWidthRatio : 1);

  // start position to draw the thumbnail so that
  // it would be drawn in the center of the canvas
  const drawnStartPosX = (CANVAS_WIDTH - drawnWidth) / 2;
  const drawnStartPoxY = (CANVAS_HEIGHT - drawnHeight) / 2;

  return {
    x: drawnStartPosX,
    y: drawnStartPoxY,
    width: drawnWidth,
    height: drawnHeight,
  };
};

/**
 * @summary a list component that demonstrate some bounding boxes with the same title.
 *
 * @description there would be multiple bounding boxes within a data data points, those bounding boxes would
 * be grouped by titles. As a result, each title corresponds to one BoundingBoxList component on the BoundingBoxTypeList
 * component.
 *
 * The list's title is the same as the given bounding boxes' group title;
 *
 * The list is expandable and each of its items (bounding boxes) would contains a thumbnail of the
 * content within the bounding box, and other inforation such as the bounding box id, its coordinates,
 * and its severity rating.
 *
 * When a bounding box is selected from the BoundingBoxCanvas component, corresponding BoundingBoxList would be expanded
 * and the corresponding list item would be highlighted.
 *
 * Likewise, if the list item is selected, corresponding bounding boxes would be higlithed on the BoundingBoxCanvas component.
 *
 * @since Feb 17, 2021
 *
 * Add double click callback function the list item. By doing so, the canvas component would open the pop up menu and show
 * the rating component for the labeler to edit the rating for the selected bounding box.
 *
 * @param {Object} props
 */

function BoundingBoxList(props) {
  const [open, setOpen] = React.useState(props.isOpen);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const {
    title,
    listData,
    imageSrc,
    onBoudingBoxSelected,
    onBoundingBoxRemoved,
    selectedBoundingBox,
    ...restProps
  } = props;
  const handleClick = () => {
    setOpen(!open);
  };

  /**
   * when image src change, collapse all lists
   */
  useEffect(() => {
    setOpen(false);
  }, [imageSrc]);

  useEffect(() => {
    if (selectedBoundingBox) {
      setSelectedIndex(selectedBoundingBox);
    }
  }, [selectedBoundingBox]);

  /**
   * redrawn all bounding boxes' thumbnails when listData is updated
   */
  useEffect(() => {
    listData.map((data, index) => {
      drawBoundingBoxThumbnail(data.coordinate, title, index);
    });
  }, [listData, title]);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  /**
   * @summary a callback function for onload property of tag <img> and for data point's bounding box update
   *
   * @description
   * 1. when a data point's image is loaded in the html <img> element, its bounding boxes' thunbnails
   *    should be shown next to the boudinig boxe details.
   *
   *    So when the image is loaded, identify both the image element (the source used to drawn its bounding boxes' thumbnails) and
   *    the canvas (which would draw the boudning boxes' thunbnail).
   *
   * 2. when a data point's bounding box is resized
   *    redrawn all bounding boxes' thunbnails
   *
   * The drawn thumbnail has the same ratio as the drawn bounding box and should be lcoated at the center of the canvas
   *
   * @see getDrawnCanvasDetails
   *
   * @param {Array} coordinate array of integer that represents the coordiantes of the bounding box [x0, y0, x1, y1]
   * @param {String} title label type name, used to uniquely identified the image element
   * @param {Number} index index of current coordiate of a data point's coordinates array, used to uniquely identified the canvas element
   */
  const drawBoundingBoxThumbnail = (coordinate, title, index) => {
    const { topLeft, topRight, bottomRight, bottomLeft } = coordinate;

    // const width = bottomRight[0] - x;
    // const height = bottomRight[1] - y;
    const imageElement = document.getElementById(
      `${IMAGE_ID}_${title}_${index}`
    );
    const canvasElement = document.getElementById(
      `${THUNBNAIL_CANVAS}_${title}_${index}`
    );

    const {
      topLeft: rectBoundingBoxTopLeft,
      bottomRight: rectBoundingBoxBottomRight,
      ratioedPoints,
    } = getRectangularBoundingBoxCoordinates([
      topLeft,
      topRight,
      bottomRight,
      bottomLeft,
    ]);

    const x = rectBoundingBoxTopLeft[0];
    const y = rectBoundingBoxTopLeft[1];
    const width = rectBoundingBoxBottomRight[0] - rectBoundingBoxTopLeft[0];
    const height = rectBoundingBoxBottomRight[1] - rectBoundingBoxTopLeft[1];

    if (imageElement && canvasElement) {
      const ctx = canvasElement.getContext("2d");

      const {
        x: startX,
        y: startY,
        width: shrunkWidth,
        height: shtrunkHeight,
      } = getThumbnailDetails(width, height);

      const [topLeftRatio, topRightRatio, bottomRightRatio, bottomLeftRatio] =
        ratioedPoints;
      const p0 = [
        startX + shrunkWidth * topLeftRatio[0],
        startY + shtrunkHeight * topLeftRatio[1],
      ];
      const p1 = [
        startX + shrunkWidth * topRightRatio[0],
        startY + shtrunkHeight * topRightRatio[1],
      ];
      const p2 = [
        startX + shrunkWidth * bottomRightRatio[0],
        startY + shtrunkHeight * bottomRightRatio[1],
      ];
      const p3 = [
        startX + shrunkWidth * bottomLeftRatio[0],
        startY + shtrunkHeight * bottomLeftRatio[1],
      ];
      ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
      ctx.save();
      ctx.beginPath();
      // ctx.moveTo(startX, startY);
      // ctx.lineTo(startX + shrunkWidth, startY + shtrunkHeight);
      // ctx.lineTo(startX, startY + shtrunkHeight);
      ctx.moveTo(p0[0], p0[1]);
      ctx.lineTo(p1[0], p1[1]);
      ctx.lineTo(p2[0], p2[1]);
      ctx.lineTo(p3[0], p3[1]);
      ctx.closePath();
      ctx.clip();
      ctx.drawImage(
        imageElement,
        x,
        y,
        width,
        height,
        startX,
        startY,
        shrunkWidth,
        shtrunkHeight
      );
      ctx.restore();
    }
  };

  /**
   * @summary A callback method passed to the onDoubleClick property of the list item
   *
   * @description When a list item, which represents a bounding box of the current selected data point,
   * is double clicked, the rating component which resides on the canvas compoent should be brought up
   * so that the user need not to navigate to the canvas component and click the highlighted bounding box
   * and hit "enter".
   *
   * This implementation facilitate the workflow for the labeler to start the editing process of a bounding box.
   *
   * Premise: set up key event on the canvas component so that when the component itself is highlighted (tabIdnex property is set),
   * hitting "enter" or "e" key would bring up the rating component for the selected (highlighted) bounding box.
   *
   * So in short, this methoud would firstly find the canvas element from the DOM tree, then highlighted it, and emit a key event with
   * letter "e" so that the rating component on the canvas component could be brought up.
   */
  const handleDoubleClickOnLabel = () => {
    // mimic the key event of "e" key is pressed
    const customDoubleClickEvent = ENTER_KEY_EVENT;

    // find the canvas component from the DOM
    const canvasComponent = document.getElementById(CANVAS_COMPONENT_DOM_ID);
    if (canvasComponent) {
      // highlight the canvas compoent, otherwise, it would not listen to any
      // key event.
      canvasComponent.focus();
      // emit (mimic) the key event, IOW, telling the cavas component that "e" key is press
      canvasComponent.dispatchEvent(customDoubleClickEvent);
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <List aria-labelledby="nested-list-subheader">
        <ListItem button onClick={handleClick}>
          <ListItemText
            primary={
              <h4
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 500,
                }}
              >{`${title} (${listData.length}) `}</h4>
            }
          />
          <ListItemSecondaryAction>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {listData &&
            listData.map((data, index) => (
              <List
                onDoubleClick={handleDoubleClickOnLabel}
                onKeyDown={(event) => {
                  if (
                    event.key.toLowerCase() === "backspace" ||
                    event.key.toLowerCase() === "delete"
                  ) {
                    // if (
                    //   window.confirm(
                    //     `Are you sure you want to delete this box?\nid: ${data.id}\ntype:${title}`
                    //   )
                    // ) {
                    onBoundingBoxRemoved(data.id);
                    // }
                  }
                }}
                component="div"
                disablePadding
                key={index}
                onClick={() => onBoudingBoxSelected(data.id)}
              >
                <ListItem
                  style={{ padding: 0 }}
                  button
                  selected={selectedIndex === data.id}
                >
                  <div>
                    <img
                      id={`${IMAGE_ID}_${title}_${index}`}
                      src={imageSrc}
                      style={{ display: "none" }}
                      onLoad={() =>
                        drawBoundingBoxThumbnail(data.coordinate, title, index)
                      }
                      alt={`${IMAGE_ID}_${title}_${index}-thumbnail`}
                    />
                    <canvas
                      id={`${THUNBNAIL_CANVAS}_${title}_${index}`}
                      width={CANVAS_WIDTH}
                      height={CANVAS_HEIGHT}
                      style={{ margin: "0 0.725rem" }}
                    />
                  </div>
                  <div>
                    <ListItemText
                      primary={
                        <p
                          style={{
                            fontSize: "1.1rem",
                          }}
                        >{`id: ${data.id}`}</p>
                      }
                    />
                    <ListItemText
                      secondary={
                        <p
                          style={{ fontSize: "1.1rem" }}
                        >{`rating: ${data.rating}`}</p>
                      }
                    />
                    <ListItemText
                      secondary={
                        <b style={{ fontSize: "1rem" }}>
                          <div>{`Coordinates:`}</div>
                          <div>{`top left: ${data.coordinate.topLeft}`}</div>
                          <div>{`top right: ${data.coordinate.topRight}`}</div>
                          <div>{`bottom right: ${data.coordinate.bottomRight}`}</div>
                          <div>{`bottom left: ${data.coordinate.bottomLeft}`}</div>
                          <div>{`start end: ${data.coordinate.start}`}</div>
                          <div>{`end: ${data.coordinate.end}`}</div>
                          {/* <div>{`surface_area: ${data.surface_area}`}</div> */}
                          <div>{`surface_area: ${data.rating}`}</div>
                        </b>
                        // {`Coordinates: ${JSON.stringify(data.coordinate)}`}</b>
                      }
                    />
                  </div>
                </ListItem>
              </List>
            ))}
        </Collapse>
      </List>
    </div>
  );
}

BoundingBoxList.propTypes = {
  // list title for bounding boxes that share the same title (under the same category).
  title: PropTypes.string.isRequired,
  // boolean value the determines whenter a sublist should be expanded show that selected bounding box could be highlighted for the user
  isOpen: PropTypes.bool.isRequired,
  // bounding boxes' coordinates information that use to draw thumbnails of them
  listData: PropTypes.array.isRequired,
  // image source that used to draw thumbnails of boundingg boxes
  imageSrc: PropTypes.string.isRequired,
  // callback function when a bounding box is selected from the list,
  // ie: highligh the correspoinding bounding box on the canvas component
  onBoudingBoxSelected: PropTypes.func.isRequired,
  // callback function that hadle the aftermath when a bounding box is removed
  onBoundingBoxRemoved: PropTypes.func.isRequired,
  // current selected boudning box, the one user have selcted from the canvas,
  // the list would be opened and the corresponding
  // bounding box would be highlighted from the list
  selectedBoundingBox: PropTypes.object,
};

/**
 * @summary A component that conatins an array of BoundingBoxList elements
 *
 * @description After the bounding boxes of a data point are grouped by their titles,
 * each title crresponses to oen BoundingBoxList element in this component.
 *
 * @param {Object} props
 */
function BoundingBoxTypeList(props) {
  const { selectedData, updateSelectedData } = props;
  const [selectedFlag, setSelectedFlag] = useState([]);
  const [boundingBoxType, setBoundingBoxType] = useState("");
  const [pciValue, setPciValue] = useState(selectedData.pci);

  useEffect(() => {
    selectedData.pci = pciValue;
  }, [pciValue]);

  const { dataObject, menu } = props;

  const handleFlagChange = (value, isExisted) => {
    let flags = [...selectedFlag];
    if (isExisted) {
      flags = selectedFlag.filter((flagValue) => flagValue !== value);
    } else {
      flags.push(value);
    }
    const dataPoint = { ...selectedData };
    const hasRedFlag = flags.indexOf(FLAGS.RED_FLAG.name) > -1;
    const hasYellowFlag = flags.indexOf(FLAGS.YELLOW_FLAG.name) > -1;
    if (hasRedFlag && hasYellowFlag) {
      dataPoint.flag = "RY";
    } else if (hasRedFlag) {
      dataPoint.flag = "R";
    } else if (hasYellowFlag) {
      dataPoint.flag = "Y";
    } else {
      dataPoint.flag = "N";
    }
    setSelectedFlag(flags);
    updateSelectedData(dataPoint);
  };

  useEffect(() => {
    const flags = [];
    if (selectedData && selectedData.flag) {
      const flagLetter = selectedData.flag;
      if (flagLetter === "R") {
        flags.push(FLAGS.RED_FLAG.name);
      } else if (flagLetter === "Y") {
        flags.push(FLAGS.YELLOW_FLAG.name);
      } else if (flagLetter === "RY") {
        flags.push(FLAGS.RED_FLAG.name);
        flags.push(FLAGS.YELLOW_FLAG.name);
      }
      setSelectedFlag(flags);
    }
    // update pci value when data point is updated
    setPciValue(selectedData.pci);
  }, [selectedData]);

  useEffect(() => {
    const { selectedBoundingBox } = selectedData;
    if (selectedBoundingBox) {
      const foundBox = selectedData.boundingBoxes.find(
        (box) => box.id === selectedBoundingBox
      );
      if (foundBox) {
        setBoundingBoxType(foundBox.type);
      }
    }
  }, [selectedData.selectedBoundingBox]);

  const handleBoundingBoxesSelected = (selectedBoundingBoxId) => {
    const updatedData = { ...selectedData };
    updatedData.selectedBoundingBox = selectedBoundingBoxId;
    updateSelectedData(updatedData);
  };

  const handleBoundingBoxRemoved = (boxId) => {
    const updatedData = JSON.parse(JSON.stringify(selectedData));
    let { boundingBoxes, removedBoundingBoxes } = updatedData;

    const target = boundingBoxes.find((box) => box.id === boxId);
    boundingBoxes = boundingBoxes.filter((box) => box.id !== boxId);
    removedBoundingBoxes.push(JSON.parse(JSON.stringify(target)));

    updateSelectedData({
      ...selectedData,
      selectedBoundingBox: null,
      boundingBoxes: boundingBoxes,
      removedBoundingBoxes: removedBoundingBoxes,
    });
  };

  /**
   * @summary the callback function passed to the onClick property of the "Remove all" button
   *
   * @description remove all bounding boxes from the current selected data points.for those
   * was fetched from the server, remove them by nevigate their id, so that they would be "visually"
   * removed. But they could be restored by making their ids back to positive numbers (original values).
   *
   * However, for those that were drawn by the user, those bounding boxes would be remove from the code, which
   * means they are removed permanently. They are not eligible to be restored later on.
   */
  const removeAllBoundingBoxes = () => {
    const updatedSelectedData = toggleBoundingBoxes(selectedData, true);
    updateSelectedData(updatedSelectedData);
  };

  return (
    <div style={{ height: "100%", maxHeight: "73vh" }}>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flexGrow: 1, overflow: "auto" }}>
          {dataObject &&
            Object.keys(dataObject).map((key, index) => {
              const object = dataObject[key];
              return (
                <div key={index}>
                  <BoundingBoxList
                    isOpen={key === boundingBoxType}
                    selectedBoundingBox={selectedData.selectedBoundingBox}
                    title={object.type}
                    listData={object.coordinates}
                    imageSrc={selectedData.image}
                    onBoudingBoxSelected={handleBoundingBoxesSelected}
                    onBoundingBoxRemoved={handleBoundingBoxRemoved}
                  />
                </div>
              );
            })}
        </div>

        <div>
          <FormControl>
            <FormGroup>
              {Object.keys(FLAGS).map((flagKey, index) => {
                const flagName = FLAGS[flagKey].name;

                const activeColor = FLAGS[flagKey].color;
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    <FormControlLabel
                      style={{
                        marginTop: "0.5rem",
                      }}
                      key={index}
                      control={
                        <Checkbox
                          style={{
                            marginLeft: "0.5rem",
                          }}
                          checked={selectedFlag.indexOf(flagName) > -1}
                          onChange={() =>
                            handleFlagChange(
                              flagName,
                              selectedFlag.indexOf(flagName) > -1
                            )
                          }
                        />
                      }
                      label={flagName}
                    />
                    {selectedFlag.indexOf(flagName) > -1 && (
                      <span
                        style={{
                          alignSelf: "center",
                          justifyContent: "center",
                        }}
                      >
                        <NewReleasesIcon style={{ color: activeColor }} />
                      </span>
                    )}
                  </div>
                );
              })}
            </FormGroup>
          </FormControl>

          <div style={{ marginLeft: 9, marginTop: 5, marginBottom: 5 }}>
            <span style={{ fontSize: "14pt" }}>
              PCI Value&#58;
              <input
                style={{ width: 50, fontsize: "14pt", background: "#e3e3e3" }}
                readOnly
                value={pciValue}
              />
            </span>
          </div>

          <Button
            fullWidth
            variant="contained"
            size="small"
            color="primary"
            onClick={() => removeAllBoundingBoxes(true)}
          >
            Remove all
          </Button>
        </div>
      </div>
    </div>
  );
}

BoundingBoxTypeList.propTypes = {
  // object that contains grouped data points, each key corresponses to
  // a particular type of bounding box; under which is an array of bounding
  // boxes that shared the same title (under the same category (title)).
  dataObject: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    MMSDefectObjects,
    roadRelatedIssues,
    rootMenu: menu,
  } = state.dataPointsState;
  return {
    selectedData: state.dataPointsState.selectedData,
    menu,
  };
};
const mapDispatchToProps = {
  updateSelectedData,
  setToastMessage,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BoundingBoxTypeList);

/**
 * Change Log:
 *
 * Change Date: Oct 28, 2020
 *
 * Description: componen created and added documentation.
 */

/**
 * Change Log:
 *
 * Change Date: Jan 21, 2021
 *
 * Description: updated @see handleBoundingBoxRemoved
 * Before, a bounding box is removed either by negating its id number
 * or remove it from memory if its id is string type, namely, the user
 * draw a bounding box (newly created, has not yet send to sever, so
 * have no integer id but only string value). Such boudning box was not
 * possible to be restore cause they were removed from the code.
 *
 * After this update, all removed bounding boxes are move from the data point's
 * boundingBoxes array to its removedBoundingBoxes array (new added in this update).
 * As a result, all bounding boxes would be able to restored on the UI after removal.
 *
 * In addition, user could restore them one by one, from the most latest removeal to
 * the least latest removal. It was not possible before this update.
 */

/**
 * Change Log:
 *
 * Change Date: Feb 17, 2021
 *
 * Description:
 *
 * Improved the user experience of editing a bounding box.
 *
 * Implemented a double click listener for items of the bounding box list. As a result,
 * double clicking a list item, which represent as boudning box item of the selected data point,
 * the canvas component would show the rating component for the corresponding bounding box.
 *
 * @see BoundingBoxList
 * @see handleDoubleClickOnLabel within the @see BoundingBoxList component
 */

/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Mar 30, 2021
 *
 * Description: added PCI calcuation mechanism
 *
 * @see calculatePCI
 */

/**
 * Change Log:
 *
 * Change Date: Jun 10, 2021
 *
 * Description: udpate the mechanism on how to draw bounding box thumbnail
 *
 * As the bounding box is no longer necessary to be a rectangle, instead, it would be a trapezoid.
 * So its thunbnail need to be the same (scale down) shape.
 *
 *
 * @see drawBoundingBoxThumbnail
 */

/**
 * Change Log:
 *
 * Change Date: Aug 07, 2021
 *
 * Description:
 * removed calculatePCI method from this component, same method would be
 * call after all bounding boxes are sent to server, so that they have their
 * valid id, which the is required by the new payload of the API
 */
