export const GLOBAL_ACTION_TYPES = {
  setError: "SET GLOBAL ERROR",
  setMessage: "SET MESSAGEE",
  setLabelPreference: "SET LABEL PREFERNCE",
};

export const setGlobalMessage = (errorObject) => ({
  type: GLOBAL_ACTION_TYPES.setError,
  payload: errorObject,
});
export const setToastMessage = (message) => ({
  type: GLOBAL_ACTION_TYPES.setMessage,
  payload: message,
});

export const setLabelPrefence = (preference) => ({
  type: GLOBAL_ACTION_TYPES.setLabelPreference,
  payload: preference,
});
