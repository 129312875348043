/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * @author: Lucien Chu
 * Create Date: Jun 26, 2020
 *
 * @description: Search bar for Iris Portal
 * used to filer data points from the backend.
 * Based on date, city name, device id, damage type,
 * with label status (has bounding box drawn or not) and data point address.
 */

import React, { useState, useEffect, useReducer } from "react";
import { InputLabel, makeStyles } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Spinner from "../../UI/spinner/Spinner";
import DateFnsUtils from "@date-io/date-fns";
import { getDate } from "../../containers/home/homeUtils";
import "react-google-places-autocomplete/dist/index.min.css";
import { connect } from "react-redux";
import { startGettingDataPoints } from "../../redux/reducers/dataPointsReducer/dataPointActions";
import { setGlobalMessage } from "../../redux/reducers/globalReducer/globalActions";
import { getLabelSummary, sendReport } from "../../awsBackend/awsBackendUtils";
import { TOKEN } from "../login/localStorageKeys";

import {
  IrisButton,
  IrisGrid,
  IrisGroupedSelect,
  IrisInput,
  IrisSelect,
} from "irisrad-ui";
import moment from "moment-timezone";

const DEFAULT_DEVICE_SN_NAME = "N / A";
const DEFAULT_SHIFTS = [
  { shiftKey: "00:00-24:00", shiftValue: "00:00-24:00", start: 0, end: 24 },
];
const DEFAULT_STATUS_SELECTION = "Please select a label";
const ALL_DEFECTS_SELECTION = "All";

const DEFAULT_DEVICE = {
  value: 0,
  cityId: 0,
  label: DEFAULT_DEVICE_SN_NAME,
  cityName: "N / A",
  shifts: [...DEFAULT_SHIFTS],
};
const REDUCER_ACTIONS = {
  SET_IS_LOADING: "SET IS LOADING",
  SET_DATE: "SET DATE",
  SET_SELECTED_DEVICE: "SET SELECTED DEVICE",
  SET_SEARCHED_LOCATION: "SET SEARCHED LOCATION",
  SET_CURRENT_ADDRESS: "SET CURRENT ADDRESS",
  SET_INPUT_CITY: "SET INPUT CITY",
  SET_INPUT_DATA_POINT_ID: "SET INPUT DATA POINT ID",
  SET_LABEL_TYPE: "SET LABEL TYPE",
  SET_GROUPED_DEFECTS: "SET GROUPED DEFECTS",
  SET_SELECTED_SHIFT: "SET SELECTED SHIFT",
};

const initialState = {
  isLoading: false,
  selectedDateString: new Date(),
  // selectedDateString: new Date(2021, 2, 14),
  selectedDevice: { ...DEFAULT_DEVICE },
  selectedStatusId: 0,
  searchLocation: null,
  currentAddress: "",
  inputCityString: "",
  inputDatapointId: "",
  labelType: "",
  groupedDefects: [
    {
      id: DEFAULT_STATUS_SELECTION,
      name: DEFAULT_STATUS_SELECTION,
      parentName: "",
    },
    { id: ALL_DEFECTS_SELECTION, name: ALL_DEFECTS_SELECTION, parentName: "" },
  ],
  selectedShift: "00:00-24:00",
};

const reducer = (initialState, action) => {
  const { type, payload } = action;
  console.log(`reducer`, type, payload);
  switch (type) {
    case REDUCER_ACTIONS.SET_IS_LOADING:
      return { ...initialState, isLoading: payload };
    case REDUCER_ACTIONS.SET_DATE:
      return { ...initialState, selectedDateString: payload };
    case REDUCER_ACTIONS.SET_SELECTED_DEVICE:
      return { ...initialState, selectedDevice: payload };
    case REDUCER_ACTIONS.SET_SEARCHED_LOCATION:
      return { ...initialState, searchLocation: payload };
    case REDUCER_ACTIONS.SET_CURRENT_ADDRESS:
      return { ...initialState, currentAddress: payload };
    case REDUCER_ACTIONS.SET_INPUT_CITY:
      return { ...initialState, inputCityString: payload };
    case REDUCER_ACTIONS.SET_INPUT_DATA_POINT_ID:
      return { ...initialState, inputDatapointId: payload };
    case REDUCER_ACTIONS.SET_LABEL_TYPE:
      return { ...initialState, labelType: payload };
    case REDUCER_ACTIONS.SET_GROUPED_DEFECTS:
      return { ...initialState, groupedDefects: payload };
    case REDUCER_ACTIONS.SET_SELECTED_SHIFT:
      return { ...initialState, selectedShift: payload };
    default:
      return initialState;
  }
};

const TAG = "SearchBar.js";
const labelSelections = [
  {
    id: DEFAULT_STATUS_SELECTION,
    name: DEFAULT_STATUS_SELECTION,
    parentName: "",
  },
  { id: ALL_DEFECTS_SELECTION, name: ALL_DEFECTS_SELECTION, parentName: "" },
];

const useStyle = makeStyles(() => ({
  wrapper: {
    // "& > *": {
    //   marginLeft: "10px",
    //   marginRight: "10px",
    // },
  },
  cityInput: {
    marginTop: "1rem",
    "&.Mui-focused": {
      borderColor: "#C52328",
      borderWidth: "2px",
    },
  },

  girdElement: { padding: "1rem" },
}));

export const getQueryTimeRange = (shiftValue, shifts, currentDate) => {
  const localYear = currentDate.getFullYear();
  const localMonth = currentDate.getMonth() + 1;
  const localDate = currentDate.getDate();

  const monthString = localMonth < 10 ? "0" + localMonth : "" + localMonth;
  const dateString = localDate < 10 ? "0" + localDate : "" + localDate;

  const UTCDateString = `${localYear}-${monthString}-${dateString} 00:00:00`;
  const target = shifts.find((shift) => shift.shiftValue === shiftValue);

  const { start, end } = target;

  /** convert local timestamp to Vaughan timestamp */
  const vaughanTime = moment.tz(UTCDateString, "Canada/Eastern");

  /** utc offset hours of Vaugha, could be 4 (EDT) or 5 (EST) */
  const vaughanUTCOffset = vaughanTime.utcOffset() / 60;

  /** get year, month and date of the current Vaughan timestamp */
  const year = vaughanTime.get("year");
  const month = vaughanTime.get("month");
  const date = vaughanTime.get("date");

  const currentVaughanUTC = new Date(vaughanTime.toISOString());
  currentVaughanUTC.setUTCHours(0 - vaughanUTCOffset);
  currentVaughanUTC.setUTCHours(start + 0 - vaughanUTCOffset);

  const timeAfter = currentVaughanUTC
    .toISOString()
    .substring(0, currentVaughanUTC.toISOString().lastIndexOf(":"));

  currentVaughanUTC.setUTCHours(end + 0 - vaughanUTCOffset);
  const timeBefore = currentVaughanUTC
    .toISOString()
    .substring(0, currentVaughanUTC.toISOString().lastIndexOf(":"));

  // console.log(
  //   `value, target, timeAfter, timeBefore`,
  //   shiftValue,
  //   target,
  //   timeAfter,
  //   timeBefore
  // );
  return { time_before: timeBefore, time_after: timeAfter };
};
const SearchBar = (props) => {
  // selections for searching
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    isLoading,
    selectedDateString,
    selectedDevice,
    selectedStatusId,
    searchLocation,
    inputCityString,
    inputDatapointId,
    labelType,
    groupedDefects,
    selectedShift,
  } = state;
  const [deviceSNObjArray, setDeviceSNObjArray] = useState([
    { ...DEFAULT_DEVICE },
  ]);
  // Context

  const { onSearchDataPoints, deviceArray } = props;

  const classes = useStyle();

  useEffect(() => {
    //filter out only valid devices
    const validDevices = deviceArray
      .filter((deviceInfo) => deviceInfo.device_sn !== "0")
      .filter((deviceInfo) => deviceInfo.city !== null)
      .map((device) => {
        const { id, tag, city } = device;

        const { id: cityId, name: cityName, shifts } = city;

        let s = shifts;
        const [firstShift] = shifts;
        if (!s || s.length === 0 || firstShift === "{}") {
          s = [...DEFAULT_SHIFTS];
        } else {
          s = shifts.map((shift) => {
            let tempShift = shift;
            const [startString, endString] = shift.split("-");
            const [startHourString] = startString.split(":");
            const [endHourString] = endString.split(":");

            const endHourInt = parseInt(endHourString);

            if (endHourInt > 24) {
              const newEndHour = endHourInt % 24;
              let newEndHourString = newEndHour + ":00";
              if (newEndHour < 10) {
                newEndHourString = "0" + newEndHourString;
              }
              tempShift = startString + "-" + newEndHourString;
            }
            return {
              shiftKey: tempShift,
              shiftValue: tempShift,
              start: parseInt(startHourString),
              end: endHourInt,
            };
          });
        }
        return { value: id, label: tag, cityId, cityName, shifts: s };
      });

    validDevices.sort((a, b) => {
      const { label: labelA } = a;
      const { label: labelB } = b;
      if (labelA.toLowerCase() > labelB.toLowerCase()) {
        return 1;
      } else if (labelA.toLowerCase() < labelB.toLowerCase()) {
        return -1;
      }
      return 0;
    });

    // sort the device object alphabetically by tag name
    validDevices.sort((objectA, objectB) => {
      const valueA = objectA.label.toUpperCase(); // ignore upper and lowercase
      const valueB = objectB.label.toUpperCase(); // ignore upper and lowercase
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
      return 0; // names must be equal
    });
    // added default value as first element of valid devices array
    validDevices.unshift({
      value: 0,
      cityId: 0,
      label: DEFAULT_DEVICE_SN_NAME,
      cityName: "N / A",
      shifts: [...DEFAULT_SHIFTS],
    });
    console.log(`validDevices`, validDevices);
    setDeviceSNObjArray(validDevices);
  }, [deviceArray]);

  useEffect(() => {
    if (Array.isArray(props.menu)) {
      const alertedMenu = props.menu
        // filter the disabled labels, whose enabled property is "N"
        .filter((label) => label.enabled === "Y")
        .map((menu) => {
          const copy = { ...menu };
          copy["parentName"] = copy.parents[0] || "Defect Types"; // as parent child relation about defect type is no longer valid, give a general name
          return copy;
        });

      const groupedDefects = labelSelections.concat(alertedMenu);

      dispatch({
        type: REDUCER_ACTIONS.SET_GROUPED_DEFECTS,
        payload: groupedDefects,
      });
    }
  }, [props.menu]);

  const handleInputCity = (event) => {
    let cityValue = event.target.value.trim();
    if (cityValue.length > 0) {
      cityValue = cityValue.charAt(0).toUpperCase() + cityValue.slice(1);
    }
    dispatch({ type: REDUCER_ACTIONS.SET_INPUT_CITY, payload: cityValue });
  };

  const handleInputDatapointID = (event) => {
    if (!isNaN(event.target.value)) {
      dispatch({
        type: REDUCER_ACTIONS.SET_INPUT_DATA_POINT_ID,
        payload: event.target.value,
      });
    } else {
      event.target.value = "";
    }
  };

  /**
   * prepares params for query data points from server
   *
   * @param {Boolean} withAllLabel whether fetch data points with labels only
   *
   * @see queryDataPoints
   */
  const handleSearch = (withAllLabel = false) => {
    let withLabel = false;

    if (withAllLabel === true) {
      withLabel = ALL_DEFECTS_SELECTION;
    } else {
      for (const selection of labelSelections) {
        if (selection.id === selectedStatusId) {
          withLabel = selection.booleanValue;
        }
      }
    }

    const queryTimeRange = getQueryTimeRange(
      selectedShift,
      selectedDevice.shifts,
      selectedDateString
    );
    dispatch({ type: REDUCER_ACTIONS.SET_CURRENT_ADDRESS, payload: "" });
    queryDataPoints({
      city: inputCityString,
      date: getDate(selectedDateString),
      device_id: selectedDevice.value,
      with_label: withLabel,
      searchLocation: searchLocation,
      damageTypeId: 0,
      datapoint_id: inputDatapointId,
      label_type: labelType,
      queryTimeRange,
    });
    dispatch({ type: REDUCER_ACTIONS.SET_SEARCHED_LOCATION, payload: null });
  };

  /**
   * search data points based on text fields' values and (or)
   * selectors' values
   *
   * @param {Object} searchParams
   */
  const queryDataPoints = async (searchParams) => {
    const {
      city,
      date,
      device_id,
      with_label,
      searchLocation,
      damageTypeId,
      datapoint_id,
    } = searchParams;

    // if no text field edited and no selector is toggled
    // do nothing
    if (
      city === 0 &&
      (date === "" || date === null) &&
      device_id === "" &&
      with_label === false &&
      searchLocation === null &&
      damageTypeId === 0 &&
      datapoint_id == 0
    ) {
      return;
    } else {
      onSearchDataPoints(searchParams);
    }
  };

  const handleLabelIdSelected = (newId) => {
    dispatch({ type: REDUCER_ACTIONS.SET_LABEL_TYPE, payload: newId });
  };

  /**
   * get the device summary for the current date
   *
   */
  const showSendReportDialog = () => {
    const cityName = selectedDevice.cityName || "N / A";
    const deviceTag = selectedDevice.label || "N / A";
    const reportShift = getQueryTimeRange(
      selectedShift,
      selectedDevice.shifts,
      selectedDateString
    );
    dispatch({ type: REDUCER_ACTIONS.SET_IS_LOADING, payload: true });
    getLabelSummary({
      // date: getDate(selectedDateString),
      // date: getDate(selectedDateString),
      labelShift: reportShift,
      deviceId: selectedDevice.value,
    })
      .then(({ success, result }) => {
        if (success === true && result && props.toggleSummaryDetails) {
          // const deviceObject = deviceSNObjArray.find(
          //   (device) => device.value === result.device_id
          // );

          // const sd = selectedDevice;
          // let cityName = deviceObject?.cityName || "N / A";
          // let deviceTag = deviceObject?.label || "N / A";

          // update the state of the parent so that the
          // summary modal would be shown
          // NOTE: it's parent is IrisHeader, but the modal is attached
          // to Home, so the method is passed from Home component
          props.toggleSummaryDetails({
            ...result,
            deviceTag,
            cityName,
            reportShift: reportShift,
          });
        }
      })
      .finally(() => {
        dispatch({ type: REDUCER_ACTIONS.SET_IS_LOADING, payload: false });
      });
  };

  // const getQueryTimeRange = (shiftValue, shifts, currentDate) => {
  //   const target = shifts.find((shift) => shift.shiftValue === shiftValue);

  //   const { start, end } = target;

  //   const tempDate = new Date(currentDate);
  //   tempDate.setMinutes(0);
  //   tempDate.setMilliseconds(0);
  //   tempDate.setSeconds(0);
  //   console.log(tempDate);
  //   tempDate.setUTCHours(start);
  //   const timeAfter = tempDate
  //     .toISOString()
  //     .substr(0, tempDate.toISOString().lastIndexOf(":"));

  //   tempDate.setUTCHours(end);
  //   const timeBefore = tempDate
  //     .toISOString()
  //     .substr(0, tempDate.toISOString().lastIndexOf(":"));

  //   console.log(
  //     `value, target, timeAfter, timeBefore`,
  //     shiftValue,
  //     target,
  //     timeAfter,
  //     timeBefore
  //   );

  //   return { time_before: timeBefore, time_after: timeAfter };
  // };
  // const getQueryTimeRange = (shiftValue, shifts, currentDate) => {
  //   const localYear = currentDate.getFullYear();
  //   const localMonth = currentDate.getMonth() + 1;
  //   const localDate = currentDate.getDate();
  //   const target = shifts.find((shift) => shift.shiftValue === shiftValue);

  //   const { start, end } = target;

  //   /** convert local timestamp to Vaughan timestamp */
  //   const vaughanTime = moment.tz(
  //     `${localYear}-${localMonth}-${localDate}`,
  //     "Canada/Eastern"
  //   );

  //   console.log(`vaughanTime.toString()`, vaughanTime.toString());
  //   console.log(`vaughanTime.toJSON()`, vaughanTime.toJSON());

  //   /** utc offset hours of Vaugha, could be 4 (EDT) or 5 (EST) */
  //   const vaughanUTCOffset = vaughanTime.utcOffset() / 60;

  //   /** get year, month and date of the current Vaughan timestamp */
  //   const year = vaughanTime.get("year");
  //   const month = vaughanTime.get("month");
  //   const date = vaughanTime.get("date");

  //   const currentVaughanUTC = new Date();
  //   currentVaughanUTC.setUTCFullYear(year);
  //   currentVaughanUTC.setUTCMonth(month);
  //   currentVaughanUTC.setUTCDate(date);
  //   currentVaughanUTC.setUTCHours(0 - vaughanUTCOffset);
  //   currentVaughanUTC.setUTCMinutes(0);
  //   currentVaughanUTC.setUTCSeconds(0);
  //   currentVaughanUTC.setUTCMilliseconds(0);
  //   currentVaughanUTC.setUTCHours(start + 0 - vaughanUTCOffset);

  //   const timeAfter = currentVaughanUTC
  //     .toISOString()
  //     .substring(0, currentVaughanUTC.toISOString().lastIndexOf(":"));

  //   currentVaughanUTC.setUTCHours(end + 0 - vaughanUTCOffset);
  //   const timeBefore = currentVaughanUTC
  //     .toISOString()
  //     .substring(0, currentVaughanUTC.toISOString().lastIndexOf(":"));

  //   console.log(
  //     `value, target, timeAfter, timeBefore`,
  //     shiftValue,
  //     target,
  //     timeAfter,
  //     timeBefore
  //   );
  //   return { time_before: timeBefore, time_after: timeAfter };
  // };

  return (
    <IrisGrid
      className="search-bar-wrapper"
      container
      justifyContent="center"
      id="search-bar-contaner"
      style={{ maxWidth: "1440px", margin: "0 auto" }}
    >
      {isLoading && <Spinner />}
      <IrisGrid
        id="search-bar-contaner-left"
        container
        item
        justifyContent="center"
        alignItems="center"
        style={{ padding: "0.5rem" }}
      >
        <IrisGrid item md={2} sm={4} xs={5} style={{ marginBottom: "1.2rem" }}>
          <InputLabel>Please pick a date</InputLabel>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              id="datePickerId"
              format="yyyy-MM-dd"
              onChange={(date) => {
                dispatch({ type: REDUCER_ACTIONS.SET_DATE, payload: date });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              maxDate={new Date()}
              value={selectedDateString}
            />
          </MuiPickersUtilsProvider>
        </IrisGrid>
        <IrisGrid item md={2} sm={4} xs={5}>
          <IrisInput
            id="cityNameInput"
            label="City Name"
            onChange={handleInputCity}
          />
        </IrisGrid>
        <IrisGrid item md={2} sm={4} xs={10}>
          <IrisSelect
            label="Device Id"
            onChange={(value) => {
              const deviceId = Number(value);
              const target = deviceSNObjArray.find(
                (device) => device.value === deviceId
              );
              dispatch({
                type: REDUCER_ACTIONS.SET_SELECTED_DEVICE,
                payload: target,
              });
              dispatch({
                type: REDUCER_ACTIONS.SET_SELECTED_SHIFT,
                payload: target.shifts[0].shiftValue,
              });
            }}
            options={deviceSNObjArray}
            value={selectedDevice.value}
            valueField="value"
            labelField="label"
          />
        </IrisGrid>
        <IrisGrid item md={2} sm={4} xs={10}>
          <IrisSelect
            label="Shift"
            onChange={(value) => {
              dispatch({
                type: REDUCER_ACTIONS.SET_SELECTED_SHIFT,
                payload: value,
              });
            }}
            options={selectedDevice.shifts}
            value={selectedDevice.shifts[0].value}
            valueField="shiftValue"
            labelField="shiftKey"
          />
        </IrisGrid>

        <IrisGrid item md={2} sm={4} xs={10}>
          <IrisGroupedSelect
            label="Label"
            value=""
            options={groupedDefects}
            groupedField="parentName"
            onChange={handleLabelIdSelected}
            valueField="id"
            labelField="name"
          />
        </IrisGrid>
        <IrisGrid item md={2} sm={4} xs={10}>
          <IrisInput
            id="labelIdInput"
            name="datapointId"
            label="Datapoint ID"
            onChange={handleInputDatapointID}
          />
        </IrisGrid>
      </IrisGrid>
      <IrisGrid container justifyContent="flex-end" alignItems="center">
        <IrisGrid item style={{ marginRight: 20 }}>
          <IrisButton
            size="large"
            iconProps={{
              iconTitle: "summarize",
              size: "large",
            }}
            color="secondary"
            disabled={selectedDevice.value === 0}
            onClick={showSendReportDialog}
          >
            Summary
          </IrisButton>
        </IrisGrid>
        <IrisGrid item>
          <IrisButton
            size="large"
            onClick={handleSearch}
            iconProps={{ iconTitle: "search", size: "large" }}
          >
            Search
          </IrisButton>
        </IrisGrid>

        {/* </IrisGrid> */}
      </IrisGrid>
    </IrisGrid>
  );
};

// could be an object with action creator
const mapStateToProps = (state) => {
  const deviceArray = JSON.parse(JSON.stringify(state.loginState.devices));
  const menu = state.loginState.labelTypes;
  return {
    deviceArray,
    menu,
  };
};
const mapDispatchToProps = {
  onSearchDataPoints: startGettingDataPoints,
  setGlobalMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);

/**
 * Change Log:
 *
 * Change Date: Jun 26, 2020
 *
 * @description: added and finished comments
 */

/**
 * Change Log:
 *
 * Change Date: Oct 13, 2020
 *
 * Description: replace useContext with react-redux
 */

/**
 * Change Log:
 *
 * Change Date: Jan 21, 2021
 *
 * Description: updated the html text for the drop down selection
 * values for device. Before only device_sn is shown, now the
 * tag value would also be shown along with the device_sn value.
 */

/**
 * Change Log:
 *
 * Change Date: Mar 03, 2021
 *
 * Description: add selector for labeler to picking a label type for
 * querying specific data points
 */
