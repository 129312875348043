/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Nov 21, 2021
 *
 * Description: A demonstrative component that shows some shortcut keys combo
 * for user to interact with the webpage
 */

import React from "react";
import styles from "./irisShortcutKeyList.module.scss";
import { Grid } from "@material-ui/core";
export const SHORTCUT_KEY_LIST_ID = "shortcutKeyListId";
const messages = [
  {
    title: "Navigation",
    keys: [
      { keyMap: "shift + /", details: "Toggle the shortcut list." },
      { keyMap: "shift + s", details: "Toggle search bar." },
      { keyMap: "←", details: "Show data points of previous page." },
      { keyMap: "→", details: "Show data points of next page." },
      { keyMap: "↑", details: "Show previous data point." },
      { keyMap: "↓", details: "Show next data point." },
    ],
  },
  {
    title: "Toggle bounding boxes",
    keys: [
      {
        keyMap: "backspace / delete",
        details: "Delete selected bounding box.",
      },
      {
        keyMap: "shift + d",
        details: "Remove / restore bounding boxes.",
      },
      {
        keyMap: "ctrl + z",
        details: "Restore the latest removed bounding box.",
      },

      // Added Date: Aug 11, 2021
      {
        keyMap: "ctrl + s",
        details: "With the canvas highlighted, save bounding boxes to server",
      },
      {
        keyMap: "ctrl + c",
        details:
          "With the canvas highlighted, copy the set of currently shown bounding boxes",
      },
      {
        keyMap: "ctrl + v",
        details:
          "With the canvas highlighted, paste the set of copied bounding boxes",
      },
      {
        keyMap: "ctrl + shift + z",
        details:
          "With the canvas highlighted, remove the set of pasted bounding boxes",
      },
    ],
  },
  {
    title: "Labeling",
    keys: [
      { keyMap: "shift + m", details: "Toggle the magnifier." },
      {
        keyMap: "enter",
        details: "Edit current bounding box rating (from canvas).",
      },
      {
        keyMap: "enter / double click",
        details: "Edit current bounding box rating (from bounding box list).",
      },
      { keyMap: "1 .. 9", details: "Select a bounding box rating." },
    ],
  },
  // {
  //   title: "Calibration",
  //   keys: [{ keyMap: "shift + c", details: "Calibrate the image skew." }],
  // },
];

export const toggleShortcutListDisplayStyle = () => {
  const element = document.getElementById(SHORTCUT_KEY_LIST_ID);
  if (element.style.display === "inline-block") {
    element.style.opacity = 0;
    setTimeout(() => {
      element.style.display = "none";
    }, 200);
  } else {
    // display the component first
    element.style.display = "inline-block";
    setTimeout(() => {
      // set opacity after a short while so that
      // the css transition could animate it,
      // resulting in a fading in effect.
      element.style.opacity = 1;
    }, 10);
  }
};

export default function IrisShortutKeyList(props) {
  return (
    <div
      className={styles.backdrop}
      id={SHORTCUT_KEY_LIST_ID}
      onClick={toggleShortcutListDisplayStyle}
    >
      <div
        className={styles.popupPanel}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h1>Shortcut Keys</h1>
        <div style={{ margin: "1rem 0.5rem" }}>
          <Grid container spacing={4} alignItems="flex-start">
            {messages.map((message, index) => (
              <Grid
                key={index}
                style={{ margin: "0.5rem 0" }}
                item
                container
                sm={6}
                xs={12}
              >
                <Grid
                  item
                  style={{
                    margin: "1rem 0",
                    fontSize: "1.125rem",
                  }}
                  xs={12}
                >
                  {message.title}
                </Grid>
                {message.keys.map((keyMap, index) => (
                  <Grid
                    key={index}
                    item
                    container
                    style={{
                      padding: "0.75rem 0.5rem",
                      borderTop: "1px solid #9c9c9c",
                      lineHeight: "1.75rem",
                      fontSize: "1rem",
                    }}
                  >
                    <Grid item xs={8} style={{ color: "#9c9c9c" }}>
                      {keyMap.details}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={4}
                      style={{
                        textAlign: "right",
                      }}
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid item>{keyMap.keyMap}</Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
            {/* <Grid style={{ margin: "0.5rem 0" }} item container sm={6} xs={12}>
              b
            </Grid> */}
          </Grid>
        </div>
      </div>
    </div>
  );
}
/**
 * Change Log:
 *
 * Change Date: Jan 21, 2021
 *
 * Description: added "ctrl + z" shortcut key's description
 */

/**
 * Change Log:
 *
 * Change Date: Jan 28, 2021
 *
 * Description:
 *
 * Defined and exported animateShortcutList function for other components (App and IrisHeader)
 * to toggle the display style for the wrapper of this component.
 *
 * @see toggleShortcutListDisplayStyle
 */

/**
 * Change Log:
 *
 * Change Date: Feb 17, 2021
 *
 * Description:
 * added implemented shortcuts for editing bounding box rating from the bounding box list
 * 1. click an item on the bounding box list (as a result, a bounding box would be selected, and highlighted on the
 * canvas component), and hit enter, so that the rating component would be brought up;
 *
 * 2. double click an item on the bounding box list so that the rating component would be brought up.
 */

/**
 * Change Log:
 *
 * Change Date: Aug 11, 2021
 *
 * Description:
 * 1. added descriptions for keys used for copy, paste, and undo paste bounding boxes for a data point
 * 2. removed description for skew adjustment calibration as this feature is removed
 */
