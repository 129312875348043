import React, { useState, useEffect } from "react";

import { IrisGrid, IrisButton, IrisModal } from "irisrad-ui";
import {
  createWorkOrdersBatch,
  sendFmeReport,
  sendReport,
  triggerAutoGeneratingWorkorderProcess,
} from "../../awsBackend/awsBackendUtils";
import { TOKEN } from "../login/localStorageKeys";
import Spinner from "../../UI/spinner/Spinner";
import DateUtils from "../../utils/DateUtils";
import moment from "moment-timezone";
import UploadFile from "../uploadFile/UploadFile";
const NA = "N / A";
export default function SummaryDetails({
  deviceDetails,
  onClose,
  getDataPointsWithLabels,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [reportObject, setReportObject] = useState({
    date: NA,
    deviceId: -1,
    deviceTag: NA,
    cityName: NA,
    reviewed: 0,
    total: 0,
    numOfDefects: 0,
    numOfFlags: 0,
    defectObjects: [],
    flagObjects: [],
    reportShift: {
      time_after: new Date().toISOString().split("T")[0],
      time_before: new Date().toISOString().split("T")[0],
    },
  });

  const [isSendReportBtnDisabled, setIsSendReportBtnDisabled] = useState(true);

  // upload csv files array
  const [uploadCSVFilesList, setUploadFilesList] = useState([]);

  // max number of data point id allowed to submit, hard code
  const MAXDATAPOINTIDAMOUNT = 100000;

  // number of current uploaded data point id
  const [currDatapointIdAmout, setCurrDatapointIdAmout] = useState(0);

  // const handleSendReport = () => {
  //   const {
  //     deviceTag,
  //     cityName,
  //     date,
  //     total,
  //     numOfDefects,
  //     numOfFlags,
  //     reportShift,
  //     deviceId,
  //   } = reportObject;

  //   debugger;
  //   sendFmeReport({ deviceId, ...reportShift });
  // };
  const handleSendReport = () => {
    const {
      deviceTag,
      cityName,
      date,
      total,
      numOfDefects,
      numOfFlags,
      reportShift,
      deviceId,
    } = reportObject;

    /** prop for api call, either undefined or an object with uploaded data */
    let uploadFileContent = undefined;
    // merge all of the upload csv files' content into 1 object
    if (uploadCSVFilesList.length > 0) {
      uploadFileContent = {};
      uploadCSVFilesList.forEach((file) => {
        uploadFileContent = { ...uploadFileContent, ...file.fileContent };
      });
    }
    // console.log("uploadFileContent", uploadFileContent);

    const reportData = {
      deviceId: deviceId,
      device: deviceTag,
      city: cityName,
      date: date,
      total: total,
      labeled: numOfDefects,
      flag: numOfFlags,
      reportShift,
      uploadFileContent,
    };

    // console.log("report data", reportData);

    let message = "error: ";
    setIsLoading(true);
    // sendReport(reportData, localStorage.getItem(TOKEN))
    //   .then((result) => {
    //     const { success, result: serverMessage } = result;
    //     if (success) {
    //       message = serverMessage;
    //       // send another fem report
    //       sendFmeReport({ deviceId, ...reportShift });
    //     }
    //   })
    //   .catch((error) => {
    //     message += error.message;
    //     console.log(`error.message`, message);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //     onClose();
    //     props.onSetGlobalMessage({
    //       title: `Report is sent ${
    //         message.startsWith("error") ? "failed" : "successfully"
    //       }`,
    //       message: message,
    //     });
    //   });

    // !TODO remove in future
    // triggerAutoGeneratingWorkorderProcess(
    //   reportData,
    //   localStorage.getItem(TOKEN)
    // )
    //   .then((result) => {
    //     if (result) {
    //       message = result;
    //       sendFmeReport({ deviceId, ...reportShift });
    //     }
    //   })
    //   .catch((error) => {
    //     message += error.message;
    //     console.log(`error.message`, message);
    //   })
    //   .finally(() => {
    //     setIsLoading(false);
    //     onClose();
    //     props.onSetGlobalMessage({
    //       title: `Report is sent ${
    //         message.startsWith("error") ? "failed" : "successfully"
    //       }`,
    //       message: message,
    //     });
    //   });

    createWorkOrdersBatch(reportData)
      .then((result) => {
        if (result) {
          message = result?.message;
        }
      })
      .catch((error) => {
        message += error.message;
        console.log(`error.message`, message);
      })
      .finally(() => {
        setIsLoading(false);
        onClose();
        props.onSetGlobalMessage({
          title: `Report is sent ${message.includes("created") ? "successfully" : "failed"
            }`,
          message: message,
        });
      });
  };

  /** @type {import("../uploadFile/UploadFile").UploadFileProps} */
  const uploadCSVFileProps = {
    uploadFileType: ".csv",
    buttonContent: "Upload CSV File",
    handleUploadFile: (uploadFile) => {
      const uploadFileName = uploadFile.name;
      const reader = new FileReader();
      reader.readAsText(uploadFile, "UTF-8");

      reader.onload = (event) => {
        const uploadFileContent = event.target.result;
        // split the csv file into an array, every element of the array stands for data of one row
        const csvContentArrTemp = uploadFileContent.split("\n");
        // the output data is an object
        const output = {};

        csvContentArrTemp.forEach((row) => {
          const rowArr = row.split(",");
          // output object has several properties, each of which has a key and a value
          // key is datapoint id, value is asset id
          if (rowArr.length >= 2) {
            // regex to remove the "\r"
            output[`${rowArr[0].replace(/(\r\n|\n|\r)/gm, "")}`] =
              rowArr[1].replace(/(\r\n|\n|\r)/gm, "");
          }
        });

        const newFile = {
          fileName: uploadFileName,
          fileContent: output,
        };

        // if case to check if the upload datapoint exceeds limit
        if (
          Object.keys(output).length + currDatapointIdAmout <=
          MAXDATAPOINTIDAMOUNT
        ) {
          // if case to check if the uploading file's name is unique
          if (
            uploadCSVFilesList.find(
              (oneFile) => oneFile.fileName === newFile.fileName
            )
          ) {
            alert("Same file name exists.");
          } else {
            setUploadFilesList((prev) => [...prev, newFile]);
            // console.log("new file", newFile);
          }
        } else {
          alert(
            `Number of uploaded datapoint id has exceeded ${MAXDATAPOINTIDAMOUNT.toLocaleString()}.`
          );
        }
      };
    },
    FilesList: uploadCSVFilesList,
    setUploadFilesList: setUploadFilesList,
    isDisabled: false,
    helperText: (
      <span style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ marginTop: "0.3rem" }}>
          Max number of datapoint id is
          <span
            style={{ fontWeight: 900 }}
          >{` ${MAXDATAPOINTIDAMOUNT.toLocaleString()}.`}</span>
        </span>
        <span style={{ marginTop: "0.3rem" }}>
          <span
            style={{
              fontWeight: 900,
              color:
                currDatapointIdAmout === MAXDATAPOINTIDAMOUNT ? "#c1151f" : "",
            }}
          >
            {`${currDatapointIdAmout}/${MAXDATAPOINTIDAMOUNT.toLocaleString()} `}
          </span>
          datapoint id is uploaded.
        </span>
      </span>
    ),
  };

  // update the number of uploaded data point id
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      let num = 0;
      if (uploadCSVFilesList.length > 0) {
        uploadCSVFilesList.forEach(
          (file) => (num += Object.keys(file.fileContent).length)
        );
      }
      setCurrDatapointIdAmout(num);
    }
    return () => (isSubscribed = false);
  }, [uploadCSVFilesList]);

  useEffect(() => {
    const showDetailsPropup = (summaryResult) => {
      const {
        total,
        reviewed,
        device_id: deviceId,
        deviceTag,
        cityName,
        with_label: { summary: labelSummary },
        with_flag: { summary: flagSummary },
        label_date: reportDate,
        reportShift,
      } = summaryResult;

      let numOfDefects = 0;
      const defectObjects =
        labelSummary?.map((summary) => {
          const { label_type__name: defectName, total: count } = summary;
          numOfDefects += count;
          return { defectName, count };
        }) || [];

      let numOfFlags = 0;
      const flagObjects = flagSummary?.map(({ name, count }) => {
        numOfFlags += count;
        return {
          flagName: name,
          count: count,
        };
      });

      setReportObject({
        date: reportDate,
        deviceId: deviceId,
        deviceTag,
        cityName,
        total,
        reviewed,

        numOfDefects,
        numOfFlags,
        defectObjects,
        flagObjects,
        reportShift,
      });
    };

    if (deviceDetails) {
      showDetailsPropup(deviceDetails);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [deviceDetails, setReportObject]);
  return (
    <IrisModal open={isOpen} onClose={onClose} closeOnBackdrlpClicked={true}>
      {isLoading && <Spinner />}
      <div className="dialog-content-wrapper">
        <div>
          <div className="title">
            <p>Device summary</p>
          </div>
          <IrisGrid container>
            <IrisGrid container>
              <p className="subtitle">Overview</p>
            </IrisGrid>
            {/* <IrisGrid item sm={6} style={{ padding: "1em" }}>
              <p className="details-title">Date</p>
              <p className="details-content">{reportObject.date}</p>
            </IrisGrid> */}
            <IrisGrid item sm={6} style={{ padding: "1em" }}>
              <p className="details-title">Shift</p>
              <p className="details-content">
                {/* {reportObject.reportShift.time_after.replace("T", " ")} -{" "}
                {reportObject.reportShift.time_before.replace("T", " ")} */}
                {moment
                  .tz(
                    moment.utc(reportObject.reportShift.time_after),
                    "Canada/Eastern"
                  )
                  .format("YYYY-MM-DD hh:mm a")}{" "}
                -{" "}
                {
                  // DateUtils.getyyyyMMddhhmmss(
                  //   reportObject.reportShift.time_before + ":00.000Z"
                  // )
                  moment
                    .tz(
                      moment.utc(reportObject.reportShift.time_before),
                      "Canada/Eastern"
                    )
                    .format("YYYY-MM-DD hh:mm a")
                }
              </p>
            </IrisGrid>

            <IrisGrid item sm={6} style={{ padding: "1em" }}>
              <p className="details-title">Device</p>
              <p className="details-content">{reportObject.deviceId}</p>
            </IrisGrid>
            <IrisGrid item sm={6} style={{ padding: "1em" }}>
              <p className="details-title">Device Tag</p>
              <p className="details-content">{reportObject.deviceTag}</p>
            </IrisGrid>

            <IrisGrid item sm={6} style={{ padding: "1em" }}>
              <p className="details-title">viewed / total</p>
              {/* <p className = "details-content">{reportObject.reviewedOverTotal}</p> */}
              <p className="details-content">
                {reportObject.reviewed} / {reportObject.total}
              </p>
            </IrisGrid>
            {/* <IrisGrid item sm={6} style={{ padding: "1em" }}>
              <p className="details-title">Patrol shift</p>
              <p className="details-content">
                {reportObject.reportShift.time_after} -
                {reportObject.reportShift.time_before}
              </p>
            </IrisGrid> */}
            <IrisGrid item sm={6} style={{ padding: "1em" }}>
              <p className="details-title" style={{ color: "red" }}>
                Collected Defects
              </p>
              <p className="details-content">{reportObject.numOfDefects}</p>
            </IrisGrid>
            <IrisGrid container>
              <p className="subtitle">Defect details</p>
            </IrisGrid>
            {reportObject.defectObjects.length > 0 ? (
              reportObject.defectObjects.map(({ defectName, count }, index) => (
                <IrisGrid item sm={6} style={{ padding: "1em" }} key={index}>
                  <p className="details-title">{defectName}</p>
                  <p className="details-content">{count}</p>
                </IrisGrid>
              ))
            ) : (
              <IrisGrid item sm={6} style={{ padding: "1em" }}>
                <p className="details-title">{NA}</p>
              </IrisGrid>
            )}
            <IrisGrid container>
              <p className="subtitle">Flag details</p>
            </IrisGrid>

            {reportObject.flagObjects.length > 0 ? (
              reportObject.flagObjects.map(({ flagName, count }, index) => (
                <IrisGrid item sm={6} style={{ padding: "1em" }} key={index}>
                  <p className="details-title">{flagName}</p>
                  <p className="details-content">{count}</p>
                </IrisGrid>
              ))
            ) : (
              <IrisGrid item sm={6} style={{ padding: "1em" }}>
                <p className="details-title">{NA}</p>
              </IrisGrid>
            )}
          </IrisGrid>
          <UploadFile {...uploadCSVFileProps} />
          <IrisGrid container alignItems="center">
            <div style={{ margin: "1rem 0.725rem 1rem auto" }}>
              <input
                onChange={(e) => setIsSendReportBtnDisabled(!e.target.checked)}
                id="summary-report-confirm-check-box"
                type="checkbox"
                className="confirm-checkbox"
              />
              <label
                htmlFor="summary-report-confirm-check-box"
                className="confirm-label"
              >
                Ready to send report
              </label>
            </div>
          </IrisGrid>
          <IrisGrid container justifyContent="flex-end">
            <IrisButton onClick={onClose}>Cancel</IrisButton>
            <IrisButton
              style={{ marginLeft: "0.5rem" }}
              onClick={() => {
                console.log(`deviceDetails`, deviceDetails);
                const { label_date, device_id } = deviceDetails;
                getDataPointsWithLabels({
                  // date: label_date,
                  queryTimeRange: reportObject.reportShift,
                  device_id,
                  label_type: "All",
                });
              }}
            >
              Details
            </IrisButton>
            <IrisButton
              disabled={isSendReportBtnDisabled}
              style={{ marginLeft: "0.5rem" }}
              color="secondary"
              onClick={handleSendReport}
              iconProps={{
                size: "small",
                iconTitle: "summarize",
              }}
              size="small"
            >
              Report
            </IrisButton>
          </IrisGrid>
        </div>
      </div>
    </IrisModal>
  );
}
