/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Nov 11, 2021
 *
 * Description: A component that allow user to draw bounding boxes. After a bounding box
 * is drawn, the user is able to select title and rating for it.
 *
 * Bounding boxes in the component could be creaed, resized, and remove.
 *
 * In terms of removing a bounding box, simply select the one about to be deleted, and press
 * delete key on the keyboard.
 *
 * If the user need to updated a bounding boxes' title and rating, he could simply select the target
 * bounding box and hit the enter key, the rating component, if applicable, would be shown to the uer.
 * In addition, the user could toggle the rating selections by pressing number key 1 to 9. For ratings
 * about 10, however, he has to mannually select from the drop down list.
 *
 * Besides, the user is also allow to remove all bounding boxes within this component. With this component selected
 * press "shift + d" to remove or to restore all bounding boxes for the current data point.
 *
 * Note: no API requests would be sent to the server unless the user manually update the currently selected data point,
 * by simpling click on of the list item on the data point list component. Upon doing so, all necessaries API requests
 * would be delivered to the server and the update would be applied on the server.
 *
 * Updated Date: Mar 30, 2021
 * added skew view onto the canvas for pci labels. When those labels are drawn, its' PCI value would be roughly
 * determined by the skew values fetch from the server. Also, the user is allow to change the skew view, simply press
 * shift + c. After the modification is done, press the same combo key to dismiss the skew adjustment view. After that,
 * the modified skew view would be sent to the server.
 */
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { updateSelectedData } from "../../redux/reducers/dataPointsReducer/dataPointActions";
import { setToastMessage } from "../../redux/reducers/globalReducer/globalActions";
import BoundingBoxMenu from "../boundingBoxMenu/BoundingBoxMenu";
import styles from "./boundingBoxCanvas.module.scss";
import { DEVICES } from "../login/localStorageKeys";
import {
  updateDevice,
  getRatingTitle,
} from "../../awsBackend/awsBackendUtils.js";
import {
  getCenter,
  isInsideBoundingBox,
  getPositionsOfPoints,
  getBoundingBoxAreaAndHeight,
  getRectangularBoundingBoxCoordinates,
  getImageSize,
  compareBoundingBoxes,
} from "../../utils/boundingBoxUtils";

let mousePositionMidClick = { x: 0, y: 0 };

const BOUNDING_BOX_LINE_WIDTH = 2;

export const CANVAS_COMPONENT_DOM_ID = "boundingBoxCanvasComponent";

/**
 *
 * @summary get the bounding boxes' upper left and lower right coordinates properly
 *
 * @description this method is called when a bounding box (trapezoid) is newly drawn or
 * resized.
 *
 * As the bounding box would be a trapezoid, its start and end poitns should be the top left and
 * the bottom right point of a rectangle which wraps the trapezoid.
 *
 * For instance, if the trapezoid is
 * top left: [1,1];
 * top right: [1,3];
 * bottom right: [0, 5];
 * bottom left: [0, 0];
 *
 * then the rectangle should be
 * top left: [0, 1];
 * bottom right: [0, 5]
 *
 * this method is used to guaranttee that the start, end, topLeft, bottomRight, and bottmLeft
 * coordinates are defined properly, as they are later on would be send to the server.
 * @param {Object} boundingBox
 */
const getStartEndCoordinates = (boundingBox) => {
  // console.log(`getStartEndCoordinates`, boundingBox);
  const { topLeft, topRight, bottomRight, bottomLeft } = boundingBox;
  const rect = getRectangularBoundingBoxCoordinates([
    topLeft,
    topRight,
    bottomRight,
    bottomLeft,
  ]);
  const [topLeftX, topLeftY] = topLeft;
  const [topRightX, topRightY] = topRight;
  const [bottomRightX, bottomRightY] = bottomRight;
  const [bottomLeftX, bottomLeftY] = bottomLeft;

  const topLeftPoint = { x: Math.round(topLeftX), y: Math.round(topLeftY) };
  const topRightPoint = { x: Math.round(topRightX), y: Math.round(topRightY) };
  const bottomRightPoint = {
    x: Math.round(bottomRightX),
    y: Math.round(bottomRightY),
  };
  const bottomLeftPoint = {
    x: Math.round(bottomLeftX),
    y: Math.round(bottomLeftY),
  };
  const result = getPositionsOfPoints([
    topLeftPoint,
    topRightPoint,
    bottomRightPoint,
    bottomLeftPoint,
  ]);

  const {
    topLeft: newTopLeft,
    topRight: newTopRight,
    bottomRight: newBottomRight,
    bottomLeft: newBottomLeft,
  } = result;

  const a = {
    start: [Math.round(rect.topLeft[0]), Math.round(rect.topLeft[1])],
    end: [Math.round(rect.bottomRight[0]), Math.round(rect.bottomRight[1])],
    topLeft: [newTopLeft.x, newTopLeft.y],
    topRight: [newTopRight.x, newTopRight.y],
    bottomRight: [newBottomRight.x, newBottomRight.y],
    bottomLeft: [newBottomLeft.x, newBottomLeft.y],
  };
  return {
    start: [Math.round(rect.topLeft[0]), Math.round(rect.topLeft[1])],
    end: [Math.round(rect.bottomRight[0]), Math.round(rect.bottomRight[1])],
    topLeft: [newTopLeft.x, newTopLeft.y],
    topRight: [newTopRight.x, newTopRight.y],
    bottomRight: [newBottomRight.x, newBottomRight.y],
    bottomLeft: [newBottomLeft.x, newBottomLeft.y],
  };
};

const isBoundingBoxTooSmall = (box) => {
  const { area, height } = getBoundingBoxAreaAndHeight(box);
  return Math.abs(area) < 100 || Math.abs(height) < 20;
};

const BoundingBoxCanvas = ({
  className,
  imageStyle,
  zoomLevel,
  breakPoint,
  setToastMessage,
  ...restProps
}) => {
  const lensRef = useRef();
  const imageRef = useRef();
  const canvasRef = useRef();
  const clensRef = useRef();
  const menuRef = useRef();

  /**
    record the id for new drawn bounding box, for reference only. 
    This id would be replaced by the one returned from the server after
    it is posted to the server
   */
  const localBoundingBoxIdRef = useRef(0);

  const zoomDetailsPanel = useRef();
  const divRef = useRef();

  const copiedBoundingBoxesRef = useRef([]);
  var stylePaddingLeft,
    stylePaddingTop,
    styleBorderLeft,
    styleBorderTop,
    htmlLeft,
    htmlTop;

  var canvas, context, lenscanvas, lenscontext;
  var imageRelativeWidth = 1280;
  var imageRelativeHeight = 720;
  var dragging = false;
  var resizing = -1;
  var resizing_corner = 0;
  var move = true;
  var firstRender = 10;
  let boudingBoxBeforeResizing = null;

  let timeoutFunction;

  const { data, menu, labelPreference } = restProps;
  let { width: imageWidth, height: imageHeight } = getImageSize(
    data.resolution
  );
  const src = data.image;

  // load the skew adjustment for the device associated with this datapoint
  var current_device = {};
  var skewAdjustment = {};
  var devices = JSON.parse(localStorage.getItem(DEVICES));
  if (devices) {
    current_device = devices.find((device) => {
      return device.device_sn === data.device_sn.device_sn;
    });
    skewAdjustment = current_device.skew_adjust;
  }

  const [popupMenuAnchorEl, setPopupMenuAnchorEl] = React.useState(null);
  const [currentBoundingBox, setCurrentBoundingBox] = useState(null);

  // data point's bounding boxes that would be drawn on the component
  const [boundingBoxes, setBoundingBoxes] = useState([]);

  const [showMagnifier, setShowMagnifier] = useState(false);

  const [skewVisible, setSkewVisible] = useState(false);

  // checks to see if the PCI menu is available
  const verifyMenu = menu.filter((subeMenu) => {
    const { cityId } = subeMenu;
    return cityId.indexOf(data.device_sn.city.id) > -1;
  });
  const hasPciMenu = verifyMenu.find((item) => item.name === "PCI");

  useEffect(() => {
    setBoundingBoxes(JSON.parse(JSON.stringify(data.boundingBoxes)) || []);
  }, [data]);

  useEffect(() => {
    localBoundingBoxIdRef.current = 0;
    copiedBoundingBoxesRef.current = getClearedBoundingBoxes(
      copiedBoundingBoxesRef.current
    );
  }, [src]);

  var selecting = false;

  /**
   * @summary a callback function for the "keyup" event for the root div of this component when is selected
   *
   * @description when this comoponent is selected, see the dive with tabIndex = 1, pressing down the shortcut keys
   * would toggle the magnifier
   *
   * @param {Event} event key event
   */

  const toggleMagnifier = (event) => {
    if (event.shiftKey && event.keyCode === 77) {
      // "M" for "Magnifier"
      const isShowMagnifier = !showMagnifier;
      setShowMagnifier(isShowMagnifier);
      setToastMessage(
        `Magnifier ${isShowMagnifier === true ? "enabled" : "disabled"}`
      );
    }

    // if (event.shiftKey && event.keyCode === 67) {
    //   // "C" for "Calibrate Skew"
    //   const isSkewVisible = !skewVisible;
    //   setSkewVisible(isSkewVisible);
    //   setToastMessage(
    //     `Calibration ${isSkewVisible === true ? "enabled" : "disabled"}`
    //   );
    // }
  };

  useEffect(() => {
    document.addEventListener("keyup", toggleMagnifier);
    return () => {
      document.removeEventListener("keyup", toggleMagnifier);
    };
  });

  /**
   *
   * @description scales a coordinate relative to the
   * imageRelativeWidth and imageWidth. As a result, the coordinate will
   * be responsive even when the window is resized.
   *
   * @see draw
   *
   * @param {Object} coord coordinate object
   */
  const scalePoint = (coord) => {
    return [
      Math.round((coord[0] * imageRelativeWidth) / imageWidth),
      Math.round((coord[1] * imageRelativeHeight) / imageHeight),
    ];
  };

  /**
   * calculate the surface area of a bounding box, adjusted for camera skew
   */

  //  bottom_distance: 10000
  //  bottom_left: (2) [389, 670]
  //  bottom_right: (2) [940, 670]
  //  bottom_width: 3500
  //  top_distance: 20000
  //  top_left: (2) [514, 473]
  //  top_right: (2) [846, 473]
  //  top_width: 3500
  // const surfaceArea = (bb) => {
  //   const rect = getRectangularBoundingBoxCoordinates([
  //     bb.topLeft,
  //     bb.topRight,
  //     bb.bottomRight,
  //     bb.bottomLeft,
  //   ]);

  //   const sbly = skewAdjustment.bottom_left[1];
  //   const stly = skewAdjustment.top_left[1];
  //   const sbrx = skewAdjustment.bottom_right[0];
  //   const sblx = skewAdjustment.bottom_left[0];
  //   const strx = skewAdjustment.top_right[0];
  //   const stlx = skewAdjustment.top_left[0];
  //   debugger;
  //   // let bottom = parseFloat(bb.end[1] > bb.start[1] ? bb.end[1] : bb.start[1]);
  //   // let top = parseFloat(bb.start[1] < bb.end[1] ? bb.start[1] : bb.end[1]);
  //   let bottom = parseFloat(rect.bottomRight[1]);
  //   let top = parseFloat(rect.topLeft[1]);

  //   let pixel_distance = bottom;
  //   // let pixel_width_bottom = Math.abs(bb.end[0] - bb.start[0]);
  //   let pixel_width_bottom = Math.abs(rect.topLeft[0] - rect.bottomRight[0]);

  //   // slope and intercept of width to height of skew box
  //   let m = parseFloat(sbly - stly);
  //   m = m / (sbrx - sblx - (strx - stlx));
  //   let b = parseFloat(stly) - m * parseFloat(strx - stlx);

  //   let skew_width_bottom = (bottom - b) / m;
  //   let true_width =
  //     (parseFloat(pixel_width_bottom) / parseFloat(skew_width_bottom)) *
  //     parseFloat(skewAdjustment.bottom_width);

  //   m = parseFloat(sbly - stly);
  //   m = m / (skewAdjustment.bottom_distance - skewAdjustment.top_distance);
  //   b = parseFloat(sbly) - m * parseFloat(skewAdjustment.bottom_distance);

  //   let true_distance_bottom = (bottom - b) / m;
  //   let true_distance_top = (top - b) / m;

  //   let true_height = true_distance_top - true_distance_bottom;

  //   let length = Math.sqrt(Math.pow(true_width, 2) + Math.pow(true_height, 2));
  //   let surface_area = true_height * true_width;

  //   return { surface_area: surface_area, length: length };
  // };
  const surfaceArea = (bb) => {
    const rect = getRectangularBoundingBoxCoordinates([
      bb.topLeft,
      bb.topRight,
      bb.bottomRight,
      bb.bottomLeft,
    ]);

    /**
     * trapezoid to rectangular ratio
     */
    let ratio = 1;
    const { area: boxArea } = getBoundingBoxAreaAndHeight(bb);
    const rectArea =
      (rect.bottomRight[0] - rect.topLeft[0]) *
      (rect.bottomRight[1] - rect.topLeft[1]);

    const tempRatio = boxArea / rectArea;
    if (!isNaN(tempRatio) && tempRatio <= 1) {
      ratio = tempRatio;
    }

    // let bottom = parseFloat(bb.end[1] > bb.start[1] ? bb.end[1] : bb.start[1]);
    // let top = parseFloat(bb.start[1] < bb.end[1] ? bb.start[1] : bb.end[1]);
    let bottom = parseFloat(rect.bottomRight[1]);
    let top = parseFloat(rect.topLeft[1]);

    let pixel_distance = bottom;
    // let pixel_width_bottom = Math.abs(bb.end[0] - bb.start[0]);
    let pixel_width_bottom = Math.abs(rect.topLeft[0] - rect.bottomRight[0]);

    // slope and intercept of width to height of skew box
    let m = parseFloat(
      skewAdjustment.bottom_left[1] - skewAdjustment.top_left[1]
    );
    m =
      m /
      (skewAdjustment.bottom_right[0] -
        skewAdjustment.bottom_left[0] -
        (skewAdjustment.top_right[0] - skewAdjustment.top_left[0]));
    let b =
      parseFloat(skewAdjustment.top_left[1]) -
      m * parseFloat(skewAdjustment.top_right[0] - skewAdjustment.top_left[0]);

    let skew_width_bottom = (bottom - b) / m;
    let true_width =
      (parseFloat(pixel_width_bottom) / parseFloat(skew_width_bottom)) *
      parseFloat(skewAdjustment.bottom_width);

    m = parseFloat(skewAdjustment.bottom_left[1] - skewAdjustment.top_left[1]);
    m = m / (skewAdjustment.bottom_distance - skewAdjustment.top_distance);
    b =
      parseFloat(skewAdjustment.bottom_left[1]) -
      m * parseFloat(skewAdjustment.bottom_distance);

    let true_distance_bottom = (bottom - b) / m;
    let true_distance_top = (top - b) / m;

    let true_height = true_distance_top - true_distance_bottom;

    let length = Math.sqrt(Math.pow(true_width, 2) + Math.pow(true_height, 2));
    let surface_area = true_height * true_width * ratio;

    return { surface_area: Math.abs(surface_area), length: Math.abs(length) };
  };

  const draw = () => {
    if (showMagnifier) {
      context.drawImage(
        imageRef.current,
        0,
        0,
        imageRelativeWidth,
        imageRelativeHeight
      );
    }

    // TODO double check this line, works with
    // or without it
    context.beginPath();
    let pos = 40;

    /*================================================== draw data info on upper left corner START ================================================== */
    // draw a dark rect on the upper left corner
    // where displays some info about a data point
    context.fillStyle = "#00000099";
    context.fillRect(5, 5, 350, 105);

    // draw data point's info
    context.font = "1.125rem Arial"; // font size and font family
    context.fillStyle = "#ffffff"; // font color
    context.fillText(`device_sn: ${data.device_sn.device_sn}`, 15, 25);
    context.fillText(`device_tag: ${data.device_sn.tag}`, 15, 45);
    context.fillText(`image_id: ${data.id}`, 15, 65);
    context.fillText(`location: ${data.latitude}, ${data.longitude}`, 15, 85);
    context.fillText(`resolution: ${data.resolution}`, 15, 105);
    /*================================================== draw data info on upper left corner END   ================================================== */

    /*================================================== draw all but not the selected bounding boxes START ================================================== */
    // style for bounding boxes
    const red = "#ff0000";
    context.strokeStyle = "red";
    context.lineWidth = BOUNDING_BOX_LINE_WIDTH;

    let userSelectedBoundingBox;
    for (let i = 0; i < boundingBoxes.length; i++) {
      let bb = boundingBoxes[i];

      // if (bb.id < 0) {
      //   continue;
      // }

      if (bb.id === data.selectedBoundingBox) {
        userSelectedBoundingBox = bb;
      }
      if (
        bb.end[0] != null
        // && bb.id !== data.selectedBoundingBox
      ) {
        drawBoundingBox(context, bb);
        pos += 20;
      }
    }

    // draw the lines
    context.stroke();
    /*==================================================  draw all but not the selected bounding boxes END  ================================================== */

    /*================================================== draw the user selected bounding box START ================================================== */
    if (userSelectedBoundingBox) {
      // new strok color
      const yellow = "#FF8C00";

      context.strokeStyle = yellow;
      context.lineWidth = 2 * BOUNDING_BOX_LINE_WIDTH;

      drawBoundingBox(context, userSelectedBoundingBox);
    }

    if (skewVisible) {
      let scaledSkew = {
        top_left: scalePoint(skewAdjustment.top_left),
        top_right: scalePoint(skewAdjustment.top_right),
        bottom_left: scalePoint(skewAdjustment.bottom_left),
        bottom_right: scalePoint(skewAdjustment.bottom_right),
        topLineRight: scalePoint([imageWidth, skewAdjustment.top_left[1]]),
        bottomLineRight: scalePoint([
          imageWidth,
          skewAdjustment.bottom_left[1],
        ]),
        measure1Height: scalePoint([50, imageHeight]),
        measure2Height: scalePoint([imageWidth - 50, imageHeight]),
      };

      context.beginPath();
      context.strokeStyle = "#00ff00";
      context.font = "12px Arial";
      context.fillStyle = "#ffffff";
      context.lineWidth = 1;
      context.setLineDash([5, 3]);
      context.moveTo(0, scaledSkew.topLineRight[1]);
      context.lineTo(scaledSkew.top_left[0], scaledSkew.topLineRight[1]);
      context.moveTo(scaledSkew.top_right[0], scaledSkew.bottomLineRight[1]);
      context.lineTo(
        scaledSkew.bottomLineRight[0],
        scaledSkew.bottomLineRight[1]
      );

      context.moveTo(scaledSkew.measure1Height[0], scaledSkew.topLineRight[1]);
      context.lineTo(
        scaledSkew.measure1Height[0],
        scaledSkew.measure1Height[1]
      );
      context.fillText(
        `${skewAdjustment.top_distance}mm`,
        scaledSkew.measure1Height[0] + 4,
        Math.round(
          (scaledSkew.measure1Height[1] + scaledSkew.topLineRight[1]) / 2
        )
      );

      context.moveTo(
        scaledSkew.measure2Height[0],
        scaledSkew.bottomLineRight[1]
      );
      context.lineTo(
        scaledSkew.measure2Height[0],
        scaledSkew.measure2Height[1]
      );
      context.fillText(
        `${skewAdjustment.bottom_distance}mm`,
        scaledSkew.measure2Height[0] - 28,
        Math.round(
          (scaledSkew.measure2Height[1] + scaledSkew.bottomLineRight[1]) / 2
        )
      );

      context.stroke();

      context.beginPath();
      context.strokeStyle = "#00ff00";
      context.lineWidth = BOUNDING_BOX_LINE_WIDTH;
      context.setLineDash([0, 0]);
      context.moveTo(scaledSkew.top_left[0], scaledSkew.top_left[1]);
      context.lineTo(scaledSkew.top_right[0], scaledSkew.top_right[1]);
      context.lineTo(scaledSkew.bottom_right[0], scaledSkew.bottom_right[1]);
      context.lineTo(scaledSkew.bottom_left[0], scaledSkew.bottom_left[1]);
      context.lineTo(scaledSkew.top_left[0], scaledSkew.top_left[1]);

      context.fillRect(
        scaledSkew.top_left[0] - 3,
        scaledSkew.top_left[1] - 3,
        7,
        7
      );
      context.fillRect(
        scaledSkew.top_right[0] - 3,
        scaledSkew.top_right[1] - 3,
        7,
        7
      );
      context.fillRect(
        scaledSkew.bottom_right[0] - 3,
        scaledSkew.bottom_right[1] - 3,
        7,
        7
      );
      context.fillRect(
        scaledSkew.bottom_left[0] - 3,
        scaledSkew.bottom_left[1] - 3,
        7,
        7
      );

      context.fillText(
        `${skewAdjustment.top_width}mm`,
        Math.round((scaledSkew.top_left[0] + scaledSkew.top_right[0]) / 2),
        scaledSkew.top_left[1] + 13
      );
      context.fillText(
        `${skewAdjustment.bottom_width}mm`,
        Math.round(
          (scaledSkew.bottom_left[0] + scaledSkew.bottom_right[0]) / 2
        ),
        scaledSkew.bottom_left[1] + 15
      );

      context.stroke();
    } else {
      // if the PCI menu is available, show the PCI grid lines
      if (hasPciMenu) {
        let scaledSkew = {
          top_left: scalePoint(skewAdjustment.top_left),
          top_right: scalePoint(skewAdjustment.top_right),
          bottom_left: scalePoint(skewAdjustment.bottom_left),
          bottom_right: scalePoint(skewAdjustment.bottom_right),
          topLineRight: scalePoint([imageWidth, skewAdjustment.top_left[1]]),
          bottomLineRight: scalePoint([
            imageWidth,
            skewAdjustment.bottom_left[1],
          ]),
          measure1Height: scalePoint([50, imageHeight]),
          measure2Height: scalePoint([imageWidth - 50, imageHeight]),
        };

        context.beginPath();
        context.strokeStyle = "#00ff00BB";
        context.lineWidth = 1;
        context.setLineDash([5, 3]);
        context.moveTo(0, scaledSkew.top_left[1]);
        context.lineTo(imageWidth, scaledSkew.top_right[1]);
        context.moveTo(0, scaledSkew.bottom_left[1]);
        context.lineTo(imageWidth, scaledSkew.bottom_right[1]);

        context.font = "14px Arial";
        context.fillStyle = "#ffffffBB";
        context.fillText(
          `PCI range (label only 1 lane wide = 3.5m)`,
          5,
          scaledSkew.bottom_right[1] + 15
        );

        context.stroke();
      }
    }
    /*================================================== draw the canvas' content on the magnifier START ================================================== */
    // copy canvas onto lens canvas
    if (
      showMagnifier &&
      mousePositionMidClick.x > 0 &&
      mousePositionMidClick.x < imageRelativeWidth &&
      mousePositionMidClick.y > 0 &&
      mousePositionMidClick.y < imageRelativeHeight
    ) {
      lenscontext.clearRect(0, 0, 400, 300);

      lenscontext.drawImage(
        canvasRef.current,
        mousePositionMidClick.x - 100,
        mousePositionMidClick.y - 75,
        200,
        150,
        0,
        0,
        400,
        300
      );
    }
    /*==================================================  draw the canvas' content on the magnifier END  ================================================== */
  };

  /**
   * @summary draw a bounding box on the canvas
   *
   * @param context The canvas' context
   * @param boundingBox bounding box to be drawn
   */
  const drawBoundingBox = (context, boundingBox) => {
    // console.log(`resized target last bb drawn`, boundingBox);
    context.beginPath();

    const { topLeft, topRight, bottomRight, bottomLeft } = boundingBox;

    const scaledTopLeft = scalePoint(topLeft);
    const scaledTopRight = scalePoint(topRight);
    const scaledBottomRight = scalePoint(bottomRight);
    const scaledBottomLeft = scalePoint(bottomLeft);

    context.moveTo(scaledTopLeft[0], scaledTopLeft[1]);
    context.lineTo(scaledTopRight[0], scaledTopRight[1]);
    context.lineTo(scaledBottomRight[0], scaledBottomRight[1]);
    context.lineTo(scaledBottomLeft[0], scaledBottomLeft[1]);
    context.lineTo(scaledTopLeft[0], scaledTopLeft[1]);

    // the bounding boxes' corners
    context.fillStyle = "#ffffff";
    context.fillRect(scaledTopLeft[0] - 2, scaledTopLeft[1] - 2, 5, 5);
    context.fillRect(scaledTopRight[0] - 2, scaledTopRight[1] - 2, 5, 5);
    context.fillRect(scaledBottomRight[0] - 2, scaledBottomRight[1] - 2, 5, 5);
    context.fillRect(scaledBottomLeft[0] - 2, scaledBottomLeft[1] - 2, 5, 5);

    // mark the center of the bounding box
    context.font = "11px Arial";
    const center = getCenter(boundingBox);
    const scaledCenter = scalePoint(center);
    let leftMostXPos = Math.min(scaledTopLeft[0], scaledBottomLeft[0]);
    let rightMostXPos = Math.max(scaledTopRight[0], scaledBottomRight[0]);
    context.fillStyle = "#ff0000";
    context.fillText(`X`, scaledCenter[0] - 4, scaledCenter[1] + 4);

    // draw the strok for the selected bounding box
    context.stroke();
  };

  /**
   * @description get the mouse position relative to the canvas component
   *
   * @param {Event} e
   */
  function getMouse(e) {
    var element = canvas,
      offsetX = 0,
      offsetY = 0,
      mx,
      my;

    // compute the total offset
    if (element && element.offsetParent !== undefined) {
      do {
        offsetX += element.offsetLeft;
        offsetY += element.offsetTop;
      } while ((element = element.offsetParent));
    }

    // add padding and border style widths to offset
    offsetX += stylePaddingLeft + styleBorderLeft + htmlLeft;
    offsetY += stylePaddingTop + styleBorderTop + htmlTop;

    // final x and y
    mx = e.pageX - offsetX;
    my = e.pageY - offsetY;

    // if the x or y value is too small ( < 0 ), constain it to 0,
    // or too large (greater than the width or height or the image's width or)
    // constain it to the height or width value
    mx = Math.max(mx, 0);
    mx = Math.min(mx, imageWidth);

    my = Math.max(my, 0);
    my = Math.min(my, imageHeight);
    // return coordinate object
    return { x: mx, y: my };
  }

  useEffect(() => {
    // animation frame loop
    let animationFrameId;

    canvas = canvasRef.current;

    // use to draw graphic on the fly
    context = canvas.getContext("2d");

    if (showMagnifier) {
      lenscanvas = clensRef.current;
      lenscontext = lenscanvas.getContext("2d");
      lenscanvas.width = 400;
      lenscanvas.height = 300;
    }

    // initial mouse clicking parameters
    stylePaddingLeft =
      parseInt(
        document.defaultView.getComputedStyle(canvas, null)["paddingLeft"],
        10
      ) || 0;
    stylePaddingTop =
      parseInt(
        document.defaultView.getComputedStyle(canvas, null)["paddingTop"],
        10
      ) || 0;
    styleBorderLeft =
      parseInt(
        document.defaultView.getComputedStyle(canvas, null)["borderLeftWidth"],
        10
      ) || 0;
    styleBorderTop =
      parseInt(
        document.defaultView.getComputedStyle(canvas, null)["borderTopWidth"],
        10
      ) || 0;
    // Some pages have fixed-position bars (like the stumbleupon bar) at the top or left of the page
    // They will mess up mouse coordinates and this fixes that
    var html = document.body.parentNode;
    htmlTop = html.offsetTop;
    htmlLeft = html.offsetLeft;

    const render = () => {
      // only render the animation loop when the mouse is moving

      if (move || firstRender > 0) {
        // adjust canvas dimensions
        if (divRef.current.offsetWidth != 0) {
          imageRelativeWidth = divRef.current.offsetWidth;
          imageRelativeHeight = (imageRelativeWidth / imageWidth) * imageHeight;
          divRef.current.height = imageRelativeHeight;
          canvasRef.current.style.height = imageRelativeHeight;
          canvas.width = imageRelativeWidth;
          canvas.height = imageRelativeHeight;
        }

        // draw the canvas image
        draw();

        // reset the mouse moving flag
        move = false;

        // after countdown reaches zero we can stop refreshing every frame (only on move)
        if (firstRender > 0) {
          firstRender--;
        }
      }
      animationFrameId = window.requestAnimationFrame(render);
    };

    // start the rendering
    render();
  }, [draw, boundingBoxes, showMagnifier, canvasRef]);

  /**
   *
   * @description when the mouse move around on the canvas and the magnifier is
   * shown, center the magnifer's center to the mouse position and move it along with
   * the mouse movement
   *
   * @see midClick
   * @param {Event} event
   */
  const moveMagnifier = (event) => {
    event.preventDefault();
    const image = canvasRef.current;
    const lens = lensRef.current;
    const imageHeight = image.height + 150;
    const imageWidth = image.width + 200;
    //lens.style.backgroundImage = `url(${src})`;
    lens.style.backgroundRepeat = "no-repeat";
    // lens.style.backgroundSize = `${image.offsetWidth * zoomLevel}px ${
    //   image.offsetHeight * zoomLevel
    // }px`;
    // const pos = getCursorPos(event, image);
    const pos = getMouse(event);
    /*calculate the position of the lens:*/

    let lensHalfWidth = lens.offsetWidth / 2;
    let lensHalfHeigh = lens.offsetHeight / 2;
    let cursorX = pos.x;
    let cursorY = pos.y;

    // console.log(`corsorX, cursorY`, cursorX, cursorY);
    /*prevent the lens from being positioned outside the image:*/
    // cursor leaving frame on right
    // console.log("imageWidth:", imageWidth);
    // console.log("zoomeLevel: ", zoomLevel);

    if (cursorX > imageWidth - lensHalfWidth / zoomLevel) {
      // cursorX = imageWidth - lensHalfWidth / zoomLevel;
      // console.log("cursorX: ", cursorX);
    }
    // cursor leaving frame on left
    if (cursorX < lensHalfWidth / zoomLevel) {
      // cursorX = lensHalfWidth / zoomLevel;
    }

    if (cursorY > imageHeight - lensHalfHeigh / zoomLevel) {
      // cursorY = imageHeight - lensHalfHeigh / zoomLevel;
    }
    if (cursorY < lensHalfHeigh / zoomLevel) {
      // cursorY = lensHalfHeigh / zoomLevel;
    }
    /*set the position of the lens:*/
    if (
      cursorX <= 0 ||
      cursorX > imageRelativeWidth ||
      cursorY <= 0 ||
      cursorY > imageRelativeHeight
    ) {
      return;
    }
    let left = cursorX - lensHalfWidth;
    let top = cursorY - lensHalfHeigh;
    // the len moves awary from the left
    if (cursorX - lensHalfWidth < 0) {
      // left = 0;
    }
    // the lens movse away from the right
    else if (cursorX > imageWidth - lensHalfWidth) {
      // left = imageWidth - 2 * lensHalfWidth;
    }

    // the len moves away from the top
    if (cursorY - lensHalfHeigh < 0) {
      // top = 0;
    }
    // the len movse away from the bottom
    else if (cursorY > imageHeight - lensHalfHeigh) {
      // top = imageHeight - 2 * lensHalfHeigh;
    }
    lens.style.left = left + "px";
    lens.style.top = top + "px";
    /*display what the lens "sees":*/
    // lens.style.backgroundPosition =
    //   "-" +
    //   (cursorX * zoomLevel - lensHalfWidth) +
    //   "px -" +
    //   (cursorY * zoomLevel - lensHalfHeigh) +
    //   "px";
  };

  /*================================================== methods for hover zoom ================================================== */
  const showZoomDetails = (isShow) => {
    if (lensRef && lensRef.current) {
      lensRef.current.style.display = isShow ? "block" : "none";
      lensRef.current.style.border = isShow ? "1px solid #d4d4d4" : "none";
    }

    if (zoomDetailsPanel && zoomDetailsPanel.current) {
      zoomDetailsPanel.current.style.display = isShow ? "block" : "none";
    }
  };

  /**
   * @summary a callback fucnton pass to the property "onMouseMove", for the
   * div where the canvas is contained
   *
   * @description when a mouse is clicked
   * 1. if the user clicked in area where there is no bounding box dragged the clicked mouse, a new bounding box should be shown
   *
   * 2. if the user clicked on one of the 4 corners of an extied bounding box and dragged, the bouding
   *    box should be resized on that corner
   *
   * 3. if ther user clicked within the area of an existed bounding box, that bounding box should be
   *    highlighted
   *
   *    NOTE: if there was a new bounding box drawn, then the popu menu is shown above an existing bounding box,
   *          try not to selected that bounding box cause it case the canva to zraw and the app crash.listComponent
   *
   *    TODO: have to find another place for
   *                  onMouseMove={(e) => midClick(e)},
   *                  onMouseDown={(e) => startClick(e)}
   *                  onMouseUp={(e) => endClick(e)}
   *          instead of placing it on the root div. Have tried to put it on the <canvas> element, but the only issues
   *          is the the magnifier did not work properly.
   *
   * @param {Event} e mouse click event
   */
  const startClick = (e) => {
    if (popupMenuAnchorEl) {
      // if pop up menu is shown, not listen to this event
      // TODO: event.stopPropagation is not working on the popup menu element
      // as a reuslt, without this block, user is still able to drag and draw while
      // a popu menu is shown
      return;
    }
    let clickedBoundingBoxId = null;
    let pos = getMouse(e);
    for (const box of boundingBoxes) {
      if (
        // box.scaledMatrix &&
        box.start &&
        box.end &&
        (box.id > 0 || typeof box.id === "string")
      ) {
        const boxCenter = getCenter(box);

        const sTopLeft = scalePoint(box.topLeft);
        const sTopRight = scalePoint(box.topRight);
        const sBottomRight = scalePoint(box.bottomRight);
        const sBottomLeft = scalePoint(box.bottomLeft);

        // a fraction makes "clickInside" is less restrict
        // other wise, when a resizing corner is click, it would
        // be consider as clickedInside the bounding box so that
        // resizing would not be possible
        const fraction = 10;
        const isClickedInside = isInsideBoundingBox(
          {
            topLeft: { x: sTopLeft[0] + fraction, y: sTopLeft[1] + fraction },
            topRight: {
              x: sTopRight[0] - fraction,
              y: sTopRight[1] + fraction,
            },
            bottomRight: {
              x: sBottomRight[0] - fraction,
              y: sBottomRight[1] - fraction,
            },
            bottomLeft: {
              x: sBottomLeft[0] + fraction,
              y: sBottomLeft[1] - fraction,
            },
          },
          pos
        );

        const x = Math.round((pos.x / imageRelativeWidth) * imageWidth);
        const y = Math.round((pos.y / imageRelativeWidth) * imageWidth);
        const clickCenter =
          Math.abs(x - boxCenter[0]) < 5 && Math.abs(y - boxCenter[1]) < 5;
        // if (x > x1 && x < x2 && y > y1 && y < y2 && !clickCenter) {
        if (!clickCenter && isClickedInside) {
          clickedBoundingBoxId = box.id;
        }
      }
    }
    if (!clickedBoundingBoxId && data?.selectedBoundingBox) {
      restProps.updateSelectedData({
        ...data,
        selectedBoundingBox: null,
      });
    }
    if (clickedBoundingBoxId && popupMenuAnchorEl === null) {
      restProps.updateSelectedData({
        ...data,
        selectedBoundingBox: clickedBoundingBoxId,
      });
    } else if (!selecting) {
      let startPos = getMouse(e);
      let lensposx = 0;
      let lensposy = 0;
      if (showMagnifier) {
        // lensposx = parseInt(lensRef.current.style.left);
        // lensposy = parseInt(lensRef.current.style.top);
        // startPos.x = (lensposx + startPos.x) / 2;
        // startPos.y = (lensposy + startPos.y) / 2;
      }
      startPos.x = (startPos.x * imageWidth) / imageRelativeWidth;
      startPos.y = (startPos.y * imageHeight) / imageRelativeHeight;

      // console.log(`startPos`, startPos);
      resizing = -1;
      let index = -1;
      let del = false;
      // replace the loop came next to this snippet
      // so that only one comfirmation pop up will
      // be shown even though two "x" are so close that
      // one click could trigger both "x"
      if (skewVisible) {
        // check for click on skew box
        if (
          Math.abs(startPos.x - skewAdjustment.top_left[0]) < 5 &&
          Math.abs(startPos.y - skewAdjustment.top_left[1]) < 5
        ) {
          resizing = 1;
          resizing_corner = 0;
        } else if (
          Math.abs(startPos.x - skewAdjustment.top_right[0]) < 5 &&
          Math.abs(startPos.y - skewAdjustment.top_right[1]) < 5
        ) {
          resizing = 1;
          resizing_corner = 1;
        } else if (
          Math.abs(startPos.x - skewAdjustment.bottom_left[0]) < 5 &&
          Math.abs(startPos.y - skewAdjustment.bottom_left[1]) < 5
        ) {
          resizing = 1;
          resizing_corner = 2;
        } else if (
          Math.abs(startPos.x - skewAdjustment.bottom_right[0]) < 5 &&
          Math.abs(startPos.y - skewAdjustment.bottom_right[1]) < 5
        ) {
          resizing = 1;
          resizing_corner = 3;
        }
      } else {
        for (let i = 0; i < boundingBoxes.length; i++) {
          index++;
          const bb = boundingBoxes[i];

          if (
            Math.abs(startPos.x - bb.topLeft[0]) < 5 &&
            Math.abs(startPos.y - bb.topLeft[1]) < 5
          ) {
            resizing = index;
            boudingBoxBeforeResizing = { ...bb };
            resizing_corner = 0;
            // alert(resizing_corner)
            // console.log(`startPos, bb.bottomRight`, startPos, bb.bottomRight);
          } else if (
            Math.abs(startPos.x - bb.topRight[0]) < 5 &&
            Math.abs(startPos.y - bb.topRight[1]) < 5
          ) {
            resizing = index;
            boudingBoxBeforeResizing = { ...bb };
            resizing_corner = 1;
            // alert(resizing_corner)
            // console.log(`startPos, bb.bottomRight`, startPos, bb.bottomRight);
          } else if (
            Math.abs(startPos.x - bb.bottomLeft[0]) < 5 &&
            Math.abs(startPos.y - bb.bottomLeft[1]) < 5
          ) {
            resizing = index;
            boudingBoxBeforeResizing = { ...bb };
            resizing_corner = 3;
            // alert(resizing_corner)
            // console.log(`startPos, bb.bottomRight`, startPos, bb.bottomRight);
          } else if (
            Math.abs(startPos.x - bb.bottomRight[0]) < 5 &&
            Math.abs(startPos.y - bb.bottomRight[1]) < 5
          ) {
            resizing = index;
            boudingBoxBeforeResizing = { ...bb };
            resizing_corner = 2;
            // alert(resizing_corner)
            // console.log(`startPos, bb.bottomRight`, startPos, bb.bottomRight);
          } else if (
            Math.abs(startPos.x - getCenter(bb)[0]) < 5 &&
            Math.abs(startPos.y - getCenter(bb)[1]) < 5
          ) {
            del = true;
            removeBoundingBoxes(JSON.parse(JSON.stringify(bb)));
            break;
          }
        }
      }

      if (!skewVisible) {
        if (!del) {
          if (resizing >= 0) {
            // resizing
          } else {
            dragging = true;
            boundingBoxes.push({
              start: [startPos.x, startPos.y],
              topLeft: [startPos.x, startPos.y],
              bottomLeft: [startPos.x, null],
              end: [null, null],
              topRight: [null, startPos.y],
              bottomRight: [null, null],
              type: "",
            });
          }
        }
      }
    }
  };

  /**
   * @summary a callback fucnton pass to the property "onMouseMove", for the
   * div where the canvas is contained
   *
   * @description When the mouse is dragging after being clicked
   *  1. if the mouse clicked on somewhere that has no exited boundding box and drags
   *     then a new bounding box show be drawn from there and enlargedd while the mouse drags
   *
   *  2. if the mouse clickedd on one of the 4 corners of an existed bounding box and drags,
   *     then the exiited bounding box shoud be reszied on that corner while the mouse is dragging
   *
   * @param {Event} e mouse event while it is clicked down and dragging
   */
  const midClick = (e) => {
    move = true;

    if (!selecting) {
      if (showMagnifier) {
        moveMagnifier(e);
      }
      var endPos = getMouse(e);
      // update this value so that when the magnifier is shown
      // content of the cavas would be drawn on it properly
      // otherwise, the magnifier has no effect but looks like a
      // frame
      if (endPos.x > imageRef.current.width) endPos.x = imageRef.current.width;
      if (endPos.y > imageRef.current.height)
        endPos.y = imageRef.current.height;

      mousePositionMidClick = { x: endPos.x, y: endPos.y };
      // let lensposx = 0;
      // let lensposy = 0;
      // if (showMagnifier) {
      // lensposx = parseInt(lensRef.current.style.left);
      // lensposy = parseInt(lensRef.current.style.top);
      // endPos.x = (lensposx + endPos.x) / 2;
      // endPos.y = (lensposy + endPos.y) / 2;
      // }
      endPos.x = (endPos.x * imageWidth) / imageRelativeWidth;
      endPos.y = (endPos.y * imageHeight) / imageRelativeHeight;
      if (skewVisible && resizing > 0) {
        // resizing skew
        switch (resizing_corner) {
          case 0:
            skewAdjustment.top_left = [endPos.x, endPos.y];
            skewAdjustment.top_right = [skewAdjustment.top_right[0], endPos.y];
            break;
          case 1:
            skewAdjustment.top_right = [endPos.x, endPos.y];
            skewAdjustment.top_left = [skewAdjustment.top_left[0], endPos.y];
            break;
          case 2:
            skewAdjustment.bottom_left = [endPos.x, endPos.y];
            skewAdjustment.bottom_right = [
              skewAdjustment.bottom_right[0],
              endPos.y,
            ];
            break;
          case 3:
            skewAdjustment.bottom_right = [endPos.x, endPos.y];
            skewAdjustment.bottom_left = [
              skewAdjustment.bottom_left[0],
              endPos.y,
            ];
            break;
          default:
            break;
        }
      } else if (dragging) {
        boundingBoxes[boundingBoxes.length - 1].end = [endPos.x, endPos.y];
        boundingBoxes[boundingBoxes.length - 1].bottomRight = [
          endPos.x,
          endPos.y,
        ];
        boundingBoxes[boundingBoxes.length - 1].bottomLeft[1] = endPos.y;
        boundingBoxes[boundingBoxes.length - 1].topRight[0] = endPos.x;
      } else if (resizing >= 0) {
        // resizing bounding box
        const target = boundingBoxes[resizing];

        // switch (resizing_corner) {
        //   case 0: {
        //     target.topLeft = [endPos.x, endPos.y];
        //     target.topRight = [target.topRight[0], endPos.y];

        //     const topLeftX = target.topLeft[0];
        //     const topRightX = target.topRight[0];

        //     if (topRightX - topLeftX < 10) {
        //       target.topLeft[0] = topRightX - 10;
        //       target.topRight[0] = topRightX;
        //     }

        //     break;
        //   }
        //   case 1: {
        //     target.topRight = [endPos.x, endPos.y];
        //     target.topLeft = [target.topLeft[0], endPos.y];

        //     const topLeftX = target.topLeft[0];
        //     const topRightX = target.topRight[0];

        //     if (topLeftX + 10 > topRightX) {
        //       target.topLeft[0] = topLeftX;
        //       target.topRight[0] = topLeftX + 10;
        //     }
        //     break;
        //   }
        //   case 3: {
        //     target.bottomLeft = [endPos.x, endPos.y];
        //     target.bottomRight = [target.bottomRight[0], endPos.y];

        //     const bottomLeftX = target.bottomLeft[0];
        //     const bottomRightX = target.bottomRight[0];
        //     if (bottomLeftX + 10 > bottomRightX) {
        //       target.bottomLeft[0] = bottomRightX - 10;
        //       target.bottomRight[0] = bottomRightX;
        //     }
        //     break;
        //   }

        //   case 2:
        //     {
        //       target.bottomRight = [endPos.x, endPos.y];
        //       target.bottomLeft = [target.bottomLeft[0], endPos.y];

        //       const bottomLeftX = target.bottomLeft[0];
        //       const bottomRightX = target.bottomRight[0];
        //       if (bottomLeftX + 10 > bottomRightX) {
        //         target.bottomLeft[0] = bottomLeftX;
        //         target.bottomRight[0] = bottomLeftX + 10;
        //       }
        //     }
        //     break;
        // }

        const { x: mouseX, y: mouseY } = endPos;
        switch (resizing_corner) {
          case 0: {
            target.topLeft = [mouseX, mouseY];
            target.topRight = [target.topRight[0], mouseY];

            if (!target.isPolygon) {
              target.bottomLeft = [mouseX, target.bottomLeft[1]];
            } else {
              const topLeftX = target.topLeft[0];
              const topRightX = target.topRight[0];

              if (topRightX - topLeftX < 10) {
                target.topLeft[0] = topRightX - 10;
                target.topRight[0] = topRightX;
              }
            }

            break;
          }
          case 1: {
            target.topRight = [mouseX, mouseY];
            target.topLeft = [target.topLeft[0], mouseY];

            if (!target.isPolygon) {
              target.bottomRight = [mouseX, target.bottomRight[1]];
            } else {
              const topLeftX = target.topLeft[0];
              const topRightX = target.topRight[0];

              if (topLeftX + 10 > topRightX) {
                target.topLeft[0] = topLeftX;
                target.topRight[0] = topLeftX + 10;
              }
            }
            break;
          }
          case 3: {
            target.bottomLeft = [mouseX, mouseY];
            target.bottomRight = [target.bottomRight[0], mouseY];

            if (!target.isPolygon) {
              target.topLeft = [mouseX, target.topLeft[1]];
            } else {
              const bottomLeftX = target.bottomLeft[0];
              const bottomRightX = target.bottomRight[0];
              if (bottomLeftX + 10 > bottomRightX) {
                target.bottomLeft[0] = bottomRightX - 10;
                target.bottomRight[0] = bottomRightX;
              }
            }
            break;
          }

          case 2: {
            target.bottomRight = [mouseX, mouseY];
            target.bottomLeft = [target.bottomLeft[0], mouseY];

            if (!target.isPolygon) {
              target.topRight = [mouseX, target.topRight[1]];
            } else {
              const bottomLeftX = target.bottomLeft[0];
              const bottomRightX = target.bottomRight[0];
              if (bottomLeftX + 10 > bottomRightX) {
                target.bottomLeft[0] = bottomLeftX;
                target.bottomRight[0] = bottomLeftX + 10;
              }
            }
            break;
          }

          default:
            break;
        }
      }
    }
  };

  /**
   * @summary a callback fucnton pass to the property "onMouseUp", for the
   * div where the canvas is contained
   *
   * @description when a mouse is released after click,
   * 1. if a new bounding box was drawn and its size is big enough, show the menu compoent for user to pick a menu (label) for this bounding box
   * 2. if an existed bounding box was resized
   *    if the resized bounding box was too small, show the bounding box before resized instead
   *    if the resized bounding box was big enough, update the bounding box locally and send new udpates to the server
   *
   *
   * @param {Event} e mouse event when it is relased from being clicked
   */
  const endClick = (e) => {
    midClick(e);
    if (skewVisible && resizing > 0) {
      // released mouse after dragging skew frame
      resizing = -1;
      current_device.skew_adjust = skewAdjustment;
      localStorage.setItem(DEVICES, JSON.stringify(devices));
      try {
        // update the device's skewAdjustment on the server
        // updateDevice(skewAdjustment, current_device.id);
      } catch (err) {
        alert("Error on device update: " + err);
      }
    } else if (resizing >= 0) {
      // before mouse up, a bounding box has been resize
      // send update to the redux store
      const updatedBoundingBoxes = [...boundingBoxes];
      const target = boundingBoxes[resizing];
      // const { area, height } = getBoundingBoxAreaAndHeight(target);
      const isTooSmall = isBoundingBoxTooSmall(target);

      // if resized bounding box is too small
      if (
        // Math.abs(target.start[0] - target.end[0]) < 10 ||
        // Math.abs(target.start[1] - target.end[1]) < 10
        // area < 100 ||
        // height < 20
        isTooSmall
      ) {
        setToastMessage("Bounding box is too small");
        updatedBoundingBoxes[resizing] = { ...boudingBoxBeforeResizing };
        setBoundingBoxes(updatedBoundingBoxes);
      } else {
        const { start, end, topLeft, topRight, bottomRight, bottomLeft } =
          getStartEndCoordinates(target);
        target.start = start;
        target.end = end;
        target.topLeft = topLeft;
        target.topRight = topRight;
        target.bottomRight = bottomRight;
        target.bottomLeft = bottomLeft;
        updatedBoundingBoxes[resizing] = target;

        if (target.type !== "" && target.typeId) {
          let bb = updatedBoundingBoxes[resizing];
          const { surface_area, length } = surfaceArea(bb);
          let surface_area_sqft = (surface_area / (1000 * 1000)) * 10.764; // convert mm2 to sqft
          let length_ft = (length / 1000) * 3.281; // convert mm to ft

          // find out if this is a measurement or not
          let ratingTitle = getRatingTitle(
            menu,
            updatedBoundingBoxes[resizing].typeId
          );

          if (ratingTitle == "sqft") {
            updatedBoundingBoxes[resizing].rating =
              Math.round(surface_area_sqft);
          } else if (ratingTitle == "ft") {
            updatedBoundingBoxes[resizing].rating = Math.round(length_ft);
          }

          updateBoundingBoxes(boundingBoxes);
        }
      }
      resizing = -1;
    } else if (dragging) {
      dragging = false;

      let bb = boundingBoxes[boundingBoxes.length - 1];
      if (isBoundingBoxTooSmall(bb)) {
        boundingBoxes.pop();
        return;
      }
      const { surface_area, length } = surfaceArea(bb);
      let surface_area_sqft = (surface_area / (1000 * 1000)) * 10.764; // convert mm2 to sqft
      let length_ft = (length / 1000) * 3.281; // convert mm to ft

      const { start, end, topLeft, topRight, bottomRight, bottomLeft } =
        getStartEndCoordinates(boundingBoxes[boundingBoxes.length - 1]);
      bb.start = start;
      bb.end = end;
      bb.topLeft = topLeft;
      bb.topRight = topRight;
      bb.bottomRight = bottomRight;
      bb.bottomLeft = bottomLeft;

      if (
        Math.abs(bb.start[0] - bb.end[0]) < 10 ||
        Math.abs(bb.start[1] - bb.end[1]) < 10
      ) {
        boundingBoxes.pop();
      } else {
        // handle drop-down menu here
        let pos = getCursorPos(e, imageRef.current);

        menuRef.current.style.left = pos.x - 10 + "px";
        if (pos.y > imageRef.current.height - 150) {
          menuRef.current.style.top = imageRef.current.height - 150 + "px";
        } else {
          menuRef.current.style.top = pos.y - 10 + "px";
        }

        // window.setTimeout(function () {
        selecting = true;

        // open menu component
        setPopupMenuAnchorEl(menuRef.current);

        const currentBoundingBox = boundingBoxes[boundingBoxes.length - 1];
        /**
             assigned a custom id to the bounding box which is about to be passed
             to the BoundingBoxMenu component.
             Reason 1: this id could be displayed next to the new drawn bounding box;
             Reason 2: , after this bounding box is updated with all label
             properties returned from the the BoundingBoxMenu component, corresponding
             bounding box (before updated) could be found from the boundingBoxes array
            */
        currentBoundingBox.id = `${data.id} - ${localBoundingBoxIdRef.current}`;
        localBoundingBoxIdRef.current = localBoundingBoxIdRef.current + 1;
        const copiedBoundingBox = JSON.parse(
          JSON.stringify(currentBoundingBox)
        );

        copiedBoundingBox.surface_area = Math.round(surface_area_sqft);
        copiedBoundingBox.length = Math.round(length_ft);

        setCurrentBoundingBox(copiedBoundingBox);
        // }, 20);
      }
    }
  };

  /*================================================== shared methods ================================================== */
  const getCursorPos = (e, image) => {
    let imageBound,
      x = 0,
      y = 0;
    e = e || window.event;
    /*get the x and y positions of the image:*/
    imageBound = image.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    x = e.pageX - imageBound.left;
    y = e.pageY - imageBound.top;

    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
  };

  /**
   * @summary update the bounding box array of the selected data point
   *
   * @description Update a bounding box object with a new property called scaledMatrix,
   * append it to the current bounding box array and then update the selected data in
   * redux store
   *
   * @param {Object} boundingBox
   */
  const updateBoundingBoxes = (boundingBox) => {
    // dismiss the pop up menu if it was shown
    setPopupMenuAnchorEl(null);
    unfocuseCanvas();
    const scaledBoundingBoxes = boundingBox.map((bbox) => {
      // const scaledMatrix = scaleBox(bbox);
      let scaledMatrix = [null, null, null, null];
      const { start, end, topLeft, topRight, bottomRight, bottomLeft } = bbox;
      if (start && end) {
        scaledMatrix = [
          Math.floor(start[0]),
          Math.floor(start[1]),
          Math.floor(end[0]),
          Math.floor(end[1]),
        ];
      }
      // if (topLeft) {
      //   bbox.topLeft = [Math.floor(topLeft[0]), Math.floor(topLeft[1])];
      // }
      // if (topRight) {
      //   bbox.topRight = [Math.floor(topRight[0]), Math.floor(topRight[1])];
      // }
      // if (bottomRight) {
      //   bbox.bottomRight = [
      //     Math.floor(bottomRight[0]),
      //     Math.floor(bottomRight[1]),
      //   ];
      // }
      // if (bottomLeft) {
      //   bbox.bottomLeft = [
      //     Math.floor(bottomLeft[0]),
      //     Math.floor(bottomLeft[1]),
      //   ];
      // }
      // bbox.area = getBoundingBoxArea(bbox);
      return { ...bbox, scaledMatrix: scaledMatrix };
    });
    restProps.updateSelectedData({
      ...data,
      selectedBoundingBox: null,
      boundingBoxes: scaledBoundingBoxes,
    });
  };

  /**
   * @description move the boundingBox from selected data point's
   * boundingBoxes array to the removedBoundingBoxes array so that
   * it would be be shown on the UI. As the Canvas component only draw
   * those bounding boxes based on the values of the bounidngBoxes
   *
   * @param {Object} boundingBox
   */

  const removeBoundingBoxes = (boundingBox) => {
    const { removedBoundingBoxes } = data;
    const updatedBoundingBoxes = [];
    for (let i = 0; i < boundingBoxes.length; i++) {
      const box = boundingBoxes[i];
      if (box.id === boundingBox.id) {
        removedBoundingBoxes.push(box);
      } else {
        updatedBoundingBoxes.push(box);
      }
    }

    restProps.updateSelectedData({
      ...data,
      selectedBoundingBox: null,
      boundingBoxes: updatedBoundingBoxes,
      removedBoundingBoxes: [...removedBoundingBoxes],
    });

    unfocuseCanvas();
  };

  /**
   * @summary a callback function passed as a property function to BoundingBoxMenu component.
   *
   * @description while this component is responsible to draw a bounding box,
   * the BoundingBoxMenu is responsible to udpate the drawn bounding box with
   * corresponding bounding box label, including the label id, label name, and
   * rating for the given label.
   *
   * When isAdd is true, which means that the user has chosen a valid rating for the bounding box
   * object before the BoundingBoxMenu component is dismssed. When it is false, it means the user
   * dismiss the BoundingBoxMenu component withouth choosing a rating for this bounding box object.
   * Either he click somewhere out side of the BoundingBoxMenu component or press "escape" key during
   * his navigation of the menu items
   *
   * @param {Object} boundingBox updated bounding box with type id, type name and rating
   * @param {boolean} isAdd whether or not to added the boundingBox to the data point's boundingBoxes array
   */
  const handleBoundingBoxesUpdate = (boundingBox, isAdd) => {
    // deep copy the current bounding boxes
    let copiedBoundingBoxes = boundingBoxes.map((box) =>
      JSON.parse(JSON.stringify(box))
    );

    // find the index of the given updated bounding box in the current bounding boxes
    const foundIndex = copiedBoundingBoxes.findIndex(
      (box) => box.id === boundingBox.id
    );
    if (foundIndex > -1) {
      if (isAdd) {
        // replace the old, found bounding box object with the updated one which contains all info about its label
        copiedBoundingBoxes[foundIndex] = boundingBox;
      } else {
        // remove the bounding box
        copiedBoundingBoxes.splice(foundIndex, 1);
        localBoundingBoxIdRef.current -= 1;
      }
    }
    updateBoundingBoxes(copiedBoundingBoxes);
  };

  const getClearedBoundingBoxes = (boxes) => {
    const copies = [];
    for (const box of boxes) {
      const temp = JSON.parse(JSON.stringify(box));
      temp.id = "";
      copies.push(temp);
    }
    return copies;
  };

  const handleKeyEvent = (event) => {
    const keyName = event.key.toLowerCase();
    if (event.target === event.currentTarget && data.selectedBoundingBox) {
      if (keyName === "backspace" || keyName === "delete") {
        const index = boundingBoxes.findIndex(
          (box) => box.id === data.selectedBoundingBox
        );
        if (index > -1) {
          removeBoundingBoxes(boundingBoxes[index]);
        }
      } else if (keyName === "escape") {
        restProps.updateSelectedData({
          ...data,
          selectedBoundingBox: null,
        });
      }
      // listen to "enter" key event, triggered by this component
      // or "e" event, trigger by other component
      // if pop up menu is not open, then open the pro up rating menu
      // for the labeler
      else if (
        keyName === "enter" ||
        (keyName === "e" && popupMenuAnchorEl === null)
      ) {
        const selectedBoundingBoxId = data.selectedBoundingBox;
        if (selectedBoundingBoxId) {
          const foundBoundingBox = boundingBoxes.find(
            (box) => box.id === selectedBoundingBoxId
          );
          if (foundBoundingBox) {
            const copied = JSON.parse(JSON.stringify(foundBoundingBox));
            const { scaledMatrix } = copied;
            const centerX =
              ((scaledMatrix[0] + (scaledMatrix[2] - scaledMatrix[0]) / 2) *
                imageRelativeWidth) /
              imageWidth;
            const centerY =
              ((scaledMatrix[1] + (scaledMatrix[3] - scaledMatrix[1]) / 2) *
                imageRelativeHeight) /
              imageHeight;

            menuRef.current.style.left = centerX - 10 + "px";
            if (centerY > imageRef.current.height - 150) {
              menuRef.current.style.top = imageRef.current.height - 150 + "px";
            } else {
              menuRef.current.style.top = centerY - 10 + "px";
            }
            setPopupMenuAnchorEl(menuRef.current);

            // find out if this is a measurement or not
            let ratingTitle = getRatingTitle(menu, copied.typeId);

            const { surface_area, length } = surfaceArea(copied);
            let surface_area_sqft = (surface_area / (1000 * 1000)) * 10.764; // convert mm2 to sqft
            let length_ft = (length / 1000) * 3.281; // convert mm to ft

            if (ratingTitle === "sqft") {
              // copied.rating = Math.round(surface_area_sqft);
              copied.surface_area = Math.round(surface_area_sqft);
            } else if (ratingTitle == "ft") {
              // copied.rating = Math.round(length_ft);
              copied.length = Math.round(length_ft);
            }

            setCurrentBoundingBox(copied);
          }
        }
      }
    } else if (
      window.navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey
    ) {
      const eventKey = event.key.toLowerCase();
      const { data, updateSelectedData } = restProps;
      if (eventKey === "c") {
        event.preventDefault();

        const currentBoundingBoxes = data?.boundingBoxes;
        if (
          Array.isArray(currentBoundingBoxes) &&
          currentBoundingBoxes.length > 0
        ) {
          const copy = getClearedBoundingBoxes(currentBoundingBoxes);
          copiedBoundingBoxesRef.current = copy;
          setToastMessage("copied bounding box(es)");
        }
      } else if (eventKey === "v") {
        event.preventDefault();
        const { current: copiedBoundingBoxes } = copiedBoundingBoxesRef;
        if (data && copiedBoundingBoxes.length > 0) {
          if (copiedBoundingBoxes[0].id !== "") {
            setToastMessage("duplicate bounding boxes");
          } else {
            const copiedData = JSON.parse(JSON.stringify(data));
            const { boundingBoxes } = copiedData;

            const { commons, inRighOnly: diffBoxes } = compareBoundingBoxes(
              boundingBoxes,
              copiedBoundingBoxes
            );

            if (commons.length > 0) {
              setToastMessage("duplicate bounding boxes");
            } else if (diffBoxes.length === copiedBoundingBoxes.length) {
              const updatedCopiedBoundingBoxes = [];
              for (let i = 0; i < copiedBoundingBoxes.length; i++) {
                const temp0 = JSON.parse(
                  JSON.stringify(copiedBoundingBoxes[i])
                );
                const temp1 = JSON.parse(
                  JSON.stringify(copiedBoundingBoxes[i])
                );
                temp0.id = `${copiedData.id} copy - ${i}`;
                temp1.id = `${copiedData.id} copy - ${i}`;
                copiedData.boundingBoxes.push(temp0);
                updatedCopiedBoundingBoxes.push(temp1);
              }
              updateSelectedData(copiedData);
              copiedBoundingBoxesRef.current = updatedCopiedBoundingBoxes;
              setToastMessage("pasted bounding box(es)");
            }
          }
        }
      } else if (event.shiftKey && eventKey === "z") {
        event.preventDefault();
        const { current: copiedBoundingBoxes } = copiedBoundingBoxesRef;

        if (data && copiedBoundingBoxes.length > 0) {
          const copiedData = JSON.parse(JSON.stringify(data));
          const { boundingBoxes } = copiedData;

          /**a set of bounding boxes except those generated by shortcut key (copy & paste) */
          const filteredBoxes = boundingBoxes.filter((box) => {
            const { id } = box;
            const result = typeof id === "string" && id.includes("copy");

            return !result;
          });

          if (filteredBoxes.length !== boundingBoxes.length) {
            copiedData.boundingBoxes = filteredBoxes;
            updateSelectedData(copiedData);
            copiedBoundingBoxesRef.current =
              getClearedBoundingBoxes(copiedBoundingBoxes);
            setToastMessage("removed pasted bounding box(es)");
          }
        }
      } else if (eventKey === "s") {
        event.preventDefault();
        if (data && typeof restProps.handleSubmit === "function") {
          restProps.handleSubmit(data);
        }
      }
    }
  };

  // hard code stuff, remove it
  const handleUpdatePCI = (value) => {
    setPopupMenuAnchorEl(null);
    restProps.updateSelectedData({
      ...data,
      pci: Number(value),
    });

    unfocuseCanvas();
    setToastMessage("PCI is set to: " + value);
  };

  /**
   * @summary unfocus the canvas component from the document
   *
   * @description unfocus the canvas component from the doument body so that the
   * arrow keys which are used to navigate the list component would be functional
   * again, without requiring the labeler to click on the list component in order
   * to make them back to functional.
   */
  const unfocuseCanvas = () => {
    // if (timeoutFunction) {
    //   clearTimeout(timeoutFunction);
    // }
    // timeoutFunction = setTimeout(() => {
    //   document.getElementById(CANVAS_COMPONENT_DOM_ID).blur();
    // }, 300);
  };

  return (
    <div
      id={CANVAS_COMPONENT_DOM_ID}
      onMouseMove={(e) => midClick(e)}
      onMouseDown={(e) => startClick(e)}
      onMouseUp={(e) => endClick(e)}
      className={styles.imgZoomContainer}
      tabIndex={1}
      onKeyDown={handleKeyEvent}
      style={{ overflow: "hidden" }}
    >
      <div
        ref={divRef}
        className={className ? className : styles.imageContainer}
        onMouseEnter={() => showZoomDetails(true)}
        onMouseLeave={(event) => {
          if (dragging) {
            endClick(event);
          }
          showZoomDetails(false);
        }}
      >
        <div
          ref={menuRef}
          aria-controls="simple-menu"
          aria-haspopup="true"
          style={{ zIndex: -1, position: "absolute", top: 100, left: 100 }}
        >
          {/* {popupMenuAnchorEl && ( */}
          <BoundingBoxMenu
            menu={menu.filter((rootMenu) => {
              return labelPreference.indexOf(rootMenu.name) >= 0;
            })}
            boundingBox={currentBoundingBox}
            data={data}
            onUpdateBoundingBoxes={handleBoundingBoxesUpdate}
            anchorElement={popupMenuAnchorEl}
            onUpdatePCI={handleUpdatePCI}
          />
          {/* )} */}
        </div>

        {showMagnifier && (
          <div ref={lensRef} id="lens" className={styles.imgZoomLen}>
            <canvas ref={clensRef} style={{ width: 400, height: 300 }} />
          </div>
        )}
        <div ref={divRef} style={{ width: "100%" }}>
          <div style={{ width: "100%", height: 1, overflow: "hidden" }}>
            {/* this img tag is redunctant, would be remove */}
            <img
              className={imageStyle ? imageStyle : ""}
              id="myimage"
              ref={imageRef}
              src={src}
              style={{
                width: "100%",
              }}
              onLoad={() => {
                imageRef.current.style.width = imageRef.current.naturalWidth;
                imageRef.current.style.height = imageRef.current.naturalHeight;
                imageWidth = imageRef.current.naturalWidth;
                imageHeight = imageRef.current.naturalHeight;
              }}
              alt="iris drift zoom"
            />
          </div>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <canvas
              ref={canvasRef}
              style={{
                // maxWidth: "720px"
                backgroundImage: `url(${src})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: `100% auto`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BoundingBoxCanvas.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  imageStyle: PropTypes.object,
  zoomLevel: PropTypes.number,
};

BoundingBoxCanvas.defaultProps = {
  zoomLevel: 3,
};

const mapStateToProps = (state) => {
  const { MMSDefectObjects, roadRelatedIssues, menu } = state.dataPointsState;
  const { labelPreference } = state.globalState;
  return {
    mmsDefectObjects: MMSDefectObjects,
    roadRelatedIssues,
    menu,
    labelPreference,
  };
};
export default connect(mapStateToProps, {
  updateSelectedData,
  setToastMessage,
})(BoundingBoxCanvas);

/**
 * Test Log:
 *
 * Create Date: Nov 27, 2020
 *
 * Description: Testing scenario
 *
 * 1. create bounding box
 * 2. resizing bounding box
 * 3. shrink bounding box to to small
 * 4. delete bounding
 *   1. delete by pressing backspace in canvas
 *   2. delete by pressing backspace in list component
 * 5. check whether magnifier works properly or not
 */

/**
 * Change Log:
 *
 * Change Date: Nov 20, 2020
 *
 * Description:
 *
 * 1. connected to redux store
 *
 * 2. added CRUD bounding box features
 *
 * 3. added documentation
 */

/**
 * Change Log:
 *
 * Change Date: Jan 10, 2021
 *
 * Description: updates for boosting user's interaction of bounding boxes.
 *
 * 1. Removed the confirmation dialog which prompts the user to remove a bounding box
 *    simply boost such processes as there would be potentially many of them.
 * 2. Add shortcut key (shift + d) to remove and restore all bounding boxes for the current data points
 */

/**
 * Change Log:
 *
 * Change Date: Jan 29, 2021
 *
 * Description: updated UI.
 * 1. Removed the blurry effect applied on the drawn bounding boxes so that the
 *    could see through it.
 * 2. Removed the "x" marker, which indicates that the user could click and toggle the
 *    removal of the current bounding box, from the center of a bounding box.
 * 3. Add data point's latitude and longitude information on the upper left corner of this component.
 *
 * As a result, after this updated, a drawn bounding box wound contain only the border edges,
 * no "x" marker on the center, no blurry effect covers the image details behind the bounding
 * box.
 */

/**
 * Change Log:
 *
 * Change Date: Feb 02, 2021
 *
 * Description:
 *
 * Filter the menu array before passing it to the BoundingBoxComponent.
 * After this updated, each parent menu element would contains an array of
 * city ids. Only if the current data point's city id is within a parent
 * menue element's city id, the corresponding menu parement menu would be passed
 * to the BoundingBoxMenu component for rendering.
 *
 */

/**
 * Change Log:
 *
 * Change Date: Feb 13, 2021
 *
 * Description:
 * created new feature: hit enter key to bring up the rating component after a bounding box is selected over there,
 * an id is generated for the root of this component so that this component could be referenced from the BoundingBoxComponent
 * from where a keyboardEvent would be dispatched to this component.
 *
 * @see BoundingBoxComponent
 */

/**
 * Change Log:
 *
 * Change Date: Mar 09, 2021
 *
 * Description: As the labeler requires, now the "x" symbol on a bounding box is put back, the function that listens to the click
 * evebt if tge  the symbol and remove the bounding box from the UI is also put back
 */

/**
 * Change Log:
 *
 * Change Date: Mar 12, 2021
 *
 * Description: unblur the canvas so that the arrow buttons which are used
 * to toggle the data point list are back to functional.
 *
 * @see unfocuseCanvas
 */

/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Mar 30, 2021
 *
 * Description: added drawing skew view on the canvas component, editing which would modify current
 * device's skew_adjust on the server
 */

/**
 * Change Log:
 *
 * Change Date: Jun 10, 2021
 *
 * Description:
 *
 * Updated resizing bounding box mechanism.
 * the drawn bounding box is used to be resized into a rectangular bounding box, with this update,
 * the new bounding box is still drawn into a rectangular shape. When it is resized, however,
 * it would be resized into a trapezoid.
 *
 * Since the shape of the bounding is changed, the following mechanism got updated:
 * 1. the center of the bounding box (trapezoid) is redefined
 * @see getCenter
 * 2. whehter a mouse click is within the bounding box (trapezoid) or not
 * @see isInsideBoundingBox
 * 3. the way how the canvas' context to draw a bounding box is updated: locate point, then line to point, instead of
 *    drawing rectangle instead.
 * @see drawBoundingBox
 * 4. the mechanism which determines whehter a bounding box is resized too small.
 * @see getBoundingBoxAreaAndHeight
 */

/**
 * Change Log:
 *
 * Change Date: Aug 11, 2021
 *
 * Description:
 * 1. removed feature of resizing skew adjustment
 * 2. add ctrl + c to copy entire set of bounding boxes
 * 3. add ctrl + v to paste entire set of bounding boxes
 * 4. add ctrl + shift + z to remove pasted bounding boxes
 * 5. when user click on the canvas and not within a bounding box, a selected bounding box, if there is any, would be
 * unselected
 */

/**
 * Change Log:
 *
 * Change Date: Aug 12, 2021
 *
 * Description:
 * 1. removed feature that allows user to change a device's skew adjustment
 * including commented out short cut key trigger and api call
 */

/**
 * Change Log:
 *
 * Change Date: Sep 07, 2022
 *
 * Description: enable to label (draw bounding boxes) on images of various resolution.
 * 1. read image resolution, represented in string format, '720p', '1080p', and '4k', from data point object.
 * 2. based on the resolution, dedicated iamgeWidth and iamgeHieht would be set.
 * 3. draw current image resolution on the upper left of the canvas, along with info about data point id, gelocation, etc.
 */
