import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
// replace with root reducer if there are more than one reducers
import loginReducer from "./redux/reducers/loginReducer/loginReducer";
import dataPointsReducer from "./redux/reducers/dataPointsReducer/dataPointsReducer";
import "./sass/main.scss";
import globalReducer from "./redux/reducers/globalReducer/globalReducer";
import { awsconfig } from "./aws/aws-export";
import Amplify from "@aws-amplify/core";
const rootReducer = combineReducers({
  globalState: globalReducer,
  loginState: loginReducer,
  dataPointsState: dataPointsReducer,
  // toastState: toastReducer,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(loginReducer, composeEnhancer)
// if there is any middleware along with composeEnhancer
const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

Amplify.configure(awsconfig);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/**
 * Change Log:
 *
 * Change Date: Oct 16, 2020
 *
 * Description: added react-redux
 */
