import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IrisButton } from "irisrad-ui";

export default function IRISDIalog(props) {
  const { dialogTitle, dialogMessage, onClose, onConfirm } = props;
  const [open, setOpen] = React.useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    if (onConfirm) {
      onConfirm();
      onClose();
    }
  };

  return (
    <Dialog
      id="iris-dialog"
      disableBackdropClick={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="dialog-content-wrapper">
        <DialogTitle id="alert-dialog-title">
          <span className="dialog-title">{dialogTitle}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span className="dialog-message">{dialogMessage}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IrisButton
            onClick={handleClose}
            color="primary"
            variant="outlined"
            id="iris-diaolog-dismiss-btn"
            size="large"
            autoFocus={onConfirm === undefined}
          >
            Dismiss
          </IrisButton>
          {onConfirm && (
            <IrisButton
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              id="iris-diaolog-confirm-btn"
              size="large"
              autoFocus
            >
              Okay
            </IrisButton>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
}
