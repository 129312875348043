import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { IRIS_RED } from "../uiConstants";
const DEFAULT_CSS = {
  fontSizeDefault: "1rem",
  fontSizeSm: "0.875rem",
  fontSizeXs: "0.75rem",
};
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cf0206",
    },
  },
  typography: {
    button: {
      fontSize: "1.25rem",
      fontWeight: 400,
      textTransform: "none",
    },
  },
  overrides: {
    // override labels
    MuiInputLabel: {
      root: {
        fontSize: DEFAULT_CSS.fontSizeDefault,
        marginBottom: "0.625rem",
        "@media (max-width: 1440px)": {
          fontSize: "1.1rem",
        },
        "@media (max-width: 1024px)": {
          fontSize: "1.25rem",
        },
      },
      formControl: {
        "&.Mui-focused": {
          top: "-0.8rem",
          fontSize: DEFAULT_CSS.fontSizeDefault,
        },
      },
    },

    MuiMenuItem: {
      root: {
        fontSize: DEFAULT_CSS.fontSizeDefault,
      },
    },

    // selections in Select elements
    MuiSelect: {
      root: {
        fontSize: DEFAULT_CSS.fontSizeDefault,
      },
    },

    // date picker
    MuiInputBase: {
      root: {
        fontSize: DEFAULT_CSS.fontSizeDefault,
      },
    },

    // font size
    MuiTypography: {
      body1: {
        fontSize: DEFAULT_CSS.fontSizeDefault,
      },
      body2: {
        fontSize: DEFAULT_CSS.fontSizeXs,
      },
    },

    MuiSvgIcon: {
      root: {
        fontSize: "1.25rem",
      },
      fontSizeSmall: {
        fontSize: "1.125rem",
      },
    },

    // override buttons
    MuiButton: {
      root: {
        // fontSize: "4rem",
      },
      containedPrimary: {
        "&:hover": {},
      },
      containedSizeSmall: {
        fontSize: DEFAULT_CSS.fontSizeSm,
      },
      outlinedSizeSmall: {
        fontSize: DEFAULT_CSS.fontSizeSm,
      },
    },

    MuiListItem: {
      gutters: {
        paddingLeft: "1rem",
      },
    },

    MuiFormHelperText: {
      root: {
        fontSize: DEFAULT_CSS.fontSizeXs,
      },
    },

    MuiTableHead: {
      root: {
        background: `linear-gradient(45deg, ${IRIS_RED} 30%, #FF8E53 90%)`,
      },
    },

    MuiTableBody: {
      root: {
        fontSize: DEFAULT_CSS.fontSizeDefault,
        fontWeight: 400,
      },
    },

    MuiTableRow: {
      root: {
        fontWeight: 400,
      },
    },
    MuiTableCell: {
      root: {
        fontSize: DEFAULT_CSS.fontSizeDefault,
        fontWeight: 400,
      },
      head: {
        color: "#ffffff",
      },
      stickyHeader: {
        backgroundColor: `linear-gradient(45deg, ${IRIS_RED} 30%, #FF8E53 90%)`,
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: "60rem",
        minWidth: "50rem", // 600px
      },
    },
  },
});

const IRISThemeProvider = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default IRISThemeProvider;
