/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Mar 21, 2020
 *
 * Description: file that contains utility functions that help
 * to do HTTP request
 */

import {
  getDataPointByCityUrl,
  lookupUrl,
  updateDataPointUrl,
  loginUrl,
  getViewReport,
  getDistanceReport,
  postDataPointBoundingBoxesUrl,
  updateDataPointBoundingBoxUrl,
  updateDeviceUrl,
  getPciUrl,
  sendReportEmailUrl,
  deleteLabelUrl,
  fmeReportUrl,
  autoGenerateWorkorderUrl,
  verifyUrl,
  CREATE_WORK_ORDERS_BATCH_URL,
} from "./backendURLs";
import { TOKEN } from "../components/login/localStorageKeys";
import { getRectangularBoundingBoxCoordinates } from "../utils/boundingBoxUtils";
import { TreeBuilder } from "../utils/MenuTree";
import { getAWSToken } from "../utils/cognitoUtils";

export const ERROR_MESSAGES = {
  ACCESS_DENIED: "ACCESS DENIED",
};

export const API_ERROR_MESSAGES = {
  TOKEN_EXPIRED: {
    title: "TOKEN EXPIRED",
    errorMessage: "Given token not valid for any token type",
    message: "Session is expired, please log in again.",
  },
  ACCESS_DENIED: "ACCESS DENIED",
  PAGE_NOT_FOUND: "PAGE NOT FOUND",
  GENERAL_SERVER_ERROR: "SERVER ERROR",
  UNDEFINED_ERROR: "UNDEFINED ERROR",
};

export const UNDEFINED_DEVICE_ID = "N/A";
const TAG = "awesBackendUtils.js";

// string value for different flags parsing from
// data points on server
export const FLAG_TYPES = {
  NONE: "Flag(N)",
  RED: "Flag(R)",
  YELLOW: "Flag(Y)",
  RED_YELLOW: "Flag(RY)",
};

let labelTree = null;

/**
 * @summary handle login to sever
 *
 * @param {String} userName
 * @param {String} password
 *
 * @return {Promise}
 */
export const login = async (userName, password) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username: userName, password: password }),
  };
  const response = await fetch(loginUrl, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

export const getUserGroups = async (userName, accessToken) => {
  const url = new URL(lookupUrl);
  // url.searchParams.append("user", true);
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await fetch(url, options);
  let error;
  if (response.ok) {
    const result = await response.json();
    const users = result.result.user;
    const foundUser = users.find((user) => user.username === userName);
    const damageTypes = result.result.damage_type;
    const deviceInfo = result.result.device_info;

    const labelTypes = result.result.label_type;

    return { foundUser, damageTypes, deviceInfo, labelTypes };
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

export const getAllData = async (params) => {
  const accessTokenAWS = await getAWSToken();
  const accessToken = localStorage.getItem(TOKEN);
  const {
    ids,
    url,
    city,
    date,
    device_id,
    searchLocation,
    damageTypeId,
    datapoint_id,
    label_type,
    queryTimeRange,
  } = params;
  //fetching all datapoints by city
  let dataPointObj = {};
  let getDataPointsResult = [];

  // if url, given by back end, is provided, fetch data points by url
  // if it was not provided, fetch all data points from back end
  getDataPointsResult = url
    ? await getDataPointsByURL(url, accessTokenAWS).catch((error) => {
        throw new Error("Error in getting data points", error);
      })
    : await searchDataPoints({
        ids,
        city,
        date,
        device_id,
        searchLocation,
        damageTypeId,
        datapoint_id,
        label_type,
        queryTimeRange,
      }).catch((error) => {
        throw new Error("Error in getting data points", error);
      });

  if (getDataPointsResult) {
    dataPointObj = getDataPointsResult;
    // next is the url defined as next page
  }

  const results = await lookupResults(accessToken);

  const {
    damage_type: damageTypes,
    city: cityObj,
    damage_rates: damageRates,
    device_info: deviceInfo,
    road_issue: roadIssues,
  } = results;

  const labelTypes = results.label_type;

  const ddd = damageTypes.find((t) => t.name === "None").id;
  const iii = roadIssues.find((issue) => issue.issue === "None").id;
  // array of registered devices,
  // used to popuplate the device id drop
  // down menu on search bar
  let deviceIdArray = [];
  for (const dInfo of deviceInfo) {
    deviceIdArray.push(dInfo.device_sn);
  }

  // filter data points
  // keep those are "enabled".
  // ps: eanbled means a labeller decided to keep the data for front end (viewing)
  //     disabled means a labeller decided not to keep the data from front end (not longer desired to view it)

  const modifiedData = dataPointObj.results
    .filter((element) => element.enabled === "Y")
    .map((data) => {
      // remove this field because it contains to much data
      // that is not useful for this portal
      delete data["accelerometer_info"];

      // override the default 80, assign automatically
      // by the server, to 85
      // if (data.pci === 80) {
      //   data.pci = 85;
      // }
      const { damage_type } = data;

      // array of damage type string
      // either in form of ["None"]
      // or in form of ["damageType0", "damageType1"]
      const damageTypeStrings = [];
      if (damage_type && damage_type.length === 0) {
        damageTypeStrings.push("None");
      } else if (damage_type.length > 0) {
        for (const damageType of damage_type) {
          const correspondingType = damageTypes.find(
            (type) => type.id === damageType.id
          );
          if (correspondingType) {
            damageTypeStrings.push(correspondingType.name);
          }
        }
      }

      // for defect flag string
      let damageFlag = "";
      switch (data.flag) {
        case "R":
          damageTypeStrings.push(FLAG_TYPES.RED);
          break;
        case "Y":
          damageTypeStrings.push(FLAG_TYPES.YELLOW);
          break;
        case "RY":
          damageTypeStrings.push(FLAG_TYPES.RED);
          damageTypeStrings.push(FLAG_TYPES.YELLOW);
          break;
        default:
          damageFlag = "";
      }

      // for roadIssues
      const roadIssues = [];
      for (const issue of data.road_related_issues) {
        roadIssues.push(issue.issue);
      }

      // for road issues flag
      const roadIssueFlag =
        data.flag === "Y" || data.flag === "RY" ? FLAG_TYPES.YELLOW : "";
      if (roadIssueFlag !== "") {
        roadIssues.push(roadIssueFlag);
      }

      // for preparing bounding boxes
      const boundingBoxes = getBoundingBoxFromLabel(data.labels, labelTypes);
      const boundingBoxesOriginal = getBoundingBoxFromLabel(
        data.labels,
        labelTypes
      );

      const tempData = {
        ...data,
        city: data.current_city,
        road_related_issues: roadIssues,
        modified: data.modified === "Y" ? true : false,
        visited: false,

        damage_types:
          data.damage_types.length === 0 ? [ddd] : data.damage_types,
        road_issues: data.road_issues.length === 0 ? [iii] : data.road_issues,
        boundingBoxes,
        boundingBoxesOriginal,
        removedBoundingBoxes: [],
      };
      return tempData;
    });

  // data points are all modified, returning all results (info) fetched from server
  dataPointObj = { ...dataPointObj, results: modifiedData };
  return {
    dataPointObj,
    deviceIdArray,
    cityObj,
    damageTypes,
    damageRates,
    roadIssues,
    labelType: results.label_type,
  };
};

/**
 * @summary look up data, including registered devices, valid damage types and valid road related issues
 * @return {Promise}
 */
export const lookupResults = async (accessToken) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await fetch(lookupUrl, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson.result;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

/**
 * @description fetch data points by given url, along with valid access token
 *
 * @param {String} url
 * @param {String} token access token
 */
const getDataPointsByURL = async (url, accessToken) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // only query enabled data points
  const urlObj = new URL(url);
  urlObj.searchParams.append("enabled", "Y");

  const response = await fetch(url, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

/**
 *
 * @description update a data point to server
 *
 * @param {Number} id data point id
 * @param {Object} updateData key-value paris to be uploaded to server
 * @returns {Promise}
 */
export const updateData = async (id, updateData) => {
  const accessToken = localStorage.getItem(TOKEN);
  const options = {
    method: "POST",
    headers: { Authorization: `Bearer ${accessToken}` },
    body: getFormData(updateData),
  };
  const response = await fetch(`${updateDataPointUrl}/${id}/`, options);
  let error;
  if (response.ok) {
    return true;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

/**
 * @description query data points from server
 *
 * @param {Object} searchParams search parameters used to query data points from server
 * @returns {Promise}
 *
 */
export const searchDataPoints = async (searchParams) => {
  const accessToken = await getAWSToken();
  const url = new URL(getDataPointByCityUrl);
  const {
    ids,
    city,
    device_id,
    date,
    searchLocation,
    damageTypeId,
    datapoint_id,
    label_type,
    queryTimeRange,
  } = searchParams;

  // only query "enabled" data points
  url.searchParams.append("enabled", "Y");

  if (datapoint_id) {
    url.searchParams.append("id", datapoint_id);
  } else {
    if (city !== null && city !== undefined && city !== "") {
      url.searchParams.append("current_city", city);
    }
    if (device_id) {
      url.searchParams.append("device_id", device_id);
    }

    // if (date) {
    //   url.searchParams.append("date", date);
    // }

    if (queryTimeRange && Object.keys(queryTimeRange).length === 2) {
      for (const key in queryTimeRange) {
        if (Object.hasOwnProperty.call(queryTimeRange, key)) {
          const element = queryTimeRange[key];
          url.searchParams.append(key, element);
        }
      }
    }

    if (searchLocation) {
      url.searchParams.append("latitude", searchLocation.latitude);
      url.searchParams.append("longitude", searchLocation.longitude);
    }
    if (damageTypeId > 0) {
      url.searchParams.append("damage_type", damageTypeId);
    }

    if (
      label_type &&
      label_type !== "" &&
      label_type.toLowerCase() != "please select a label"
    ) {
      if (label_type.toLowerCase() === "all") {
        url.searchParams.append("with_label", true);
      } else {
        url.searchParams.append("label_type", label_type);
      }
    }

    if (Array.isArray(ids) && ids.length > 0) {
      for (const id of ids) {
        url.searchParams.append("ids", id);
      }
    }
  }

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const response = await fetch(url, options);

  const responseJson = await response.json();

  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

export async function getReports(searchParams) {
  const accessToken = localStorage.getItem(TOKEN);
  const viewReportUrl = new URL(getViewReport);
  const distanceReportUrl = new URL(getDistanceReport);
  let selectedUrl = null;
  const { option, month, year } = searchParams;
  if (option === 1) {
    selectedUrl = distanceReportUrl;
  } else {
    selectedUrl = viewReportUrl;
  }
  if (month !== null && month !== undefined && month !== "") {
    selectedUrl.searchParams.append("month", month);
  }
  // if (year !== null && year !== undefined && year !== "") {
  //   selectedUrl.searchParams.append("year", year);
  // }
  if (year) {
    selectedUrl.searchParams.append("year", year);
  }
  return new Promise((resolve, reject) => {
    fetch(selectedUrl.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function exportReports(searchParams) {
  const viewReportUrl = new URL(getViewReport);
  const distanceReportUrl = new URL(getDistanceReport);
  let selectedUrl = null;
  const { option, month, year } = searchParams;
  if (option === 1) {
    selectedUrl = distanceReportUrl;
  } else {
    selectedUrl = viewReportUrl;
  }
  if (month !== null && month !== undefined && month !== "") {
    selectedUrl.searchParams.append("month", month);
  }
  // if (year !== null && year !== undefined && year !== "") {
  //   selectedUrl.searchParams.append("year", year);
  // }
  if (year) {
    selectedUrl.searchParams.append("year", year);
  }

  selectedUrl.searchParams.append("export", true);

  return new Promise((resolve, reject) => {
    const accessToken = localStorage.getItem(TOKEN);
    fetch(selectedUrl.toString(), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        var disposition = response.headers.get("content-disposition");
        var matches = /"([^"]*)"/.exec(disposition);
        var filename = matches != null && matches[1] ? matches[1] : "file.csv";
        response.blob().then(function (myBlob) {
          var file = new File([myBlob], filename, {
            type: "text/csv",
          });
          resolve(file);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// check whether given object is not null and not undefined.
export const isValidObject = (obj) => {
  return obj !== null && obj !== undefined;
};

/**
 * @summary transfer a regular object into a FormData object
 *
 * @param {Object} object
 *
 * @returns FormData object
 */
const getFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    const values = object[key];
    if (Array.isArray(values)) {
      for (const value of values) {
        formData.append(key, value);
      }
    } else {
      formData.append(key, object[key]);
    }
  });
  for (var pair of formData.entries()) {
  }
  return formData;
};

export const createDataPointBoundingBoxes = async (box, dataPointId) => {
  const boundingBoxObject = getBoudingBoxesUploadData([box], dataPointId);
  const url = new URL(postDataPointBoundingBoxesUrl);
  const accessToken = localStorage.getItem(TOKEN);
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: getFormData(boundingBoxObject),
  };
  const response = await fetch(url.toString(), options);
  const responseJson = await response.json();

  let error;
  if (response.ok) {
    const { result } = responseJson;
    result.oldId = box.id;
    return result;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

export const deleteBoundingBox = async (dataPointId) => {
  const accessToken = localStorage.getItem(TOKEN);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await fetch(
    // `${updateDataPointBoundingBoxUrl}/${dataPointId}/delete/`,
    `${deleteLabelUrl}/${dataPointId}/delete/`,
    options
  );
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    responseJson.id = dataPointId;
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

export const updateBoundingBox = async (boundingBox) => {
  const labelId = boundingBox.id;
  const payLoad = getUpdatedBoundingBoxesData(boundingBox);
  const accessToken = localStorage.getItem(TOKEN);
  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: getFormData(payLoad),
  };
  const response = await fetch(
    `${updateDataPointBoundingBoxUrl}/${labelId}/`,
    options
  );
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    responseJson.id = labelId;
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  error.id = labelId;
  throw error;
};

export const getPCI = async (pci_data) => {
  const accessToken = localStorage.getItem(TOKEN);
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(pci_data),
  };

  const response = await fetch(`${getPciUrl}`, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      alert(JSON.stringify(response));
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

// api call to change device skew adjustment, commented out
// due to this feature is no longer needed on the front end
export const updateDevice = async (skewAdjust, device_id) => {
  // const accessToken = localStorage.getItem(TOKEN);
  // const payload = JSON.stringify({
  //   skew_adjust: {
  //     top_left_x: Math.round(skewAdjust.top_left[0]),
  //     top_left_y: Math.round(skewAdjust.top_left[1]),
  //     top_right_x: Math.round(skewAdjust.top_right[0]),
  //     top_right_y: Math.round(skewAdjust.top_right[1]),
  //     bottom_left_x: Math.round(skewAdjust.bottom_left[0]),
  //     bottom_left_y: Math.round(skewAdjust.bottom_left[1]),
  //     bottom_right_x: Math.round(skewAdjust.bottom_right[0]),
  //     bottom_right_y: Math.round(skewAdjust.bottom_right[1]),
  //     top_distance: Math.round(skewAdjust.top_distance),
  //     bottom_distance: Math.round(skewAdjust.bottom_distance),
  //     top_width: Math.round(skewAdjust.top_width),
  //     bottom_width: Math.round(skewAdjust.bottom_width),
  //   },
  // });
  // const options = {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${accessToken}`,
  //   },
  //   body: payload,
  // };
  // const response = await fetch(`${updateDeviceUrl}/${device_id}/`, options);
  // const responseJson = await response.json();
  // let error;
  // if (response.ok) {
  //   return responseJson;
  // }
  // {
  //   if (response.status === 401) {
  //     error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
  //   } else if (response.status === 404) {
  //     error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
  //   } else if (response.status >= 500) {
  //     error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
  //   } else {
  //     alert(JSON.stringify(response));
  //     error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
  //   }
  // }
  // throw error;
};

// const getBoundingBoxFromLabel = (dataPointLabels, allLabels) => {
//   const validLabels = allLabels.filter(
//     (label) => label.enabled.toLowerCase() === "y"
//   );
//   let boundingBoxes = [];
//   if (dataPointLabels && dataPointLabels.length && dataPointLabels.length > 0) {
//     for (const label of dataPointLabels) {
//       const {
//         bbox_x1: x0,
//         bbox_x2: x1,
//         bbox_x3: x2,
//         bbox_x4: x3,
//         bbox_y1: y0,
//         bbox_y2: y1,
//         bbox_y3: y2,
//         bbox_y4: y3,
//       } = label;

//       let type = "";
//       const foundType = validLabels.find(
//         (element) => element.id === label.label_type
//       );
//       if (foundType) {
//         const startPos = [
//           Math.min(label.bbox_x1, label.bbox_x2),
//           Math.min(label.bbox_y1, label.bbox_y2),
//         ];
//         const endPos = [
//           Math.max(label.bbox_x1, label.bbox_x2),
//           Math.max(label.bbox_y1, label.bbox_y2),
//         ];
//         type = foundType.name;
//         const box = {
//           id: label.id,
//           // start: [label.bbox_x1, label.bbox_y1],
//           // end: [label.bbox_x2, label.bbox_y2],
//           start: [startPos],
//           end: endPos,
//           topLeft: [x0, y0],
//           topRight: [x1, y1],
//           bottomRight: [x2, y2],
//           bottomLeft: [x3, y3],
//           scaledMatrix: [startPos[0], startPos[1], endPos[0], endPos[1]],
//           typeId: label.label_type,
//           type: type,
//           rating: label.rating,
//           selected: false,
//         };

//         boundingBoxes.push(box);
//       }
//     }
//   }
//   return boundingBoxes;
// };

/**
 *
 * @summary reconstruct the set of labels of a data point.
 *
 * @description as each label in the data point is in the form like this
 * {
 *  "id": 175965,
 *  "bbox_x1": xxx，
 *  "bbox_x2": xxx，
 *  "bbox_x3": xxx，
 *  "bbox_x4": xxx，
 *  "bbox_y1": xxx，
 *  "bbox_y2": xxx，
 *  "bbox_y3": xxx，
 *  "bbox_y4": xxx，
 *  "rating": 67,
 *  "resolution": "EM",
 *  "label_type": 345
 * },
 * {
 *   start: [x1, y1],
 *   end: [x3, y3],
 *   topLeft: [x1, y1],
 *   topRight: [x2, y2],
 *   bottomRight: [x3, y3],
 *   bottomLeft: [x4, y4],
 *   typeId: label.label_type,
 *   type: label_title,
 *   rating: label.rating,
 *   selected: false,
 * },
 *
 * which contains not enough information to construct the UI elements.
 *
 * so the information of each label shoudl be extend to the following form:
 *
 *
 * @param {Array[Object]} dataPointLabels a set of label objects attached to the data point
 * @param {Array[Object]} allLabels a set of label objects from the server
 * @returns
 */
const getBoundingBoxFromLabel = (dataPointLabels, allLabels) => {
  const tree =
    labelTree ||
    getLabelTree(
      allLabels.filter((label) => label.enabled.toLowerCase() === "y")
    );

  const modifiedNotes = tree.getRootNotes();

  let boundingBoxes = [];
  if (dataPointLabels && dataPointLabels.length && dataPointLabels.length > 0) {
    for (const label of dataPointLabels) {
      // const {
      //   bbox_x1: x0,
      //   bbox_x2: x1,
      //   bbox_x3: x2,
      //   bbox_x4: x3,
      //   bbox_y1: y0,
      //   bbox_y2: y1,
      //   bbox_y3: y2,
      //   bbox_y4: y3,
      // } = label.bbox.bounding_box;

      let [x0, y0, x1, y1, x2, y2, x3, y3] = label.bbox.bounding_box;

      // for legacy data point
      if (!x2) {
        // bottom right
        x2 = x1;
        y2 = y1;

        // top right
        x1 = x2;
        y1 = y0;

        // bottom left
        x3 = x0;
        y3 = y2;
      }

      const topLeft = [x0, y0];
      const topRight = [x1, y1];
      const bottomRight = [x2, y2];
      const bottomLeft = [x3, y3];
      const rect = getRectangularBoundingBoxCoordinates([
        topLeft,
        topRight,
        bottomRight,
        bottomLeft,
      ]);
      const { topLeft: rectStart, bottomRight: rectEnd } = rect;
      let type = "";
      const foundType = modifiedNotes.find(
        (element) => element.id === label.label_type
      );
      if (foundType) {
        type = foundType.name;
        const box = {
          id: label.id,
          // start: [label.bbox_x1, label.bbox_y1],
          // end: [label.bbox_x2, label.bbox_y2],
          start: rectStart,
          end: rectEnd,
          topLeft,
          topRight,
          bottomRight,
          bottomLeft,
          scaledMatrix: [rectStart[0], rectStart[1], rectEnd[0], rectEnd[1]],
          typeId: label.label_type,
          type: type,
          rating: label.rating,
          selected: false,
          isPolygon: foundType.parents[0].toLowerCase() === "pci",
        };

        boundingBoxes.push(box);
      }
    }
  }
  return boundingBoxes;
};

const getLabelTree = (labels) => {
  if (Array.isArray(labelTree) && labelTree.length > 0) return;
  const alteredLabels = labels.map((label) => {
    const { rating_range, range_title, parent, city_id } = label;
    label.ratingRange = rating_range;
    label.ratingTitle = range_title;
    label.parentId = parent;

    label.cityId = city_id;

    // delete label.rating_range;
    // delete label.range_title;
    // delete label.parent;
    // delete label.city_id;

    return label;
  });
  labelTree = new TreeBuilder(
    alteredLabels,
    "parentId",
    "id",
    "subMenu",
    "name"
  );
  return new TreeBuilder(alteredLabels, "parentId", "id", "subMenu", "name");
};
export const getDevices = async () => {
  const accessToken = localStorage.getItem(TOKEN);
  const options = {
    method: "GET",
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await fetch(lookupUrl + "?device_id=true", options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    let devices = responseJson.result.device_info;
    devices.map((device) => {
      if (JSON.stringify(device.skew_adjust) === "{}") {
        device.skew_adjust = {
          top_left: [700, 450],
          top_right: [900, 450],
          bottom_left: [500, 600],
          bottom_right: [1000, 600],
          top_distance: 20000, // mm
          bottom_distance: 10000, // mm
          top_width: 3500, // mm
          bottom_width: 3500, // mm
        };
      } else {
        device.skew_adjust.top_left = [
          device.skew_adjust.top_left_x,
          device.skew_adjust.top_left_y,
        ];
        device.skew_adjust.top_right = [
          device.skew_adjust.top_right_x,
          device.skew_adjust.top_right_y,
        ];
        device.skew_adjust.bottom_left = [
          device.skew_adjust.bottom_left_x,
          device.skew_adjust.bottom_left_y,
        ];
        device.skew_adjust.bottom_right = [
          device.skew_adjust.bottom_right_x,
          device.skew_adjust.bottom_right_y,
        ];
      }
    });
    return devices;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

export const getDataById = async (id) => {
  const url = new URL(getDataPointByCityUrl);
  url.searchParams.append("id", id);
  const accessToken = await getAWSToken();
  const options = {
    method: "GET",
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await fetch(url.toString(), options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    const lookupResponse = await lookupResults(accessToken);
    const allLabels = lookupResponse.label_type;
    const labels = responseJson.results[0].labels;
    const boundingBoxes = getBoundingBoxFromLabel(labels, allLabels);
    return boundingBoxes;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

export const getBoudingBoxesUploadData = (boudingBoxes, dataPointId) => {
  let newBoundingBoxeData = {};
  for (let index = 0; index < boudingBoxes.length; index++) {
    const box = boudingBoxes[index];
    // new payload for 4-edge-polygon bounding box
    const { topLeft, topRight, bottomRight, bottomLeft } = box;
    // newBoundingBoxeData[`labels[${index}]bbox_x1`] = Math.floor(topLeft[0]);
    // newBoundingBoxeData[`labels[${index}]bbox_y1`] = Math.floor(topLeft[1]);
    // newBoundingBoxeData[`labels[${index}]bbox_x2`] = Math.floor(topRight[0]);
    // newBoundingBoxeData[`labels[${index}]bbox_y2`] = Math.floor(topRight[1]);
    // newBoundingBoxeData[`labels[${index}]bbox_x3`] = Math.floor(bottomRight[0]);
    // newBoundingBoxeData[`labels[${index}]bbox_y3`] = Math.floor(bottomRight[1]);
    // newBoundingBoxeData[`labels[${index}]bbox_x4`] = Math.floor(bottomLeft[0]);
    // newBoundingBoxeData[`labels[${index}]bbox_y4`] = Math.floor(bottomLeft[1]);
    newBoundingBoxeData[`labels[${index}]bbox`] = JSON.stringify({
      bounding_box: [
        Math.floor(topLeft[0]),
        Math.floor(topLeft[1]),
        Math.floor(topRight[0]),
        Math.floor(topRight[1]),
        Math.floor(bottomRight[0]),
        Math.floor(bottomRight[1]),
        Math.floor(bottomLeft[0]),
        Math.floor(bottomLeft[1]),
      ],
    });
    newBoundingBoxeData[`labels[${index}]label_type`] = box.typeId;
    newBoundingBoxeData[`labels[${index}]rating`] = box.rating;

    if (index === boudingBoxes.length - 1) {
      newBoundingBoxeData.datapoint = dataPointId;
    }
  }
  return newBoundingBoxeData;
};
// const getUpdatedBoundingBoxesData = (box) => {
//   return {
//     bbox_x1: Math.floor(box.start[0]),
//     bbox_y1: Math.floor(box.start[1]),
//     bbox_x2: Math.floor(box.end[0]),
//     bbox_y2: Math.floor(box.end[1]),
//     label_type: box.typeId,
//     rating: box.rating,
//   };
// };
const getUpdatedBoundingBoxesData = (box) => {
  const { topLeft, topRight, bottomLeft, bottomRight } = box;

  return {
    // bbox_x1: Math.floor(topLeft[0]),
    // bbox_y1: Math.floor(topLeft[1]),
    // bbox_x2: Math.floor(topRight[0]),
    // bbox_y2: Math.floor(topRight[1]),
    // bbox_x3: Math.floor(bottomRight[0]),
    // bbox_y3: Math.floor(bottomRight[1]),
    // bbox_x4: Math.floor(bottomLeft[0]),
    // bbox_y4: Math.floor(bottomLeft[1]),
    bbox: JSON.stringify({
      bounding_box: [
        Math.floor(topLeft[0]),
        Math.floor(topLeft[1]),
        Math.floor(topRight[0]),
        Math.floor(topRight[1]),
        Math.floor(bottomRight[0]),
        Math.floor(bottomRight[1]),
        Math.floor(bottomLeft[0]),
        Math.floor(bottomLeft[1]),
      ],
    }),
    label_type: box.typeId,
    rating: box.rating,
  };
};

export const getDataPointById = async (id) => {
  const url = new URL(getDataPointByCityUrl);
  url.searchParams.append("id", id);
  const accessToken = await getAWSToken();
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const response = await fetch(url.toString(), options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

export const getServerDate = (dateObject) => {
  // return "2020-10-26";
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const date = dateObject.getDate();

  const monthString = month > 9 ? `${month}` : `0${month}`;
  const dateString = date > 9 ? `${date}` : `0${date}`;
  return `${year}-${monthString}-${dateString}`;
};

export const getSummaryDataPoint = async (date) => {
  // let dateString;
  // if (date && typeof date.getMonth === "function") {
  //   dateString = getServerDate(date);
  // } else {
  //   dateString = getServerDate(new Date());
  // }
  const url = new URL(getDataPointByCityUrl);
  url.searchParams.append("raw", true);
  url.searchParams.append("date", date);
  url.searchParams.append("page_size", 50000);
  const accessToken = await getAWSToken();
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const response = await fetch(url.toString(), options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error(API_ERROR_MESSAGES.ACCESS_DENIED);
    } else if (response.status === 404) {
      error = new Error(API_ERROR_MESSAGES.PAGE_NOT_FOUND);
    } else if (response.status >= 500) {
      error = new Error(API_ERROR_MESSAGES.GENERAL_SERVER_ERROR);
    } else {
      error = new Error(API_ERROR_MESSAGES.UNDEFINED_ERROR);
    }
  }
  throw error;
};

/**
 * returns the rating title of a specific label_id (typeId)
 */
export const getRatingTitle = (menu, typeId) => {
  let ratingTitle = "";
  menu.map((item) => {
    if (typeId === item.id) {
      ratingTitle = item.ratingTitle;
      return ratingTitle;
    }
    if (ratingTitle != "") return ratingTitle;

    if (item.subMenu.length > 0) {
      ratingTitle = getRatingTitle(item.subMenu, typeId);
      if (ratingTitle !== "") {
        return ratingTitle;
      }
    }
    if (ratingTitle != "") return ratingTitle;
  });

  return ratingTitle;
};

export const getRatingTitleByLabel = (menu, label) => {
  let ratingTitle = "";
  menu.map((item) => {
    if (label === item.name) {
      ratingTitle = item.ratingTitle;
      return ratingTitle;
    }
    if (ratingTitle != "") return ratingTitle;
  });

  return ratingTitle;
};

export const getLabelSummary = async (params) => {
  const accessToken = await getAWSToken();
  const { deviceId, labelShift } = params;

  const { time_before, time_after } = labelShift;
  if (deviceId === undefined || !time_before || !time_after) return;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const url = new URL(getDataPointByCityUrl);
  // url.searchParams.append("date", date);
  url.searchParams.append("time_before", labelShift.time_before);
  url.searchParams.append("time_after", labelShift.time_after);
  url.searchParams.append("device_id", deviceId);
  url.searchParams.append("label_report", true);
  url.searchParams.append("enabled", "Y");

  const response = await fetch(url, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error("401 ACCESS DENIED");
    } else if (response.status === 404) {
      error = new Error("404 PAGE NOT FOUND");
    } else if (response.status >= 500) {
      error = new Error(response.status + " SERVER ERROR");
    } else {
      error = new Error(`${response.status} UNKNOWN ERROR`);
    }
  }
  throw error;
};

const REPORT_TEMPLATE = {
  recipients: [
    "lucien@irisradgroup.com",
    "haseebm@irisradgroup.com",
    "dkeaney@irisradgroup.com",
    "emilr@irisradgroup.com",
    "kevinx@irisradgroup.com",
    "marcjovenl@irisradgroup.com",
  ],
  // subject: "Labeling Report - {date}",
  body: "<p>TESTING: LABELING COMPLETED</p><br/><p>City name {city}</p><p>Date {date}</p><br/><p>Device ID {device}</p><p>Total # of data points {total}</p><p>Total # of data points labeled {labeled}</p><p>Total # of flags {flag}</p>",
  // data: {
  //   device: "TestDevice",
  //   city: "testCity",
  //   date: "2021-04-15",
  //   total: 12,
  //   labeled: 12,
  //   flag: 3,
  // },
};

export const sendReport = async (data, accessToken) => {
  const reportData = {
    ...REPORT_TEMPLATE,
    subject: `labeling Report -${data.date} `,
    data: data,
  };
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(reportData),
  };
  const response = await fetch(sendReportEmailUrl, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 401) {
      error = new Error("401 ACCESS DENIED");
    } else if (response.status === 404) {
      error = new Error("404 PAGE NOT FOUND");
    } else if (response.status >= 500) {
      error = new Error(response.status + " SERVER ERROR");
    } else {
      error = new Error(`${response.status} UNKNOWN ERROR`);
    }
  }
  throw error;
};

export const createWorkOrdersBatch = async (data) => {
  const reportData = {
    ...data.reportShift,
    device_id: data.deviceId,
  };

  if (data?.uploadFileContent) {
    reportData["asset_id_map"] = data.uploadFileContent;
  }
  const accessToken = await getAWSToken();
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(reportData),
  };
  const response = await fetch(CREATE_WORK_ORDERS_BATCH_URL, options);
  const responseJson = await response.json();
  let error;
  if (response.ok) {
    return responseJson;
  } else {
    if (response.status === 400) {
      error = new Error(
        "BAD REQUEST: " + responseJson?.message || "unknown message"
      );
    } else if (response.status === 401) {
      error = new Error("401 ACCESS DENIED");
    } else if (response.status === 404) {
      error = new Error("404 PAGE NOT FOUND");
    } else if (response.status >= 500) {
      error = new Error(response.status + " SERVER ERROR");
    } else {
      error = new Error(`${response.status} UNKNOWN ERROR`);
    }
  }
  throw error;
};

export const calculatePCI = async (data, menu, numOfLanes, widthPerLane) => {
  var pciObjects = [];
  var got_pci = false;

  data.boundingBoxes.map((key) => {
    let object = { ...key };

    const { start, end, id } = object;
    var pci_item = {};
    var severity = "";

    // split the label name into type and severity using the hyphen
    var type = object.type.split(" - ");
    // determine the severity from the last part of the label name
    switch (type[1]) {
      case "High Severity":
        severity = "high";
        break;
      case "Medium Severity":
        severity = "medium";
        break;
      case "Low Severity":
        severity = "low";
        break;
      default:
        severity = "error";
    }

    pci_item.damage_type = "";
    pci_item.severity = severity;

    //adjust rating to fit units
    let ratingTitle = getRatingTitleByLabel(menu, object.type);
    let correct = 1;
    if (ratingTitle == "sqft") {
      correct = 1 / 10.764; // convert sqft to m2
    } else if (ratingTitle == "ft") {
      correct = 1 / 3.281; // convert ft to m
    } else {
      correct = 1; // no correction
    }

    switch (type[0]) {
      case "Alligator Cracking":
        pci_item.damage_type = "alligator_cracking";
        break;

      case "Bleeding":
        pci_item.damage_type = "bleeding";
        break;

      case "Block Cracking":
        pci_item.damage_type = "block_cracking";
        break;

      case "Bumps and Sags":
        pci_item.damage_type = "bumps_sags";
        break;

      case "Corrugation":
        pci_item.damage_type = "corrugation";
        break;

      case "Depression":
        pci_item.damage_type = "depression";
        break;

      case "Edge Cracking":
        pci_item.damage_type = "edge_cracking";
        break;

      case "Joint Reflection Cracking":
        pci_item.damage_type = "joint_reflection_cracking";
        break;

      case "Lane/Shoulder Drop Off":
        pci_item.damage_type = "lane_should_drop_off";
        break;

      case "Long & Trans Cracking":
        pci_item.damage_type = "long_tran_cracking";
        break;

      case "Patching & Util Cut Patching":
        pci_item.damage_type = "patching";
        break;

      case "Polished Aggregate":
        pci_item.damage_type = "polished_aggregate";
        break;

      case "Potholes":
        pci_item.damage_type = "potholes";
        break;

      case "Railroad Crossings":
        pci_item.damage_type = "railroad_crossing";
        break;

      case "Rutting":
        pci_item.damage_type = "rutting";
        break;

      case "Shoving":
        pci_item.damage_type = "shoving";
        break;

      case "Slippage Cracking":
        pci_item.damage_type = "slippage";
        break;

      case "Swell":
        pci_item.damage_type = "swell";
        break;

      case "Weathering / Raveling":
        pci_item.damage_type = "weathering_raveling";
        break;

      case "Weathering / Surface":
        pci_item.damage_type = "weathering_surface";
        break;

      default:
      //
    }

    if (pci_item.damage_type !== "") {
      const label = {
        bbox_x1: start[0],
        bbox_x2: start[1],
        bbox_y1: end[0],
        bbox_y2: end[1],
        label_id: id,
      };
      pci_item.label = label;
      pciObjects.push(JSON.parse(JSON.stringify(pci_item))); // force a copy
      got_pci = true;
    }
  });

  if (got_pci) {
    let pci_final = {
      datapoint_id: data.id,
      lane_count: numOfLanes,
      lane_width: widthPerLane,
      pci_input: pciObjects,
    };
    try {
      let returnJSON = await getPCI(pci_final);
      return returnJSON;
    } catch (e) {
      alert("There was an error calculating PCI (" + e + ")");
    }
  } else {
    return null;
  }
};

export const sendFmeReport = async (params) => {
  const { time_before, time_after, deviceId } = params;
  const url = new URL(fmeReportUrl);

  url.searchParams.append("device", deviceId);
  url.searchParams.append("time_before", time_before);
  url.searchParams.append("time_after", time_after);

  return new Promise(async (resolve, reject) => {
    const response = await fetch(url);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      if (response.status === 401) {
        error = new Error("access_denied__error_message");
      } else if (response.status === 404) {
        error = new Error("page_not_found_error_message");
      } else if (response.status >= 500) {
        error = new Error("server_error_message");
      } else {
        error = new Error("general_error_message");
      }
      reject(error);
    }
  });
};

export const triggerAutoGeneratingWorkorderProcess = async (params) => {
  const {
    deviceId,
    reportShift: { time_after, time_before },
    uploadFileContent = "",
  } = params;
  const url = new URL(autoGenerateWorkorderUrl);

  url.searchParams.append("device_id", deviceId);
  url.searchParams.append("time_before", time_before);
  url.searchParams.append("time_after", time_after);

  /** body prepared for api call, either empty string or stringified uploaded datapoint id and asset id  */
  let body = "";
  if (uploadFileContent) {
    body = JSON.stringify(uploadFileContent);
  }

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: body,
    redirect: "follow",
  };

  return new Promise(async (resolve, reject) => {
    const response = await fetch(url, requestOptions);
    let error;
    if (response.ok) {
      const responseJson = await response.json();
      resolve(responseJson);
    } else {
      if (response.status === 401) {
        error = new Error("access_denied__error_message");
      } else if (response.status === 404) {
        error = new Error("page_not_found_error_message");
      } else if (response.status >= 500) {
        error = new Error("server_error_message");
      } else {
        error = new Error("general_error_message");
      }
      reject(error);
    }
  });
};

/**
 * Verify validity of an access token against the server.
 * @param {string} token
 * @returns
 */
export const verifyToken = async (token) => {
  const payload = {
    token,
  };
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return new Promise(async (resolve, reject) => {
    let error;
    const response = await fetch(verifyUrl, options).catch((e) => {
      error = e;
    });
    if (error) {
      // caught error due to fetch API
      reject(error);
    } else if (response.ok) {
      resolve(true);
    } else {
      reject("ACCESS DENIED");
    }
  });
};
/**
 * Change Log:
 *
 * Change Date: Jul 01, 2020
 *
 * @description
 * added documentation
 */

/**
 * Change Log:
 *
 * Change Date: Jul 15, 2020
 *
 * @description
 * updated getUserGroups.
 * Instead of resolve an arrray of strings,
 * it resolves an array of objects which represent
 * the user groups belong to logged in user.
 *
 * @see getUserGroups
 */

/**
 * Change Log:
 *
 * Change Date: Oct 09, 2020
 *
 * Description: restructured all async functions
 */

/**
 * Change Log:
 *
 * Change Date: Jan 21, 2021
 *
 * Description:
 * 1. added extra property "removedBoundingBoxes"
 *    for all returned data point, as a placehodler
 *    to keep the removed bounding boxes before they are
 *    removed from the server
 */

/**
 * Change Log:
 *
 * Change Date: Jun 10, 2021
 *
 * Description:
 *
 * read bounding information from "labels4pt" instead of "labels"
 * as the "labels4pt" contains information of the 4 points of the trapezoid, instead of
 * two points of a rectangle (start and end points (top left and bottom right points)) that are stored in "labels".
 *
 * // updated method
 * @see getBoundingBoxFromLabel
 * @see getBoudingBoxesUploadData
 */

/**
 * Change Log:
 *
 * Change Date: Aug 07, 2021
 *
 * Description:
 * 1. added calculatePCI method which sends bounding boxes
 * info to server and fetches calculated PCI value from there.
 *
 * 2. removed updateDevice API call due to feature is no long desired on the front end
 */

/**
 * Change Log:
 *
 * Change Date: Aug 12, 2021
 *
 * Description:
 * 1. commented out async call (updateDevice) which send API request to server to
 * change a device's skew adjustment due to such feature is not desired any more.
 */
