import { IrisButton, IrisModal } from "irisrad-ui";
import React, { useEffect, useState, useRef } from "react";

export default function QueryDataModal({ open, onClose, onConfirm }) {
  const [isShow, setIsShow] = useState(false);
  const [idString, setIdString] = useState("");

  const uploadInputRef = useRef(null);
  const textAreaRef = useRef(null);

  useEffect(() => {
    setIsShow(open);
  }, [open]);

  const handleOkayClick = () => {
    let invalidInputFound = false;
    const ids = idString.match(/(\d+)/g);

    if (ids.length === 0) {
      invalidInputFound = true;
    }
    ids.forEach((id) => {
      if (isNaN(Number(id)) || Number(id) <= 0) {
        invalidInputFound = true;
      }
    });

    if (invalidInputFound) {
      alert("invalid input is found, please check the ids");
    } else {
      if (typeof onConfirm === "function") {
        onConfirm(ids);
      }
    }
    onClose();
  };

  const handleFileUpload = (event) => {
    const loadedFile = uploadInputRef.current.files[0];
    if (loadedFile) {
      const reader = new FileReader();
      reader.readAsText(loadedFile);
      reader.onload = function (event) {
        const jsonContent = event.target.result;
        const jsonArray = JSON.parse(jsonContent);
        const ids = jsonArray.reduce((acc, element) => {
          acc.push(element.id);
          return acc;
        }, []);
        textAreaRef.current.value = ids.toString();
        setIdString(ids.toString());
      };
    }
  };

  return (
    <IrisModal open={isShow} onClose={onClose}>
      <div
        style={{
          width: "20em",
          height: "15em",
          padding: "0.5em 0.75em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <label>select file contains id</label> */}
        <input
          ref={uploadInputRef}
          type="file"
          accept="application/JSON"
          onChange={handleFileUpload}
        />
        <textarea
          ref={textAreaRef}
          style={{ width: "100%", flex: 1 }}
          placeholder={"ids, sepearte by ', ' or space"}
          onChange={(event) => {
            console.log(`event.target.value`, event.target.value);
            setIdString(event.target.value);
          }}
          value={idString}
        />
        <div
          style={{
            marginTop: "1rem",
            marginLeft: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IrisButton onClick={onClose} color="secondary">
            Cancel
          </IrisButton>
          <IrisButton
            style={{ marginLeft: "0.5rem" }}
            onClick={handleOkayClick}
            disabled={idString.trim() === ""}
          >
            Okay
          </IrisButton>
        </div>
      </div>
    </IrisModal>
  );
}
