/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * @author Lucien Chu
 * Create Date: Jul 01, 2020
 *
 * @description Login component
 */

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import logo from "../../images/iris-logo.png";
import { USER_NAME, PASSWORD, TOKEN, REFRESH_TOKEN } from "./localStorageKeys";
import { clearLocalStorage } from "../irisHeader/irisHeader";
import { connect } from "react-redux";
import {
  startLogin,
  loginReset,
} from "../../redux/reducers/loginReducer/loginActions";
import Spinner from "../../UI/spinner/Spinner";
import { setGlobalMessage } from "../../redux/reducers/globalReducer/globalActions";

import { IrisInput, IrisButton, IrisGrid } from "irisrad-ui";
import Auth from "@aws-amplify/auth";
import {
  API_ERROR_MESSAGES,
  verifyToken,
} from "../../awsBackend/awsBackendUtils";

import pkg from "../../../package.json";
const PASSWORD_MIN_LENGTH = 6;
const TAG = "Login.js";

/**
 * @summary check whether user data has been saved previously.
 *
 * @return {Boolean} wheter or not valid user existed
 */
const isUserExisted = () => {
  const userName = localStorage.getItem(USER_NAME);
  const accessToken = localStorage.getItem(TOKEN);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  return userName !== null && accessToken !== null && refreshToken !== null;
};

const Login = (props) => {
  const { isLoading, userData, error, history, onLoginReset } = props;

  const { onLoginError } = props;
  const [userName, setUserName] = useState(
    localStorage.getItem(USER_NAME) || ""
  );
  const [password, setPassword] = useState(
    localStorage.getItem(PASSWORD) || ""
  );

  useEffect(() => {
    if (error) {
      onLoginError(error);
      clearLocalStorage();
      onLoginReset();
    } else if (userData) {
      if (userData.adminGroup) {
        history.push("/welcome");
      } else if (userData.labelerGroup) {
        history.push("/");
      } else if (userData.employeeGroup) {
        history.push("/analysis");
      } else {
        clearLocalStorage();
        history.push("/login");
      }
    }
  }, [isLoading, userData, error, history, onLoginReset, onLoginError]);

  const { onLoginStart } = props;
  useEffect(() => {
    const userExisted = isUserExisted();

    if (userExisted) {
      const userName = localStorage.getItem(USER_NAME);
      const accessToken = localStorage.getItem(TOKEN);
      // verify token then execute auto login
      verifyToken(accessToken).then((result) => {
        if (result === true) {
          onLoginStart(userName, "", accessToken);
        }
      });
    }
  }, [onLoginStart]);

  const handleLogin = async () => {
    try {
      const user = await Auth.signIn(userName, password);
      props.onLoginStart(userName, password);
      console.log(`cognitoUser`, user);
    } catch (error) {
      console.error("error signing in", error);
      onLoginError({
        title: "Error",
        message: error.message,
      });
    }
  };
  /**
   * @summary a callback function for password text field's onKeyDown property
   *
   * @description this method listen for the "Enter" key press event from the password
   * textfield and then trigger the login function
   *
   * @param {Event} event
   */

  const handlePasswordKeyDown = (event) => {
    const key = event.key.toLowerCase();
    if (key === "enter" && password.trim().length > PASSWORD_MIN_LENGTH) {
      handleLogin();
    }
  };

  return (
    <div className="login-wrapper">
      {props.isLoading ? <Spinner /> : null}
      <div className="login-content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} className="img" alt="IrisLogo" />
          <h3 className="login-title">Municipal Road Dashboard</h3>
          <h4 className="iris-red-title">Version {pkg.version}</h4>
        </div>
        <form
          style={{ padding: "0 1rem" }}
          onSubmit={(e) => e.preventDefault()}
        >
          <IrisInput
            label="User name"
            autoFocus
            value={userName}
            onChange={(event) => setUserName(event.target.value)}
          />
          <IrisInput
            label="Password"
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={handlePasswordKeyDown}
          />

          <IrisButton
            style={{ marginLeft: "auto" }}
            disabled={password.trim().length <= PASSWORD_MIN_LENGTH}
            color="primary"
            onClick={handleLogin}
          >
            Login
          </IrisButton>
          <IrisGrid container justifyContent="center">
            <ul className="nav-list">
              <li>
                <a
                  href="mailto:emilr@irisradgroup.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Help
                </a>
              </li>
              &nbsp;
              <li>
                <a
                  href="https://www.irisradgroup.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About Us
                </a>
              </li>
            </ul>
          </IrisGrid>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.loginState.isLoading,
    error: state.loginState.error,
    userData: state.loginState.userData,
  };
};
// could be an object with action creator
const mapDispatchToProps = {
  onLoginStart: startLogin,
  onLoginReset: loginReset,
  onLoginError: setGlobalMessage,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));

/**
 * Change Log:
 *
 * Change Date: Jul 01, 2020
 *
 * @description
 * added documentation
 */

/**
 * Change Log:
 *
 * Change Date: Jul 15, 2020
 *
 * Description:
 * change auto login logic
 * 1. when user name and password are fetched from local storage,
 *    those info would be used to call login api so that new access token
 *    and refresh token would be updated.
 *    Note: this would solve 401 error when user's access token is expired since
 *    token expiration time is not yet implement.
 *
 * 2. after a user has logged in, user group would then be fetched from the back end
 *    and which would be set as current user in AuthContext.js.
 *    Note: not saving it into local storage in order to prevent someone would be
 *    able to change user's group id, which would be sent to the back end as auditor id
 */

/**
 * Change Log:
 *
 * Change Date: Oct 13, 2020
 *
 * Description: replace useContex with redux
 */

/**
 * Change Log:
 *
 * Change Date: Apr 26, 2021
 *
 * Description: repalce button and text field with corresponding
 * elements from iris-ui libraray
 */
