import { datadogRum } from "@datadog/browser-rum";

export const setupDatadog = (username) => {
  // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#identify-user-session
  datadogRum.setUser({
    name: username,
    // name: "lucien PC test manual start session replay",
  });

  const sessionReplaySampleRate =
    process.env.REACT_APP_DATADOG_REPLAY_SAMPLE_RATE || 0;
  // reference https://docs.datadoghq.com/real_user_monitoring/browser/
  datadogRum.init({
    // find values for this snippet from
    // UX Monitoring -> setup & configurations
    // find your app and find Edit Application
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: "us5.datadoghq.com",
    service: "iris-labeling-portal",
    sessionSampleRate: 100,
    sessionReplaySampleRate: parseInt(sessionReplaySampleRate),
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
};
