/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Sep 28, 2020
 *
 * Description: This file defined the inital state of the state of an unsigned-in user
 * and the reducer that is designed to intearct with it during the user's login process.
 */
import { actionTypes } from "./loginActions";

const initialState = {
  isLoading: false,
  error: null,
  userData: null,
  damageTypes: [],
  devices: [],
  labelTypes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.loginStarted:
      return { ...state, isLoading: true };

    case actionTypes.loginSucceeded:
      return { ...state, isLoading: false, userData: action.payload };

    case actionTypes.loginFailed:
      return { ...state, isLoading: false, error: action.payload };

    case actionTypes.setDamageTypes:
      return { ...state, damageTypes: action.payload };

    case actionTypes.setDevices:
      const a = { ...state, devices: action.payload };
      return { ...state, devices: action.payload };

    case actionTypes.setLabelTypes:
      return { ...state, labelTypes: action.payload };
    case actionTypes.loginReset:
      return { ...initialState };

    default:
      return state;
  }
};
export default reducer;

/**
 * Change Log:
 *
 * Change Date: Sep 28, 2020
 *
 * Description: filed created.
 */
