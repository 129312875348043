/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Jun 10, 2021
 *
 * Description: some util function for bounding box (trapezoid)
 */

"use strict";
const TOP_LEFT = "TOP-LEFT";
const TOP_RIGHT = "TOP-RIGHT";
const BOTTOM_RIGHT = "BOTTOM-RIGHT";
const BOTTOM_LEFT = "BOTTOM-LEFT";
const Positions = [TOP_LEFT, TOP_RIGHT, BOTTOM_LEFT, BOTTOM_RIGHT];

function getPointsOrientation(p0, p1, p2) {
  const { x: p0x, y: p0y } = p0;
  const { x: p1x, y: p1y } = p1;
  const { x: p2x, y: p2y } = p2;
  const v0 = { x: p1x - p0x, y: p1y - p0y };
  const v1 = { x: p2x - p0x, y: p2y - p0y };
  return v0.x * v1.y - v0.y * v1.x;
}

function getTrapezoidCenter(boundingBox) {
  const { topLeft, topRight, bottomRight, bottomLeft } = boundingBox;
  const lineTlTr = getLine(topLeft, topRight);
  const line23 = getLine(bottomLeft, bottomRight);
  const lineTlBl = getLine(topLeft, bottomLeft);
  const lineTrBr = getLine(topRight, bottomRight);
  const lineTlBr = getLine(topLeft, bottomRight);
  const lineTrBl = getLine(topRight, bottomLeft);
  const i0 = getIntersectionPoint(lineTlTr, line23);
  const i1 = getIntersectionPoint(lineTlBl, lineTrBr);
  const i2 = getIntersectionPoint(lineTlBr, lineTrBl);

  const minX = Math.min(
    Math.min(topLeft.x, topRight.x),
    Math.min(bottomLeft.x, bottomRight.x)
  );
  const maxX = Math.max(
    Math.max(topLeft.x, topRight.x),
    Math.max(bottomLeft.x, bottomRight.x)
  );
  const minY = Math.min(
    Math.min(topLeft.y, topRight.y),
    Math.min(bottomLeft.y, bottomRight.y)
  );
  const maxY = Math.max(
    Math.max(topLeft.y, topRight.y),
    Math.max(bottomLeft.y, bottomRight.y)
  );
  const validPoints = [i0, i1, i2].filter((point) => {
    const [x, y] = point;
    const isValid = !isNaN(x) && !isNaN(y);
    const isBounded = !(x < minX) && !(x > maxX) && !(y < minY) && !(y > maxY);
    return isValid && isBounded;
  });
  return validPoints[0];
}
/**
 * @description give 4 points of a trapezoid, determines the top left,
 * top right, bottom right, and bottom left points.
 *
 * @param {Array<Object>} points points of the trapezoid
 * @returns
 */
export function getPositionsOfPoints(points) {
  let topLeftPoint;
  let topRightPoint;
  let bottomRightPoint;
  let bottomLeftPoint;
  const sortedPoints = points.sort((a, b) => {
    if (a.x > b.x) {
      return 1;
    } else if (a.x < b.x) {
      return -1;
    }
    return 0;
  });
  const [cetnerX, centerY] = getTrapezoidCenter({
    topLeft: points[0],
    topRight: points[1],
    bottomRight: points[2],
    bottomLeft: points[3],
  });
  const [p0, p1, p2, p3] = sortedPoints;
  if (p0.y === p1.y) {
    if (p0.y < centerY) {
      topLeftPoint = p0;
      topRightPoint = p1;
      bottomLeftPoint = p2;
      bottomRightPoint = p3;
    } else {
      bottomLeftPoint = p0;
      bottomRightPoint = p1;
      topLeftPoint = p2;
      topRightPoint = p3;
    }
  } else {
    if (p0.y < centerY) {
      topLeftPoint = p0;
      bottomLeftPoint = p1;
      if (p2.y < centerY) {
        topRightPoint = p2;
        bottomRightPoint = p3;
      } else {
        bottomRightPoint = p2;
        topRightPoint = p3;
      }
    } else {
      topLeftPoint = p1;
      bottomLeftPoint = p0;
      if (p2.y < centerY) {
        topRightPoint = p2;
        bottomRightPoint = p3;
      } else {
        bottomRightPoint = p2;
        topRightPoint = p3;
      }
    }
  }
  return {
    topLeft: topLeftPoint,
    topRight: topRightPoint,
    bottomRight: bottomRightPoint,
    bottomLeft: bottomLeftPoint,
  };
}
// export function getCenter(boundingBox) {
//   const { topLeft, topRight, bottomRight, bottomLeft } = boundingBox;
//   const line0 = getLine(topLeft, bottomRight);
//   const line1 = getLine(bottomLeft, topRight);
//   return getIntersectionPoint(line0, line1);
// }

// https://www.quora.com/Geometry-How-do-I-calculate-the-center-of-four-X-Y-coordinates
/**
 * @description give a trapezoid, determine its center point position.
 *
 * @param boundingBox 4 points of a trapezoid whose top left, top right, bottom right, and bottom left points are well defined.
 * @returns
 */
export function getCenter(boundingBox) {
  const {
    topLeft: p0,
    topRight: p1,
    bottomRight: p2,
    bottomLeft: p3,
  } = boundingBox;
  const [x0, y0] = p0;
  const [x1, y1] = p1;
  const [x2, y2] = p2;
  const [x3, y3] = p3;
  const centeroidOfTriangle0 = [(x0 + x1 + x3) / 3, (y0 + y1 + y3) / 3];
  const centeroidOfTriangle1 = [(x1 + x2 + x3) / 3, (y1 + y2 + y3) / 3];
  return [
    (centeroidOfTriangle0[0] + centeroidOfTriangle1[0]) / 2,
    (centeroidOfTriangle0[1] + centeroidOfTriangle1[1]) / 2,
  ];
}
export function getLine(point0, point1) {
  const { x: x0, y: y0 } = point0;
  const { x: x1, y: y1 } = point1;
  const k = (y1 - y0) / (x1 - x0);
  const b = y1 - k * x1;
  return { k, b };
}
export function getIntersectionPoint(line0, line1) {
  const { k: k0, b: b0 } = line0;
  const { k: k1, b: b1 } = line1;
  const x = (b1 - b0) / (k0 - k1);
  const y = k0 * x + b0;
  return [x, y];
}

/**
 * @summary get the upper left and bottom right coordiate of a rectangle which would
 * contains the entire give trapezoid
 *
 * @param {Object} points points that defined a trapezoid
 * @returns the upper left and lower right of a rectangle
 */
export function getRectangularBoundingBoxCoordinates(points) {
  const [firstPoint, secondPoint, thirdPoint, fourthPoint] = [...points];
  const minX = Math.min(
    Math.min(firstPoint[0], secondPoint[0]),
    Math.min(thirdPoint[0], fourthPoint[0])
  );
  const minY = Math.min(
    Math.min(firstPoint[1], secondPoint[1]),
    Math.min(thirdPoint[1], fourthPoint[1])
  );
  const maxX = Math.max(
    Math.max(firstPoint[0], secondPoint[0]),
    Math.max(thirdPoint[0], fourthPoint[0])
  );
  const maxY = Math.max(
    Math.max(firstPoint[1], secondPoint[1]),
    Math.max(thirdPoint[1], fourthPoint[1])
  );
  const topLeft = [minX, minY];
  const width = maxX - minX;
  const height = maxY - minY;
  const firstR = getRatioedPoint(firstPoint, topLeft, width, height);
  const secondR = getRatioedPoint(secondPoint, topLeft, width, height);
  const thirdR = getRatioedPoint(thirdPoint, topLeft, width, height);
  const fourthR = getRatioedPoint(fourthPoint, topLeft, width, height);
  // console.log("firstR", firstR)
  // console.log("secondR", secondR)
  // console.log("thirdR", thirdR)
  // console.log("fourthR", fourthR)
  return {
    topLeft,
    bottomRight: [maxX, maxY],
    ratioedPoints: [firstR, secondR, thirdR, fourthR],
  };
}
function getRatioedPoint(point, respectPoint, width, height) {
  const [x, y] = point;
  const [rx, ry] = respectPoint;
  const ratioedX = (x - rx) / width;
  const ratioedY = (y - ry) / height;
  return [ratioedX, ratioedY];
}

/**
 * @summary get the area of the trapezoid bounding box
 *
 * @param {} boundingBox
 * @returns the area and height of the bounding box
 */
export function getBoundingBoxAreaAndHeight(boundingBox) {
  const { topLeft, topRight, bottomRight, bottomLeft } = boundingBox;
  const rect = getRectangularBoundingBoxCoordinates([
    topLeft,
    topRight,
    bottomRight,
    bottomLeft,
  ]);
  const height = rect.bottomRight[1] - rect.topLeft[1];
  const topWidth = topRight[0] - topLeft[0];
  const bottomWidth = bottomRight[0] - bottomLeft[0];
  const area = ((topWidth + bottomWidth) * height) / 2;
  return { area, height };
}

/**
 * get image size, width and height, based on resolution.
 *
 * @param {("720p"|"1080p"|"4k")} resolutionStr
 */
export function getImageSize(resolutionStr) {
  let size = { width: 1280, height: 720 };
  switch (resolutionStr) {
    case "4k":
      size = { width: 3840, height: 2160 };
      break;
    case "1080p":
      size = { width: 1920, height: 1080 };
      break;
    default:
      break;
  }
  return size;
}

export const compareBoundingBoxes = (g0, g1) => {
  const left = JSON.parse(JSON.stringify(g0));
  const right = JSON.parse(JSON.stringify(g1));
  const same = [];
  const inLeft = [];
  const inRight = [];

  left.forEach((g00) => {
    const { start, end } = g00;

    const [s0g0, s1g0] = start;
    const [e0g0, e1g0] = end;

    let foundInRight = false;
    right.forEach((g11) => {
      const { start, end } = g11;

      const [s0g1, s1g1] = start;
      const [e0g1, e1g1] = end;

      if (s0g0 === s0g1 && s1g0 === s1g1 && e0g0 === e0g1 && e1g0 === e1g1) {
        same.push(g11);
        foundInRight = true;
      }
    });

    if (!foundInRight) {
      inLeft.push(g00);
    }
  });

  right.forEach((g00) => {
    const { start, end } = g00;

    const [s0g0, s1g0] = start;
    const [e0g0, e1g0] = end;

    let foundInLeft = false;
    left.forEach((g11) => {
      const { start, end } = g11;

      const [s0g1, s1g1] = start;
      const [e0g1, e1g1] = end;

      if (s0g0 === s0g1 && s1g0 === s1g1 && e0g0 === e0g1 && e1g0 === e1g1) {
        foundInLeft = true;
      }
    });

    if (!foundInLeft) {
      inRight.push(g00);
    }
  });

  return {
    commons: JSON.parse(JSON.stringify(same)),
    inLeftOnly: JSON.parse(JSON.stringify(inLeft)),
    inRighOnly: JSON.parse(JSON.stringify(inRight)),
  };
};

/*================================================== algo to determined whether a point is inside a polygon or not START ================================================== */
// check wether a point is wihtin a polygon or not
//https://www.geeksforgeeks.org/how-to-check-if-a-given-point-lies-inside-a-polygon/
// Given three colinear points p, q, r,
// the function checks if point q lies
// on line segment 'pr'
function onSegment(p, q, r) {
  if (
    q.x <= Math.max(p.x, r.x) &&
    q.x >= Math.min(p.x, r.x) &&
    q.y <= Math.max(p.y, r.y) &&
    q.y >= Math.min(p.y, r.y)
  ) {
    return true;
  }
  return false;
}
// To find orientation of ordered triplet (p, q, r).
// The function returns following values
// 0 --> p, q and r are colinear
// 1 --> Clockwise
// 2 --> Counterclockwise
function triangleOrientation(p, q, r) {
  const val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
  if (val === 0) {
    return 0; // colinear
  }
  return val > 0 ? 1 : 2; // clock or counterclock wise
}
// The function that returns true if
// line segment 'p1q1' and 'p2q2' intersect.
function doIntersect(p1, q1, p2, q2) {
  // Find the four orientations needed for
  // general and special cases
  const o1 = triangleOrientation(p1, q1, p2);
  const o2 = triangleOrientation(p1, q1, q2);
  const o3 = triangleOrientation(p2, q2, p1);
  const o4 = triangleOrientation(p2, q2, q1);
  // General case
  if (o1 !== o2 && o3 !== o4) {
    return true;
  }
  // Special Cases
  // p1, q1 and p2 are colinear and
  // p2 lies on segment p1q1
  if (o1 === 0 && onSegment(p1, p2, q1)) {
    return true;
  }
  // p1, q1 and p2 are colinear and
  // q2 lies on segment p1q1
  if (o2 === 0 && onSegment(p1, q2, q1)) {
    return true;
  }
  // p2, q2 and p1 are colinear and
  // p1 lies on segment p2q2
  if (o3 === 0 && onSegment(p2, p1, q2)) {
    return true;
  }
  // p2, q2 and q1 are colinear and
  // q1 lies on segment p2q2
  if (o4 === 0 && onSegment(p2, q1, q2)) {
    return true;
  }
  // Doesn't fall in any of the above cases
  return false;
}
// Returns true if the point p lies
// inside the polygon[] with n vertices
function isInside(polygon, n, p) {
  // There must be at least 3 vertices in polygon[]
  if (n < 3) {
    return false;
  }
  // Create a point for line segment from p to infinite
  const extreme = { x: 10000, y: p.y };
  // Count intersections of the above line
  // with sides of polygon
  let count = 0,
    i = 0;
  do {
    let next = (i + 1) % n;
    // Check if the line segment from 'p' to
    // 'extreme' intersects with the line
    // segment from 'polygon[i]' to 'polygon[next]'
    if (doIntersect(polygon[i], polygon[next], p, extreme)) {
      // If the point 'p' is colinear with line
      // segment 'i-next', then check if it lies
      // on segment. If it lies, return true, otherwise false
      if (triangleOrientation(polygon[i], p, polygon[next]) == 0) {
        return onSegment(polygon[i], p, polygon[next]);
      }
      count++;
    }
    i = next;
  } while (i !== 0);
  // Return true if count is odd, false otherwise
  return count % 2 === 1; // Same as (count%2 == 1)
}

/**
 * @description determines whether a point is inside a trapezoid or not
 */
export function isInsideBoundingBox(
  { topLeft, topRight, bottomRight, bottomLeft },
  point
) {
  const points = [topLeft, topRight, bottomRight, bottomLeft];
  const result = isInside(points, points.length, point);
  return result;
}
/*==================================================  algo to determined whether a point is inside a polygon or not END  ================================================== */
