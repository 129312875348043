/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Sep 28, 2020
 *
 * Description: This file defined the inital state of the state of data points
 * and the reducer that is designed to intearct with it.
 */

import {
  DATA_POINT_ACTION_TYPES,
  SORT_DATA_POINT_BY,
} from "./dataPointActions";

const initialState = {
  isLoading: false,
  dataPoints: [],
  selectedData: null,
  previousUrl: null,
  nextUrl: null,
  MMSDefectObjects: null,
  roadRelatedIssues: null,
  totalDataPoints: 0,
  noDataPointsFlag: false,
  menu: [],
  allMenu: [],
  rootMenu: [],
  sortDataBy: SORT_DATA_POINT_BY.DATE_ASC,
  numOfLanes: 1,
  widthPerLane: 3.2,
};

const dataPointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_POINT_ACTION_TYPES.getDataPointStarted:
      return { ...state, isLoading: true };

    case DATA_POINT_ACTION_TYPES.getDataPointSucceeded:
      return { ...state, isLoading: false, ...action.payload };

    case DATA_POINT_ACTION_TYPES.getDataPointFailed:
      return { ...state, isLoading: false, error: action.payload };

    case DATA_POINT_ACTION_TYPES.updateSelectedData:
      return { ...state, selectedData: action.payload };

    case DATA_POINT_ACTION_TYPES.updateDataPoints:
      return { ...state, dataPoints: action.payload };
    case DATA_POINT_ACTION_TYPES.resetDataPoints:
      return { ...initialState };
    case DATA_POINT_ACTION_TYPES.resetNoDataPointsFlag:
      return { ...state, noDataPointsFlag: action.payload };

    case DATA_POINT_ACTION_TYPES.setSortDataPointsBy:
      return { ...state, sortDataBy: action.payload };

    case DATA_POINT_ACTION_TYPES.setMenu:
      return { ...state, menu: action.payload };

    case DATA_POINT_ACTION_TYPES.setNumOfLane: {
      return { ...state, numOfLanes: action.payload };
    }

    case DATA_POINT_ACTION_TYPES.setWidthPerLane: {
      return { ...state, widthPerLane: action.payload };
    }
    default:
      return state;
  }
};
export default dataPointsReducer;

/**
 * Change Log:
 *
 * Change Date: Sep 28, 2020
 *
 * Description: filed created.
 */

/**
 * Change Log:
 *
 * Change Date: Aug 07, 2021
 *
 * Description:
 * added numOfLanes and widthPerLane states
 */
