import { GLOBAL_ACTION_TYPES } from "./globalActions";

const initialState = {
  message: null,
  toastMessage: "",
  labelPreference: [],
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_ACTION_TYPES.setError:
      return { ...state, message: action.payload };
    case GLOBAL_ACTION_TYPES.setMessage:
      return { ...state, toastMessage: action.payload };
    case GLOBAL_ACTION_TYPES.setLabelPreference:
      return { ...state, labelPreference: action.payload };
    default:
      return state;
  }
};
export default globalReducer;
