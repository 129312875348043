/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Dec 01, 2021
 *
 * Description:
 * A component that slides up from the bottom of the webpage, demonstrates some
 * informative message, and slides down below the bottom of the page.
 */
import React, { useEffect, useRef } from "react";
import styles from "./irisToast.module.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setToastMessage } from "../../redux/reducers/globalReducer/globalActions";

let timeoutReference = null;

function IrisToast({
  message = "Toast message",
  duration = 3000,
  ...restProps
}) {
  const snackBarRef = useRef();
  const { setToastMessage } = restProps;
  // useEffect(() => {
  //   if (message === null || message === undefined || message.trim() === "") {
  //     return;
  //   }
  //   const snackBar = snackBarRef.current;
  //   if (!snackBar.classList.contains(styles.show)) {
  //     snackBar.classList.toggle(styles.show);
  //   }

  //   setTimeout(() => {
  //     snackBar.classList.remove(styles.show);
  //     if (message !== "") {
  //       // clean up the message
  //       setToastMessage("");
  //     }
  //   }, duration);
  // }, [message, duration, setToastMessage]);

  useEffect(() => {
    if (message === null || message === undefined || message.trim() === "") {
      return;
    }

    clearTimeout(timeoutReference);
    const snackBar = snackBarRef.current;
    if (!snackBar.classList.contains(styles.show)) {
      snackBar.classList.toggle(styles.show);
    }

    timeoutReference = setTimeout(() => {
      snackBar.classList.remove(styles.show);
      if (message !== "") {
        // clean up the message
        setToastMessage("");
      }
    }, duration);
  }, [message, duration, setToastMessage]);

  return (
    <div className={styles.snackbar} ref={snackBarRef}>
      <h3 style={{ whiteSpace: "pre-line" }}>{message}</h3>
    </div>
  );
}

IrisToast.propTypes = {
  message: PropTypes.string.isRequired,
  duration: PropTypes.number,
};

export default connect(null, { setToastMessage })(IrisToast);

/**
 * Change Log:
 *
 * Change Date: Feb 09, 2021
 *
 * Description: The component is moved from the bottom to the top of the page.
 *
 * If there were too many message to be shown, this component would cover the lower part
 * of the image for a while, which would hinder the labeler's process.
 *
 * In contrast, the upper part of the image has less probablity to contains and defects which the labeler
 * would concern.
 */
