"use strict";
const PARENT_LINKS_KEY = "parents";
// type ObjectOfAny = { [key: string]: ObjectOfAny & {[PARENT_LINKS_KEY]: string[]} };
export class TreeBuilder {
  /**
   *
   * @param {Array} objects array of objects
   * @param {string} rootKey the key whose values reference a parent relationship
   * @param {string} elementKey  a common key for all objects, ie: name, id, etc
   * @param {string} groupKey a key that added to nodes, whose values contains sub objects with shared property, ie: same parent, same deparement, etc
   * @param {string | number} value of this key would added as an elmenet of the parent's array wich represent ancesstor link from top parent to root parent
   */
  constructor(
    objects,
    rootKey,
    elementKey,
    groupKey,
    elementTitle,
    ancesstorLinkTitle
  ) {
    if (!(objects && rootKey && elementKey && groupKey && elementTitle)) {
      throw Error("Error from tree builder, not enough info is provided");
    }
    this._objects = [];
    /** e.g. "parentId" */
    this._rootKey = rootKey;
    /** e.g. "id" */
    this._elementKey = elementKey;
    /** e.g. "subMenu" */
    this._groupKey = groupKey;
    /** e.g. "name" */
    this._elementTitle = elementTitle;
    for (const obj of objects) {
      const copy = JSON.parse(JSON.stringify(obj));
      copy[this._groupKey] = [];
      this._objects.push(copy);
    }

    /** e.g. "parents" */
    this._ancesstor_link_title = ancesstorLinkTitle || PARENT_LINKS_KEY;

    this._tree = this.buildTree();

    this._rootNotes = this.getRootNotes();
  }
  buildTree() {
    const parentIds = this._objects.map((label) => label[this._rootKey]);
    const parentIdSet = new Set(parentIds);
    const parentIdArray = Array.from(parentIdSet);
    const allIds = this._objects.map((label) => label[this._elementKey]);
    /**
     * the id do not exist in any of the member
     * ie: an element's parent is none of the kind
     */
    const superParents = [];
    parentIdArray.forEach((id) => {
      const temp = id;
      allIds.indexOf(temp);
      if (allIds.indexOf(temp) < 0) {
        superParents.push(id);
      }
    });

    // at this point, superParents should be [0], because 0 is not the id of any elements

    /**
     * super parent
     */
    let sps = [];
    /**
     * placeholder for siblings
     */
    let temp = [];
    this._objects.forEach((label) => {
      if (label[this._rootKey] === superParents[0]) {
        sps.push(
          Object.assign(Object.assign({}, label), {
            [this._ancesstor_link_title]: [],
          })
        );
      } else {
        temp.push(
          Object.assign(Object.assign({}, label), {
            [this._ancesstor_link_title]: [],
          })
        );
      }
    });

    // at this point, tree only has one level of data, whose parent id is 0
    const tree = [...sps];

    /**
     * intermediate super parents array
     */
    let tempSuperParents = [];

    while (temp.length > 0) {
      for (let i = 0; i < temp.length; i++) {
        const current = temp[i];
        const root = current[this._rootKey];
        const index = sps.findIndex(
          (element) => element[this._elementKey] === root
        );
        // if sps has current element's parentID
        if (index >= 0) {
          const parent = sps[index];
          // if parent is disabled, set current object to be disabled
          if (parent.enabled === "N") {
            current.enabled = "N";
          }
          // const {  subGroup, parents, name: title } = parent;
          const subGroup = parent[this._groupKey];
          const parents = parent[this._ancesstor_link_title];
          const title = parent[this._elementTitle];
          current[this._ancesstor_link_title] = [...parents, title];

          if (subGroup) {
            subGroup.push(current);
          } else {
            sps[index][this._groupKey] = [current];
          }
          tempSuperParents.push(current);
          temp.splice(i, 1);
          i--;
        }
      }
      /**
       * update the super parent array
       */
      sps = tempSuperParents;
      tempSuperParents = [];
    }
    // sorting
    this.sortBranch(tree);

    return tree;
  }
  /**
   *
   * @param {Array<Object>} arr
   */
  sortBranch(arr) {
    arr.sort((a, b) => {
      const subA = a[this._groupKey];
      const subB = b[this._groupKey];
      if (subA.length > 0) this.sortBranch(subA);
      if (subB.length > 0) this.sortBranch(subB);

      const valueA = a[this._elementTitle];
      const valueB = b[this._elementTitle];
      if (valueA > valueB) {
        return 1;
      } else if (valueA < valueB) {
        return -1;
      }
      return 0;
    });
  }
  getBranchNodes(ele, arr) {
    const subMenu = ele[this._groupKey];

    if (Array.isArray(subMenu) && subMenu.length > 0) {
      for (const menu of subMenu) {
        this.getBranchNodes(menu, arr);
      }
    } else {
      arr.push(ele);
    }

    return arr;
  }

  getRootNotes() {
    let singles = [];
    for (const nodeMenu of this._tree) {
      const s = this.getBranchNodes(nodeMenu, []);
      singles = singles.concat(s);
    }
    return JSON.parse(JSON.stringify(singles));
  }
  get rootNotes() {
    return this._rootNotes || [];
  }

  getRootNotesOf(key) {
    return this._rootNotes.filter((note) => {
      return note[this._ancesstor_link_title].indexOf(key) > -1;
    });
  }

  get tree() {
    return JSON.parse(JSON.stringify(this._tree));
  }
}
