/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Nov 21, 2021
 *
 * Description: A component that prompts a user to select
 * a rating for a given label
 */
import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";

export default function RatingComponent(props) {
  const { defaultValue, values } = props;
  const { label } = props;
  const { onChange } = props;
  const { onSet } = props;
  const { type } = props;
  const { units } = props;
  const [selectedValue, setSelectedValue] = useState(
    type === "text" ? defaultValue : defaultValue.id || "Minor"
  );

  const handleChange = (event) => {
    let value = event.target.value;
    setSelectedValue(value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleTextChange = (event) => {
    let value = event.target.value;
    setSelectedValue(value);
    if (onSet) {
      onSet(event.target.value);
    }
  };

  const { keyEvent } = props;
  useEffect(() => {
    if (keyEvent) {
      document.addEventListener("keydown", keyEvent);
    }
    return () => {
      document.removeEventListener("keydown", keyEvent);
    };
  }, [keyEvent]);

  useEffect(() => {
    if (defaultValue.id) {
      setSelectedValue(defaultValue.id);
    } else {
      setSelectedValue(defaultValue);
      if (onSet) {
        onSet(defaultValue);
      }
    }
  }, [defaultValue]);

  return (
    <div style={{ width: "100%" }}>
      <FormControl style={{ width: "100%" }}>
        <InputLabel shrink id="Iris-dropdown-single-selection-label">
          {label}
        </InputLabel>
        {type === "select" ? (
          <div>
            <Select
              labelId="Iris-dropdown-single-selection"
              id="Iris-dropdown-single-selection"
              value={selectedValue}
              onChange={handleChange}
              displayEmpty
              onKeyPress={keyEvent}
            >
              {values.map((value, index) => (
                <MenuItem key={index} value={value.id}>
                  {value.id > 0 ? value.id : ""} - {value.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ color: "red" }}>
              Press <b>1</b> to <b>{values.length - 1}</b>
            </FormHelperText>
          </div>
        ) : (
          <span>
            <br />
            <input
              onChange={handleTextChange}
              style={{ width: 75 }}
              value={selectedValue}
            />
            {units}
          </span>
        )}
      </FormControl>
    </div>
  );
}

RatingComponent.propTypes = {
  // inital values shown to user
  defaultValue: PropTypes.object,
  // array of selection values
  values: PropTypes.array.isRequired,
  // custom title for this component
  label: PropTypes.string,
  // callback function which would be called when user tiggle a single slection box
  onChange: PropTypes.func,
  // custom keyEvent function that handles user key input when
  // user interact with this component
  keyEvent: PropTypes.func,
};
