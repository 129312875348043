/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Mar 28, 2020
 *
 * Description: This component is designed to protect a component against
 * a unsigned-in user.
 *
 * If such user tried to access a component by simply keying in the route,
 * he would be redirected to the log in component from where he could get his
 * credential from the server.
 */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
const PrivateRoute = ({ component: RouteComponent, userData, ...rest }) => {
  // Able to access to currentUser variable from Auth component
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!userData ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.loginState.userData,
  };
};

export default connect(mapStateToProps)(PrivateRoute);

/**
 * Change Log:
 *
 * Change Date: Mar 28, 2020
 *
 * Description: component created and documentated
 */
