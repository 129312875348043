const getDefaultUser = () => {
  return {
    accessToken: { token: "", exp: 0 },
    idToken: { token: "", exp: 0 },
    refreshToken: { token: "" },
    userName: "",
    userGroups: [],
  };
};

export const AWS_CHALLENGE_NAMES = {
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
};

export const AWS_PASSWORD_RULES = {
  AT_LEAST_EIGHT_CHARACTERS: "At leat 8 characters",
  MUST_HAVE_NUMERIC_CHARACTER: "Must have numeric character",
  MUST_HAVE_UPPERCASE_CHARACTER: "Must have uppercase character",
  MUST_HAVE_LOWERCASE_CHARACTER: "Must have lowercase character",
  MUST_HAVE_SPECIAL_SYMBOL: "Must have symbol character",
};

export const AWS_PASSWORD_MIN_LENGTH = 8;
export const getUserFromSession = (signInUserSession) => {
  const user = getDefaultUser();

  let {
    accessToken: accessTokenObj,
    idToken: idTokenObj,
    refreshToken: refreshTokenObj,
  } = user;
  if (signInUserSession) {
    const { accessToken, idToken, refreshToken } = signInUserSession;
    accessTokenObj.token = accessToken?.jwtToken || "";
    accessTokenObj.exp = accessToken?.payload?.exp * 1000 || 0;

    idTokenObj.token = idToken?.jwtToken || "";
    idTokenObj.exp = idToken?.payload?.exp * 1000 || 0;

    if (idToken?.payload) {
      user.userName = idToken.payload["cognito:username"] || "";
      user.userGroups = idToken.payload["cognito:groups"] || [];
    }
    // const userGroups = idToken?.payload?['cognitogroups'] || []

    refreshTokenObj.token = refreshToken?.token || "";

    return user;
  }
};
