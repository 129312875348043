import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./IrisSlider.module.scss";
function IrisSlider(props) {
  const {
    label,
    unit = "",
    value: inputValue,
    min,
    max,
    step = 1,
    onChange,
    disabled = false,
  } = props;

  const [value, setValue] = useState(inputValue);

  const handleValueChanged = (event) => {
    const numValue = Number(event.target.value);
    if (!isNaN(numValue)) {
      setValue(numValue);
      if (typeof onChange === "function") {
        onChange(numValue);
      }
    }
  };

  return (
    <div className={styles.slidecontainer}>
      <p>
        {label}: {value} {unit}
      </p>
      <input
        disabled={disabled}
        type="range"
        min={min}
        max={max}
        value={value}
        step={step}
        className={styles.slider}
        onChange={handleValueChanged}
      />
    </div>
  );
}

IrisSlider.propTypes = {
  label: PropTypes.string.isRequired,
  unit: PropTypes.string,
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  onChange: PropTypes.func,
};

export default IrisSlider;
