import { IrisModal } from "irisrad-ui";
import { IrisCarousel } from "../../UI/irisCarousel/IrisCarousel";
import React, { useState, useEffect } from "react";

export default function AccelerometerGraphic({ open, onClose, dataPoint }) {
  const [images, setImages] = useState(undefined);

  useEffect(() => {
    const id = dataPoint?.id;
    const city = dataPoint?.device_sn?.city.name?.toLowerCase();
    const tag = dataPoint?.device_sn?.tag?.toLowerCase();
    const date = dataPoint?.create_time?.split("T")[0];

    if (id && city && tag && date) {
      const images = [];
      ["acc_x", "acc_y", "acc_z"].map((acc) => {
        images.push(
          `https://iris-sensor-graphs.s3.ca-central-1.amazonaws.com/${city}/${tag}/${date}/${acc}/${id}_${acc}.png`
        );
      });
      console.log(`images`, images);
      setImages(images);
    }
  }, [setImages, dataPoint]);
  return (
    <div>
      <IrisModal open={open} closeOnBackdrlpClicked={true} onClose={onClose}>
        <IrisCarousel
          style={{ minWidth: "500px", maxWidth: "1500px", width: "70vw" }}
          images={images}
        />
      </IrisModal>
    </div>
  );
}
