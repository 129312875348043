export const getFlattenedMenuRecursively = (arr, menu) => {
  const subMenu = menu.subMenu;
  if (subMenu.length === 0) {
    arr.push(menu);
    return arr;
  } else {
    for (const sMenu of subMenu) {
      getFlattenedMenuRecursively(arr, sMenu);
    }
  }
  return arr;
};

export const flatMenu = (menu) => {
  const copy = JSON.parse(JSON.stringify(menu));
  let temp = [];
  for (const menu of copy) {
    const { name: parent } = menu;

    // temp.push({ ...menu, parentName: parent, label: menu.name });
    const copySubmenu = JSON.parse(JSON.stringify(menu.subMenu));
    copySubmenu.forEach((subMenu) => {
      subMenu.parentName = parent;
    });
    temp = temp.concat(copySubmenu);
  }
  return temp;
};

/**
 * @summary flatten the multi level menu into one-dimension menu
 *
 * @description while flattening the menu, keeps traks the parents links for the
 * root parent from most remote parent to its parent
 *
 * For instance, if a menu's (label's) parents field looks like this
 * parents: ["MMS", "Signage Issue", "Damaged Sign"], which means that
 * while "MMS" is the grand grand parent, the "Damaged Sign" is its parent
 *
 * @param {Array} menuArray an array of menu objects
 * @param {Array} rootArray menu tree
 */
export const recursiveMenu = (menuArray, rootArray, parents) => {
  let a = rootArray;

  for (const ma of menuArray) {
    let ps = parents.concat(ma.name);
    ma.parents = ps;
    if (ma.subMenu.length !== 0) {
      recursiveMenu(ma.subMenu, a, ps);
    } else {
      a.push(ma);
    }
  }
  return a;
};
