import React, { useState, useRef, useEffect } from "react";
import logo1 from "./iamges/01.jpg";
import logo2 from "./iamges/02.jpg";
import logo3 from "./iamges/03.jpg";
import logo4 from "./iamges/04.jpg";
import logo5 from "./iamges/05.jpg";
import logo6 from "./iamges/06.jpg";
import logo7 from "./iamges/07.jpg";

const IMAGES = [
  { src: logo1 },
  { src: logo2 },
  { src: logo3 },
  { src: logo4 },
  { src: logo5 },
  { src: logo6 },
  { src: logo7 },
];

// function plusSlides(currentIndexRef, n) {
//   currentIndexRef.current += n;
//   showSlides(currentIndexRef.current);
// }

// function currentSlide(currentIndexRef, n) {
//   currentIndexRef.current = n;
//   showSlides(currentIndexRef.current);
// }

function showSlides(targetIndex) {
  var i;
  var dots = document.getElementsByClassName("demo");
  var slides = document.getElementsByClassName("slide");
  var captionText = document.getElementById("caption");
  var index = targetIndex;
  if (index > slides.length) {
    index = slides.length - 1;
  }
  if (index < 0) {
    index = 0;
  }
  for (i = 0; i < slides.length; i++) {
    // slides[i].style.display = "none";
    slides[i].classList.remove("show");
  }
  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" active", "");
  }
  //   slides[index - 1].style.display = "block";
  slides[index].classList.toggle("show");
  dots[index].className += " active";
  const ele = dots[index];
  const size = ele.getBoundingClientRect();
  const center = size.x + size.width / 2;
  const p = ele.parentElement.parentElement;
  const pSize = p.getBoundingClientRect();
  const pCenter = (pSize.right - pSize.left) / 2;
  const diff = ele.offsetLeft - pCenter + ele.width / 2;
  p.scrollLeft = diff;
  console.log(`ele.offsetLeft`, ele.offsetLeft);
  captionText.innerHTML = dots[index].alt;
}
export function IrisCarousel({ images = IMAGES, ...props }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  console.log(`images`, images);
  useEffect(() => {
    showSlides(selectedImageIndex);
  }, [selectedImageIndex]);
  return (
    <div className="iris-carousel-wrapper" {...props}>
      {images.map((url, index) => (
        <div className="slide" key={index}>
          <div className="numbertext">
            {index + 1} / {images.length}
          </div>
          <img src={url} />
        </div>
      ))}

      <a
        className="prev"
        onClick={() => {
          // decrease index value
          let index = selectedImageIndex - 1;
          if (index < 0) {
            // point index to the last element of the images
            index = images.length - 1;
          }
          console.log(`index`, index);
          setSelectedImageIndex(index);
        }}
      >
        ❮
      </a>
      <a
        className="next"
        onClick={() => {
          // increase index value
          let index = selectedImageIndex + 1;
          if (index >= images.length) {
            // point index to the first element of the images
            index = 0;
          }
          console.log(`index`, index);
          setSelectedImageIndex(index);
        }}
      >
        ❯
      </a>

      <div className="caption-container">
        <p id="caption"></p>
      </div>

      <div className="row">
        {images.map((url, index) => (
          <div key={index}>
            <img
              className="demo cursor"
              src={url}
              onClick={() => setSelectedImageIndex(index)}
              alt="acc"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
