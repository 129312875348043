import { Auth } from "aws-amplify";

/**
 * get cached or refreshed (if expired) aws access token
 * @returns aws access token
 */
export const getAWSToken = async () => {
  const res = await Auth.currentAuthenticatedUser();
  const accessToken = res.signInUserSession?.accessToken?.jwtToken;
  return accessToken;
};
