import React, { useEffect } from "react";
import {
  InputLabel,
  TextField,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";

import SendIcon from "@material-ui/icons/Send";
import { IRISButton } from "../../UI/button/IRISButton";
import "react-google-places-autocomplete/dist/index.min.css";

const DEFAULT_REPORT_TYPE = "Please Select a Report Type";

const reportArray = [
  { id: 0, name: DEFAULT_REPORT_TYPE },
  { id: 1, name: "Distance Report" },
  { id: 2, name: "Labeler Report" },
];
export const SearchBar = (props) => {
  return (
    <div
      tabIndex={1}
      onKeyDown={(event) => {
        if (event.keyCode === 13) {
          props.handleSearch();
        }
      }}
    >
      <Grid container style={{ paddingTop: "2rem" }} justify="flex-end">
        <Grid container item spacing={3} justify="flex-end" sm={11}>
          <Grid container item md={11} lg={5} spacing={1} justify="flex-start">
            <Grid item md={6} sm={3} xs={10}>
              <InputLabel>Report Type</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="reportSelection"
                value={props.searchParams.option}
                onChange={(event) => {
                  props.editSearch({
                    option: event.target.value,
                    month: props.searchParams.month,
                    year: props.searchParams.year,
                  });
                }}
              >
                {reportArray.map((reportObj) => {
                  return (
                    <MenuItem value={reportObj.id} key={reportObj.id}>
                      {reportObj.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
          <Grid container item md={6} lg={7} spacing={3} justify="flex-start">
            <Grid item md={4} sm={3} xs={10}>
              <TextField
                label="Month"
                onChange={(event) => {
                  props.editSearch({
                    month: event.target.value,
                    year: props.searchParams.year,
                    option: props.searchParams.option,
                  });
                }}
                value={props.searchParams.month}
              />
            </Grid>
            <Grid item md={4} sm={3} xs={10}>
              <TextField
                label="Year"
                onChange={(event) => {
                  props.editSearch({
                    month: props.searchParams.month,
                    year: event.target.value,
                    option: props.searchParams.option,
                  });
                }}
                value={props.searchParams.year}
              />
            </Grid>
            <Grid item md={4} sm={3} xs={10}>
              <IRISButton onClick={props.handleSearch} endIcon={<SendIcon />}>
                Search
              </IRISButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
