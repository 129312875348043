/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Sep 28, 2020
 *
 * Description: This file defines some actions that interact with the state
 * about data points.
 */

import { getAllData } from "../../../awsBackend/awsBackendUtils";
import { listDefectTypes } from "../../../awsBackend/irisDataUtils";
import { TreeBuilder } from "../../../utils/MenuTree";
import { getAWSToken } from "../../../utils/cognitoUtils";
import {
  setGlobalMessage,
  setLabelPrefence,
} from "../globalReducer/globalActions";
export const SORT_DATA_POINT_BY = {
  DATE_ASC: "Date Asc",
  DATE_DESC: "Date Desc",
  LABEL_ACC: "Label Asc",
  Label_DESC: "Label Desc",
};

export const DATA_POINT_ACTION_TYPES = {
  getDataPointStarted: "GET DATA POINTS STARTED",
  getDataPointSucceeded: "GET DATA POINTS SUCCEEDED",
  getDataPointFailed: "GET DATA POINTS FAILED",
  resetDataPoints: "RESET DATA POINTS STATE",
  updateSelectedData: "UPDATE SELECTED DATA",
  updateDataPoints: "UPDATE DATA POINTS",
  resetNoDataPointsFlag: "RESET NO DATA POINTS FLAG",
  setSortDataPointsBy: "SET SORT DATA POINTS BY",
  setMenu: "SET MENU",
  setNumOfLane: "SET NUM OF LANES",
  setWidthPerLane: "SET WIDTH PER LANE",
};
export const startGettingDataPoints = (params) => {
  return async (dispatch, getState) => {
    dispatch(getDataPointStarted());
    try {
      const result = await getAllData(params);
      const { damageTypes, roadIssues, labelType } = result;

      let alteredLabels = labelType.map((label) => {
        const { rating_range, range_title, parent, city_id } = label;
        label.ratingRange = rating_range;
        label.ratingTitle = range_title;
        label.parentId = parent;

        label.cityId = city_id;

        delete label.rating_range;
        delete label.range_title;
        delete label.parent;
        delete label.city_id;

        return label;
      });

      // this defect type is set up for BoundingBoxMenu component
      const awsToken = await getAWSToken();
      const defectTypeIrisData = await listDefectTypes(awsToken);
      const defectNames = defectTypeIrisData.map((ele) => ele.name);

      const DUPLICATES = {
        "Bridge Deck Spall": 455,
        "Pothole Paved Surface": 402,
        "Pothole Shoulder": 404,
        Debris: 407,
      };
      alteredLabels = alteredLabels.filter((ele) => {
        const foundId = DUPLICATES[ele.name];

        if (defectNames.indexOf(ele.name) > -1) {
          if (!foundId) {
            return true;
          }
          if (foundId === ele.id) {
            return true;
          }
        }
      });

      alteredLabels.forEach((ele) => {
        // parent child relation about defect type is no longer valid,
        // set to null so that the build tree proccess will be okay
        ele.parentId = null;
      });

      const menuTree = new TreeBuilder(
        alteredLabels,
        "parentId",
        "id",
        "subMenu",
        "name"
      );
      // console.log(`menuTree`, menuTree.tree, menuTree.rootNotes);
      const labelPreference = menuTree.tree.map((m) => m.name);
      dispatch(setLabelPrefence(labelPreference));

      const { previous, next, results, count } = result.dataPointObj;
      const filteredDataPoints = results.filter((data) => data.image !== null);
      const dataPointsWithLabelName = mergeDataWithLabel(
        filteredDataPoints,
        labelType
      );

      const sortBy =
        getState()?.dataPointsState?.sortDataBy || SORT_DATA_POINT_BY.DATE_ASC;
      const sortedData = getSortedData(dataPointsWithLabelName, sortBy);

      const newSelectedData =
        sortedData[0] === undefined
          ? null
          : JSON.parse(JSON.stringify(sortedData[0]));
      const noIssue = roadIssues.find(
        (issue) => issue.issue.toLowerCase() === "none"
      );
      const withoutIssues = roadIssues.filter(
        (issue) => issue.issue.toLowerCase() !== "none"
      );

      const updatedIssue = [noIssue].concat(withoutIssues);

      const allMenu = menuTree.rootNotes;
      // find root parent for each data point's bounding box
      sortedData.map((data) => {
        const { boundingBoxes } = data;
        for (const box of boundingBoxes) {
          const foundMenu = allMenu.find((menu) => menu.id === box.typeId);
          if (foundMenu) {
            box.rootParent = foundMenu.parents[0];
          }
        }
        data.boundingBoxesOriginal = JSON.parse(JSON.stringify(boundingBoxes));
      });
      // console.log(`allMenu`, allMenu, sortedData);

      const dataPointsState = {
        dataPoints: sortedData,
        selectedData: newSelectedData,
        previousUrl: previous,
        nextUrl: next,
        MMSDefectObjects: damageTypes,
        roadRelatedIssues: updatedIssue,
        totalDataPoints: count,
        noDataPointsFlag: count === 0,
        // menu: menu,
        menu: menuTree.tree,
        allMenu: menuTree.tree,
        rootMenu: menuTree.rootNotes,
      };
      dispatch(getDataPointSucceeded(dataPointsState));
    } catch (error) {
      const errorObject = {
        title: "Error",
        message: error.message,
      };
      dispatch(setGlobalMessage(errorObject));
      dispatch(
        getDataPointFailed({
          title: "Error in gettting data points",
          message: "please login and try again",
        })
      );
    }
  };
};

const getDataPointStarted = () => ({
  type: DATA_POINT_ACTION_TYPES.getDataPointStarted,
});
export const getDataPointSucceeded = (dataPoints) => ({
  type: DATA_POINT_ACTION_TYPES.getDataPointSucceeded,
  payload: dataPoints,
});
export const getDataPointFailed = (error) => ({
  type: DATA_POINT_ACTION_TYPES.getDataPointFailed,
  payload: error,
});
export const resetDataPointsState = () => ({
  type: DATA_POINT_ACTION_TYPES.resetDataPoints,
});

export const updateSelectedData = (dataPoint) => ({
  type: DATA_POINT_ACTION_TYPES.updateSelectedData,
  payload: dataPoint,
});
export const updateDataPoints = (dataPoints) => ({
  type: DATA_POINT_ACTION_TYPES.updateDataPoints,
  payload: dataPoints,
});

export const resetNoDataPointsFlag = () => ({
  type: DATA_POINT_ACTION_TYPES.resetNoDataPointsFlag,
  payload: false,
});

export const setSortDataPointsBy = (value) => ({
  type: DATA_POINT_ACTION_TYPES.setSortDataPointsBy,
  payload: value,
});

export const setMenu = (menu) => ({
  type: DATA_POINT_ACTION_TYPES.setMenu,
  payload: menu,
});

export const setNumOfLanes = (numOfLanes) => ({
  type: DATA_POINT_ACTION_TYPES.setNumOfLane,
  payload: numOfLanes,
});

export const setWidthPerLane = (widthPerLane) => ({
  type: DATA_POINT_ACTION_TYPES.setWidthPerLane,
  payload: widthPerLane,
});

export const sortMenuByName = (arr) => {
  const copied = [...arr];
  copied.sort(function (a, b) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  return copied;
};

function mergeDataWithLabel(data, labelTypes) {
  const copy = JSON.parse(JSON.stringify(data));
  const enabledLabelTypes = labelTypes;
  for (const data of copy) {
    const labels = [];
    for (const label of data.labels) {
      const { label_type: labelId } = label;
      const foundLabel = enabledLabelTypes.find(
        (label) => label.id === labelId
      );
      if (foundLabel) {
        label.name = foundLabel.name;
        if (label.name !== "Crack") {
          console.log(`id`, data.id);
        }
      }
      labels.push({ ...label });
    }
    const sortedLabel = sortArrayOfObjects(labels, "name", false);
    data.labels = sortedLabel;
  }
  return copy;
}

export const getSortedData = (data, sortingRule) => {
  let result = JSON.parse(JSON.stringify(data));
  switch (sortingRule) {
    case SORT_DATA_POINT_BY.DATE_ASC:
      result = sortArrayOfObjects(data, "create_time", true);
      break;
    case SORT_DATA_POINT_BY.DATE_DESC:
      result = sortArrayOfObjects(data, "create_time", false);
      break;
    case SORT_DATA_POINT_BY.LABEL_ACC:
      result = sortArrayOfObjectBasedOnObjectsArray(
        data,
        "labels",
        "name",
        true
      );
      break;
    case SORT_DATA_POINT_BY.Label_DESC:
      result = sortArrayOfObjectBasedOnObjectsArray(
        data,
        "labels",
        "name",
        false
      );
      break;
    default:
      break;
  }
  return result;
};

// sort arry of object based on one property's string value alphabetically
const sortArrayOfObjects = (arr, propertyName, acc = false) => {
  const copied = [...arr]; // shallow copied, if it is nested object, would be a problem
  copied.sort((objectA, objectB) => {
    const valueA = objectA[propertyName].toUpperCase(); // ignore upper and lowercase
    const valueB = objectB[propertyName].toUpperCase(); // ignore upper and lowercase
    if (acc) {
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }

      return 0; // names must be equal
    } else {
      if (valueA > valueB) {
        return -1;
      }
      if (valueA < valueB) {
        return 1;
      }

      return 0; // names must be equal
    }
  });
  return copied;
};

// sort arry of object based on one property's string value alphabetically
const sortArrayOfObjectBasedOnObjectsArray = (
  arr,
  propertyNameA,
  propertyNameB,
  acc = false
) => {
  const copied = [...arr]; // shallow copied, if it is nested object, would be a problem
  copied.sort((elementA, elementB) => {
    const valueA = elementA[propertyNameA][0]?.[propertyNameB].toUpperCase(); // ignore upper and lowercase
    const valueB = elementB[propertyNameA][0]?.[propertyNameB].toUpperCase(); // ignore upper and lowercase
    if (acc) {
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }

      return 0; // names must be equal
    } else {
      if (valueA > valueB) {
        return -1;
      }
      if (valueA < valueB) {
        return 1;
      }

      return 0; // names must be equal
    }
  });
  return copied;
};

/**
 * Change Log:
 *
 * Change Date: Sep 28, 2020
 *
 * Description: file created.
 */

/**
 * Change Log:
 *
 * Change Date: Feb 02, 2021
 *
 * Description: Added cityId property for every menu element in order to
 * dynamically load bounding box menu for data points gethered by different
 * cities.
 *
 * When the menu array is sent to the BoundingBoxMenu component, the
 * city id would be used to check against the one recoreded in the data point.
 * Only menu element(s) with the same id would be rendered within the BoundingBoxMenu
 * component.
 * @see makeMenu
 */

/**
 * Change Log:
 *
 * Change Date: Aug 07, 2021
 *
 * Description:
 * 1. removed makeMenu and its associated methods
 * 2. introduce TreeBuilder class which is used to build menu
 * tree and get the root menu, replacing makeMenu and its associated methods
 *
 */
