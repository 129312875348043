import React, { Component } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const keysArray = [
  null,
  { id: "id", data: "city", value: "mileage" },
  { id: "id", data: "labeler", value: "view_count" },
];

const AnalysisCell = styled(TableCell)({
  fontWeight: "bold",
  fontSize: "120%",
  color: "grey",
  height: 50,
  textAlign: "center",
});

class AnalysisItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.data[keysArray[props.option]["id"]],
      data: props.data[keysArray[props.option]["data"]],
      value: props.data[keysArray[props.option]["value"]],
    };
  }

  getStyle() {
    const { type, isActive } = this.props;
    // default style for pci, red if type.toLowerCase() !== "none"
    let defaultPCIStyle = { color: "black" };
    if (type && type.join(", ").toLowerCase() === "none") {
      defaultPCIStyle = { color: "#d00205" }; // pci style in red style if type is "none"
    } else if (isActive) {
      defaultPCIStyle = { color: "white" };
    }

    return defaultPCIStyle;
  }

  render() {
    return (
      <TableRow>
        <AnalysisCell>{this.state.id}</AnalysisCell>
        <AnalysisCell>{this.state.data}</AnalysisCell>
        <AnalysisCell>{this.state.value}</AnalysisCell>
      </TableRow>
    );
  }
}

export default AnalysisItem;
