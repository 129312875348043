/**
 * IRIS R&D Group Inc. All rights reserved.
 *
 * Author: Lucien Chu
 * Create Date: Aug 27, 2020
 *
 * Description:
 *
 * Header contains company logo and page title, along with
 * interal nav links, user account icon and title and log
 * out button
 */

import React, { useState, useEffect } from "react";

import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import logo from "../../assets/logo.png";
import { USER_NAME } from "../login/localStorageKeys";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { loginReset } from "../../redux/reducers/loginReducer/loginActions";
import { makeStyles } from "@material-ui/core";
import { toggleShortcutListDisplayStyle } from "../irisShortCutKeyList/irisShortcutKeyList";
import { userManualUrl } from "../../awsBackend/backendURLs";

export const clearLocalStorage = () => {
  const userName = localStorage.getItem(USER_NAME);

  localStorage.clear();
  localStorage.setItem(USER_NAME, userName);
};

let dataPointSize = 0;
const renderMenuItem = (linkTitle, pathName, isActive) => {
  return (
    <MenuItem
      style={{ margin: "0.125rem" }}
      className={`menu-link ${isActive ? "active" : ""}`}
    >
      <Link className="menu-text" to={pathName}>
        {linkTitle}
      </Link>
    </MenuItem>
  );
};

const useStyles = makeStyles(() => ({
  menuItem: {
    fontSize: "1.7rem;",
  },
}));

const IrisHeader = (props) => {
  const { title, links } = props;

  const { dataPoints } = props; // passed by redux
  //   const classes = useStyles();
  const [auth] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const classes = useStyles();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    const menuOption = event.currentTarget.textContent;
    if (menuOption === "Log out") {
      //logout event
      clearLocalStorage();
      props.onLoginReset();
      props.history.push("/login");
    } else if (menuOption === "Profile") {
      //profile method goes here
    } else if (menuOption === "Shortcuts") {
      toggleShortcutListDisplayStyle();
    } else if (menuOption === "User Manual") {
      window.open(userManualUrl);
    }
    setAnchorEl(null);
  };
  const toggle = () => {
    const searchBox = document.getElementById("sb");
    searchBox.classList.toggle("show");
    const searchToggle = document.getElementById("searchToggle");
    searchToggle.classList.toggle("active");

    const content = document.getElementById("content");
    content.classList.toggle("show");
  };

  const GetUserProfile = () => {
    return (
      <span className="user-profile-name">
        {localStorage.getItem(USER_NAME)}
      </span>
    );
  };

  /*================================================== short cut key for toggling hidden search bar ================================================== */

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  });

  /**
   * A trick to shwo search bar (when data size = 0);
   * and auto hide search bar (when data size != 0);
   *
   * Not a good practice, though it works
   */
  useEffect(() => {
    // auto hide search bar component when size of data point is 0
    if (dataPoints.length === 0) {
      dataPointSize = 0;
      toggle();
    }
    // auto hide search bar component when size of data point is not 0
    else if (dataPointSize === 0 && dataPoints && dataPoints.length > 0) {
      dataPointSize = 1;
      toggle();
    }
  }, [dataPoints]);

  const handleKeyUp = (event) => {
    if (event.target.tagName.toUpperCase() === "INPUT") return;
    if (event.shiftKey && event.keyCode === 83) {
      toggle();
    }
  };

  return (
    <header className="header-wrapper">
      <div className="logo-title-container">
        <img className="image" src={logo} alt="iris logo" />
        <h1 className="header-title">{title}</h1>
      </div>

      <div className="link-and-user-icon">
        {props.currentUser.groups.find((user) => user.name === "admin") &&
          links.map((linkObject, index) => (
            <div key={index}>
              {renderMenuItem(
                linkObject.title,
                linkObject.pathname,
                linkObject.pathname === props.location.pathname
              )}
            </div>
          ))}

        <div onClick={toggle}>
          <a href="#" id="searchToggle" className="menu-text search-toggle" />
        </div>
        <div id="content" className="hidden-content">
          {props.children}
        </div>
        <div id="sb"></div>
        {auth && (
          <div className="user-profile-container">
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle fontSize="large" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} className={classes.menuItem}>
                Profile
              </MenuItem>
              <MenuItem onClick={handleClose} className={classes.menuItem}>
                Shortcuts
              </MenuItem>
              <MenuItem onClick={handleClose} className={classes.menuItem}>
                User Manual
              </MenuItem>
              <MenuItem onClick={handleClose} className={classes.menuItem}>
                Log out
              </MenuItem>
            </Menu>
            <GetUserProfile />
          </div>
        )}
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.loginState.userData,
    dataPoints: state.dataPointsState.dataPoints,
  };
};

const mapDispatchToProps = {
  onLoginReset: loginReset,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IrisHeader));

/**
 * Change Log:
 *
 * Change Date: Aug 27, 2020
 *
 * Description: element is created and documented.
 */

/**
 * Change Log:
 *
 * Change Date: Jan 28, 2021
 *
 * Description:
 * Removed toggling the shortcult list component by toggling the route,
 * instead, toggle it with the function that the element exported
 *
 * @see toggleShortcutListDisplayStyle
 */

/**
 * Change Log:
 *
 * Change Date: Mar 30, 2021
 *
 * Description: added download button on the menu for user to download the hosted
 * user manual from aws
 */
