import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
// import DataItem from "../../../components/DataItem";
import AnalysisItem from "./AnalysisItem";

import { IRIS_RED } from "../../UI/uiConstants";

import { IRISButton } from "../../UI/button/IRISButton";
import GetAppIcon from "@material-ui/icons/GetApp";
const TAG = "IRISAnalysisTable.js";

const MyTable = (props) => {
  // table columns
  const tableCellsObjectArray = [
    { value: "ID", tableCellFunction: null },
    { value: "Data", tableCellFunction: null },
    { value: "Value", tableCellFunction: null },
  ];
  // variables
  const { data, selectedIndexFromMap, resetTable, toggleRestTable } = props;
  // functions

  const [dataPoints, setDataPoints] = useState([]);

  const [page, setPage] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [autoFocusDateIndex, setAutoFocusDateIndex] = useState(
    selectedIndexFromMap
  );

  useEffect(() => {
    setAutoFocusDateIndex(selectedIndexFromMap);
    setDataPoints(data);
    if (resetTable) {
      setPage(0);
      setActiveIndex(0);
      setAutoFocusDateIndex(0);
      toggleRestTable();
    }
  }, [selectedIndexFromMap, data, resetTable, toggleRestTable]);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    setActiveIndex(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setActiveIndex(0);
  };

  const handleChangeActiveIndex = (event, newActiveIndex) => {
    event.preventDefault();
    // start of current page, moving to previous page
    if (newActiveIndex < 0) {
      if (page > 0) {
        setActiveIndex(rowsPerPage - 1); // end of the previous page
        setPage(page - 1);
      } else {
        return;
      }
    }
    // end of current page, moving to next page
    else if (newActiveIndex >= rowsPerPage) {
      if (
        data[(page + 1) * rowsPerPage] === undefined ||
        data[(page + 1) * rowsPerPage] == null
      ) {
        // setActiveIndex(0);
      } else {
        setActiveIndex(0); // start of next page
        setPage(page + 1);
      }
    } else {
      setActiveIndex(newActiveIndex);
    }
  };

  // const handleTableNaviation = (event) => {
  //   if (
  //     dataPoints !== null &&
  //     dataPoints !== undefined &&
  //     dataPoints.length !== 0
  //   ) {
  //     switch (event.keyCode) {
  //       case 38:
  //         handleChangeActiveIndex(event, activeIndex - 1);
  //         break;
  //       case 40:
  //         handleChangeActiveIndex(event, activeIndex + 1);
  //         break;
  //       case 37:
  //         handleChangePage(event, page - 1);
  //         break;
  //       case 39:
  //         handleChangePage(event, page + 1);
  //         break;
  //       default:
  //     }
  //   }
  // };
  return (
    <>
      <TableContainer
        component={Paper}
        tabIndex={1}
        //onKeyDown={(event) => handleTableNaviation(event)}
      >
        <Table aria-label="custom pagination table" size="small">
          <TableHead>
            <TableRow>
              {tableCellsObjectArray.map((cell) => {
                return (
                  <TableCell
                    key={cell.value}
                    align="center"
                    onClick={cell.tableCellFunction}
                  >
                    {cell.value}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataPoints.map((item, index) => {
              return (
                <AnalysisItem
                  dataIndex={rowsPerPage * page + index}
                  key={index}
                  data={item}
                  option={props.option}
                  isActive={activeIndex === index % rowsPerPage}
                  activeIndex={index % rowsPerPage}
                  setActiveIndex={setActiveIndex}
                />
              );
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 50 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <IRISButton
        onClick={props.handleExport}
        style={{ margin: 8 }}
        endIcon={<GetAppIcon />}
      >
        Export
      </IRISButton>
      {/* <TablePagination
        style={{ width: "85%" }}
        rowsPerPageOptions={[10, 15, 20]}
        colSpan={3}
        count={dataPoints.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
        component="div"
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={(props) => (
          <IRISTablePagination
            {...props}
            activeIndex={activeIndex}
            onChangedActiveIndex={handleChangeActiveIndex}
          />
        )}
      /> */}
    </>
  );
};
export const IRISAnalysisTable = MyTable;
